// @ts-nocheck
import * as __GraphQLSchemaTypes from './graphql'

import { GraphQLClient } from 'graphql-request'
import { RequestInit } from 'graphql-request/dist/types.dom'
import { useMutation, useQuery, UseMutationOptions, UseQueryOptions } from 'react-query'

function fetcher<TData, TVariables extends { [key: string]: any }>(
  client: GraphQLClient,
  query: string,
  variables?: TVariables,
  requestHeaders?: RequestInit['headers']
) {
  return async (): Promise<TData> =>
    client.request({
      document: query,
      variables,
      requestHeaders
    })
}
export type AiChatMutationVariables = __GraphQLSchemaTypes.Exact<{
  input: __GraphQLSchemaTypes.AiChatInput
}>

export type AiChatMutation = {
  __typename?: 'Mutation'
  aiChat: {
    __typename?: 'AiChat'
    messages: Array<{
      __typename?: 'AiMessage'
      role: string
      content: Array<{ __typename?: 'AiMessageContent'; text: string }>
    } | null>
    interrupts: Array<{ __typename?: 'Interrupt'; toolRequest: { __typename?: 'ToolRequest'; name: string } }>
  }
}

export type AiChatHistoryQueryVariables = __GraphQLSchemaTypes.Exact<{ [key: string]: never }>

export type AiChatHistoryQuery = {
  __typename?: 'Query'
  aiChatHistory?: {
    __typename?: 'AiChatHistory'
    messages: Array<{
      __typename?: 'AiMessage'
      role: string
      content: Array<{ __typename?: 'AiMessageContent'; text: string }>
    } | null>
  } | null
}

export type ChangeFeedLocationMutationVariables = __GraphQLSchemaTypes.Exact<{
  feedId: __GraphQLSchemaTypes.Scalars['ID']['input']
  googlePlaceId: __GraphQLSchemaTypes.Scalars['String']['input']
}>

export type ChangeFeedLocationMutation = {
  __typename?: 'Mutation'
  changeFeedLocation: { __typename?: 'FeedBase'; id: string; feedType: __GraphQLSchemaTypes.FeedType }
}

export type ChangeFeedsLocationMutationVariables = __GraphQLSchemaTypes.Exact<{
  feedIds: Array<__GraphQLSchemaTypes.Scalars['ID']['input']> | __GraphQLSchemaTypes.Scalars['ID']['input']
  googlePlaceId: __GraphQLSchemaTypes.Scalars['String']['input']
}>

export type ChangeFeedsLocationMutation = {
  __typename?: 'Mutation'
  changeFeedsLocation: Array<{ __typename?: 'FeedBase'; id: string; feedType: __GraphQLSchemaTypes.FeedType }>
}

export type CreateSavedSearchMutationVariables = __GraphQLSchemaTypes.Exact<{
  input: __GraphQLSchemaTypes.SavedSearchInput
}>

export type CreateSavedSearchMutation = {
  __typename?: 'Mutation'
  createSavedSearch: { __typename?: 'CreateSavedSearchResult'; id: string }
}

export type DeleteSavedSearchMutationVariables = __GraphQLSchemaTypes.Exact<{
  deleteSavedSearchId: __GraphQLSchemaTypes.Scalars['ID']['input']
}>

export type DeleteSavedSearchMutation = { __typename?: 'Mutation'; deleteSavedSearch: boolean }

export type EndChatSessionMutationVariables = __GraphQLSchemaTypes.Exact<{ [key: string]: never }>

export type EndChatSessionMutation = { __typename?: 'Mutation'; endChatSession: boolean }

export type FavoriteListingsQueryVariables = __GraphQLSchemaTypes.Exact<{
  clientId?: __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['ID']['input']>
}>

export type FavoriteListingsQuery = {
  __typename?: 'Query'
  favoriteListings: {
    __typename?: 'FavoriteListings'
    listings: Array<{
      __typename?: 'Listing'
      id: string
      alertCadenceDays?: number | null
      description?: string | null
      disclaimer: string
      geohash?: string | null
      imageCoverUrl: string
      mlsLogo?: string | null
      mlsNumber: string
      onMarketDate?: string | null
      originatingSystemName?: string | null
      previousListPriceCents: number
      priceCents: number
      priceChangeTimestamp?: string | null
      propertyType: string
      bedroomsCount?: number | null
      bathroomsCount?: number | null
      sqftTotal: number
      sqftLot: number
      showMapLink?: boolean | null
      status: string
      thumbnailImageFileNames: Array<string>
      updatedAt: string
      yearBuilt?: number | null
      address: {
        __typename?: 'ListingAddress'
        number?: string | null
        street?: string | null
        unitNumber?: string | null
        city?: string | null
        stateOrProvince: string
        postalCode: string
        latitude: number
        longitude: number
      }
      agent?: {
        __typename?: 'ListingAgent'
        email?: string | null
        mlsId?: string | null
        name?: string | null
        phone?: string | null
        stateLicenseState?: string | null
        stateLicenseNumber?: string | null
      } | null
      openHouses?: Array<{
        __typename?: 'OpenHouse'
        directions?: string | null
        endTime: string
        id: string
        refreshments?: string | null
        remarks?: string | null
        startTime: string
      }> | null
      brokerage?: { __typename?: 'Brokerage'; name?: string | null; phone?: string | null } | null
    } | null>
  }
}

export type FeedQueryVariables = __GraphQLSchemaTypes.Exact<{
  feedId: __GraphQLSchemaTypes.Scalars['ID']['input']
  options?: __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.FeedOptions>
}>

export type FeedQuery = {
  __typename?: 'Query'
  feed: {
    __typename?: 'Feed'
    feedType: __GraphQLSchemaTypes.FeedType
    id: string
    homeId?: string | null
    lastNotificationAtNative?: string | null
    lastNotificationAtWeb?: string | null
    subscribedAt?: string | null
    subscribed: boolean
    webUserId: string
    listings: Array<{
      __typename?: 'ListingCompact'
      assumableRatePercent?: number | null
      bathroomsCount?: number | null
      bedroomsCount?: number | null
      closeDate?: string | null
      closePriceCents?: number | null
      daysOnMarket?: number | null
      onMarketDate?: string | null
      geohash?: string | null
      id: string
      listing_url?: string | null
      mlsNumber: string
      originatingSystemName: string
      previousListPriceCents: number
      priceCents: number
      priceChangeTimestamp?: string | null
      rank?: number | null
      showMapLink?: boolean | null
      sqftTotal: number
      sqftLot: number
      status: string
      propertyType: string
      isAssumable: boolean
      hasSellerConcession?: boolean | null
      thumbnailImageFileNames?: Array<string> | null
      openHouses?: Array<{
        __typename?: 'OpenHouse'
        directions?: string | null
        endTime: string
        id: string
        refreshments?: string | null
        remarks?: string | null
        startTime: string
      }> | null
      address: {
        __typename?: 'ListingAddress'
        number?: string | null
        street?: string | null
        unitNumber?: string | null
        city?: string | null
        stateOrProvince: string
        postalCode: string
        latitude: number
        longitude: number
      }
    } | null>
    location: {
      __typename?: 'GeoArea'
      latitude: number
      longitude: number
      name: string
      placeId: string
      stateAbbreviation: string
      level: __GraphQLSchemaTypes.GeoAreaLevel
      city?: string | null
      zipCode?: string | null
    }
  }
}

export type EphemeralFeedsQueryVariables = __GraphQLSchemaTypes.Exact<{
  input: __GraphQLSchemaTypes.EphemeralFeedsInput
}>

export type EphemeralFeedsQuery = {
  __typename?: 'Query'
  ephemeralFeeds: Array<{
    __typename?: 'EphemeralFeed'
    id: string
    zipCode: string
    listings: Array<{
      __typename?: 'ListingCompact'
      assumableRatePercent?: number | null
      bathroomsCount?: number | null
      bedroomsCount?: number | null
      closeDate?: string | null
      closePriceCents?: number | null
      complianceBrokerageInfo?: string | null
      daysOnMarket?: number | null
      onMarketDate?: string | null
      hasSellerConcession?: boolean | null
      id: string
      internetAddressDisplayYn?: boolean | null
      isAssumable: boolean
      listing_url?: string | null
      mlsLogo?: string | null
      mlsNumber: string
      originatingSystemName: string
      previousListPriceCents: number
      priceCents: number
      priceChangeTimestamp?: string | null
      propertyType: string
      rank?: number | null
      showMapLink?: boolean | null
      sqftLot: number
      sqftTotal: number
      status: string
      thumbnailImageFileNames?: Array<string> | null
      address: {
        __typename?: 'ListingAddress'
        city?: string | null
        latitude: number
        longitude: number
        number?: string | null
        postalCode: string
        stateOrProvince: string
        street?: string | null
        unitNumber?: string | null
      }
      brokerage?: { __typename?: 'Brokerage'; name?: string | null; phone?: string | null } | null
      bucketedMonthlyHomeExpenses?: {
        __typename?: 'BucketedMonthlyHomeExpenses'
        homeInsurance: number
        monthlyHoaFees: number
        monthlyPrincipalAndInterest: number
        mortgageInsurance: number
        propertyTaxes: number
        totalMonthlyHomeExpenses: number
      } | null
      loanInfo?: {
        __typename?: 'ListingLoanInfo'
        downpayment: number
        interestRatePercent: number
        loanAmount: number
        termYears: number
      } | null
    } | null>
  }>
}

export type FeedsQueryVariables = __GraphQLSchemaTypes.Exact<{
  googlePlaceId?: __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['String']['input']>
  webUserId?: __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.Scalars['ID']['input']>
}>

export type FeedsQuery = {
  __typename?: 'Query'
  feeds: Array<{
    __typename?: 'FeedBase'
    id: string
    homeId?: string | null
    feedType: __GraphQLSchemaTypes.FeedType
    lastNotificationAtNative?: string | null
    lastNotificationAtWeb?: string | null
    subscribedAt?: string | null
    subscribed: boolean
    webUserId: string
    location: {
      __typename?: 'GeoArea'
      latitude: number
      longitude: number
      name: string
      placeId: string
      stateAbbreviation: string
      level: __GraphQLSchemaTypes.GeoAreaLevel
      city?: string | null
      zipCode?: string | null
    }
  } | null>
}

export type ListingEnrichedQueryVariables = __GraphQLSchemaTypes.Exact<{
  listingId: __GraphQLSchemaTypes.Scalars['ID']['input']
  options?: __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.ListingEnrichedOptions>
}>

export type ListingEnrichedQuery = {
  __typename?: 'Query'
  listingEnriched: {
    __typename?: 'ListingEnriched'
    similarCount: number
    shareUrl?: string | null
    listing: {
      __typename?: 'Listing'
      id: string
      isAssumable: boolean
      assumableLoanType?: string | null
      assumableLoanBalance?: number | null
      assumableMonthlyPayment?: number | null
      assumableMonthsInHome?: number | null
      assumableNumLoanPayments?: number | null
      assumableRatePercent?: number | null
      closeDate?: string | null
      closePriceCents?: number | null
      concessionSummary?: string | null
      daysOnMarket?: number | null
      description?: string | null
      disclaimer: string
      geohash?: string | null
      hasBasement?: boolean | null
      hasFireplace?: boolean | null
      hasGarage?: boolean | null
      hasPool?: boolean | null
      hasSpa?: boolean | null
      hasSellerConcession?: boolean | null
      hasView?: boolean | null
      imageCoverUrl: string
      mlsLogo?: string | null
      mlsNumber: string
      onMarketDate?: string | null
      originatingSystemName?: string | null
      previousListPriceCents: number
      priceCents: number
      priceChangeTimestamp?: string | null
      propertyType: string
      bedroomsCount?: number | null
      bathroomsCount?: number | null
      buyerBrokerageName?: string | null
      sqftLot: number
      sqftTotal: number
      internetAddressDisplayYn?: boolean | null
      showMapLink?: boolean | null
      status: string
      thumbnailImageFileNames: Array<string>
      updatedAt: string
      utilities?: Array<string | null> | null
      yearBuilt?: number | null
      address: {
        __typename?: 'ListingAddress'
        number?: string | null
        street?: string | null
        unitNumber?: string | null
        city?: string | null
        stateOrProvince: string
        postalCode: string
        latitude: number
        longitude: number
      }
      agent?: {
        __typename?: 'ListingAgent'
        email?: string | null
        mlsId?: string | null
        name?: string | null
        phone?: string | null
        stateLicenseState?: string | null
        stateLicenseNumber?: string | null
      } | null
      openHouses?: Array<{
        __typename?: 'OpenHouse'
        directions?: string | null
        endTime: string
        id: string
        refreshments?: string | null
        remarks?: string | null
        startTime: string
      }> | null
      brokerage?: { __typename?: 'Brokerage'; name?: string | null; phone?: string | null } | null
    }
    similarListings?: Array<{
      __typename?: 'ListingCompact'
      geohash?: string | null
      bathroomsCount?: number | null
      bedroomsCount?: number | null
      closeDate?: string | null
      closePriceCents?: number | null
      daysOnMarket?: number | null
      onMarketDate?: string | null
      id: string
      listing_url?: string | null
      mlsNumber: string
      originatingSystemName: string
      propertyType: string
      previousListPriceCents: number
      priceCents: number
      priceChangeTimestamp?: string | null
      rank?: number | null
      showMapLink?: boolean | null
      internetAddressDisplayYn?: boolean | null
      isAssumable: boolean
      sqftLot: number
      sqftTotal: number
      status: string
      thumbnailImageFileNames?: Array<string> | null
      address: {
        __typename?: 'ListingAddress'
        number?: string | null
        street?: string | null
        unitNumber?: string | null
        city?: string | null
        stateOrProvince: string
        postalCode: string
        latitude: number
        longitude: number
      }
      openHouses?: Array<{
        __typename?: 'OpenHouse'
        directions?: string | null
        endTime: string
        id: string
        refreshments?: string | null
        remarks?: string | null
        startTime: string
      }> | null
    }> | null
    smartMove?: {
      __typename?: 'SmartMove'
      monthsToReach: number
      savingsIncrease: number
      creditIncrease: number
      debtPayOffAmount: number
      isLoanRequired: boolean
      purchasingPower: number
      preferredMonthlyHousingPayment: number
      milestones?: Array<{
        __typename?: 'Milestone'
        type?: __GraphQLSchemaTypes.MilestoneTypes | null
        projectedBuyingPower: number
        buyingPowerIncrease: number
        savings: number
        totalCashIncrease: number
        creditScore: number
        creditScoreIncrease: number
        debtsToRepay: Array<string>
        debtsToRepayChange?: Array<string> | null
        debtsToRepayTotalCost: number
        debtsToRepayChangeCost: number
        estimatedMonthsToComplete: number
        programName: string
        months: number
        buyingPowerResult?: {
          __typename?: 'BuyingPower'
          programName: string
          buyingPower: number
          loanAmount: number
          downPayment: number
          reserves: number
          closingCosts: number
          flexFunds: number
          totalMonthlyHomeExpenses: number
          interestRate: number
          qualifies: boolean
          BEDTI: number
          FEDTI: number
          LTV: number
          rejectionReason: string
          constrainedByDTI?: boolean | null
          dpaProgram?: boolean | null
          firstMortgageAmount?: number | null
          secondMortgageAmount?: number | null
          constrainedByPreferredPayment: boolean
        } | null
      }> | null
    } | null
    bucketedMonthlyHomeExpenses?: {
      __typename?: 'BucketedMonthlyHomeExpenses'
      homeInsurance: number
      monthlyPrincipalAndInterest: number
      mortgageInsurance: number
      propertyTaxes: number
      totalMonthlyHomeExpenses: number
      monthlyHoaFees: number
    } | null
    loanInfo?: {
      __typename?: 'ListingLoanInfo'
      loanAmount: number
      downpayment: number
      termYears: number
      interestRatePercent: number
    } | null
    marketReport?: {
      __typename?: 'ListingMarketReport'
      zipcode: string
      allHomesTab: {
        __typename?: 'MarketReport'
        averageBaths: number
        averageBeds: number
        averageSqft: number
        daysOnMarket: number
        inventoryTotal: number
        priceMax?: number | null
        priceMin?: number | null
        marketHistory: Array<{ __typename?: 'MarketHistoryEntry'; date: string; score: number }>
      }
      pricedAroundTab: {
        __typename?: 'MarketReport'
        averageBaths: number
        averageBeds: number
        averageSqft: number
        daysOnMarket: number
        inventoryTotal: number
        priceMax?: number | null
        priceMin?: number | null
        marketHistory: Array<{ __typename?: 'MarketHistoryEntry'; date: string; score: number }>
      }
    } | null
    assumableMortgageLoanInfo?: {
      __typename?: 'ListingLoanInfo'
      downpayment: number
      interestRatePercent: number
      loanAmount: number
      termYears: number
    } | null
    assumableMortgageMonthlyHomeExpenses?: {
      __typename?: 'BucketedMonthlyHomeExpenses'
      homeInsurance: number
      monthlyHoaFees: number
      monthlyPrincipalAndInterest: number
      mortgageInsurance: number
      propertyTaxes: number
      secondMortgagePrincipalAndInterest?: number | null
      totalMonthlyHomeExpenses: number
    } | null
    assumableMortgageSecondLoanInfo?: {
      __typename?: 'ListingLoanInfo'
      downpayment: number
      interestRatePercent: number
      loanAmount: number
      termYears: number
    } | null
  }
}

export type ListingsForAreaQueryVariables = __GraphQLSchemaTypes.Exact<{
  searchArea: __GraphQLSchemaTypes.SearchArea
  filters: __GraphQLSchemaTypes.ListingsFilters
  sortOrder: __GraphQLSchemaTypes.ListingsSortOrder
  perPage: __GraphQLSchemaTypes.Scalars['Int']['input']
  page: __GraphQLSchemaTypes.Scalars['Int']['input']
}>

export type ListingsForAreaQuery = {
  __typename?: 'Query'
  listingsForArea: {
    __typename?: 'ListingsForArea'
    page: number
    hasMore: boolean
    listings: Array<{
      __typename?: 'ListingCompact'
      geohash?: string | null
      bathroomsCount?: number | null
      bedroomsCount?: number | null
      buyerBrokerageName?: string | null
      closeDate?: string | null
      closePriceCents?: number | null
      daysOnMarket?: number | null
      onMarketDate?: string | null
      id: string
      isAssumable: boolean
      hasSellerConcession?: boolean | null
      listing_url?: string | null
      mlsLogo?: string | null
      mlsNumber: string
      originatingSystemName: string
      propertyType: string
      previousListPriceCents: number
      priceCents: number
      priceChangeTimestamp?: string | null
      rank?: number | null
      showMapLink?: boolean | null
      internetAddressDisplayYn?: boolean | null
      assumableRatePercent?: number | null
      sqftLot: number
      sqftTotal: number
      status: string
      thumbnailImageFileNames?: Array<string> | null
      address: {
        __typename?: 'ListingAddress'
        number?: string | null
        street?: string | null
        unitNumber?: string | null
        city?: string | null
        stateOrProvince: string
        postalCode: string
        latitude: number
        longitude: number
      }
      brokerage?: { __typename?: 'Brokerage'; name?: string | null; phone?: string | null } | null
      bucketedMonthlyHomeExpenses?: {
        __typename?: 'BucketedMonthlyHomeExpenses'
        homeInsurance: number
        monthlyPrincipalAndInterest: number
        mortgageInsurance: number
        propertyTaxes: number
        totalMonthlyHomeExpenses: number
        monthlyHoaFees: number
      } | null
      openHouses?: Array<{
        __typename?: 'OpenHouse'
        directions?: string | null
        endTime: string
        id: string
        refreshments?: string | null
        remarks?: string | null
        startTime: string
      }> | null
      loanInfo?: {
        __typename?: 'ListingLoanInfo'
        loanAmount: number
        downpayment: number
        termYears: number
        interestRatePercent: number
      } | null
    }>
    location?: {
      __typename?: 'GeoArea'
      city?: string | null
      latitude: number
      level: __GraphQLSchemaTypes.GeoAreaLevel
      longitude: number
      name: string
      placeId: string
      stateAbbreviation: string
      zipCode?: string | null
    } | null
  }
}

export type PreferredAgentQueryVariables = __GraphQLSchemaTypes.Exact<{
  input?: __GraphQLSchemaTypes.InputMaybe<__GraphQLSchemaTypes.PreferredAgentInput>
}>

export type PreferredAgentQuery = {
  __typename?: 'Query'
  preferredAgent: {
    __typename?: 'PreferredAgent'
    agentEmail?: string | null
    agentFirstName?: string | null
    agentLastName?: string | null
    clientId: string
  }
}

export type UpdatePreferredAgentMutationVariables = __GraphQLSchemaTypes.Exact<{
  input: __GraphQLSchemaTypes.UpdatePreferredAgentInput
}>

export type UpdatePreferredAgentMutation = {
  __typename?: 'Mutation'
  updatePreferredAgent: {
    __typename?: 'PreferredAgent'
    agentEmail?: string | null
    agentFirstName?: string | null
    agentLastName?: string | null
    clientId: string
  }
}

export type ResetFavoriteListingsAlertCadenceMutationVariables = __GraphQLSchemaTypes.Exact<{
  clientId: __GraphQLSchemaTypes.Scalars['ID']['input']
}>

export type ResetFavoriteListingsAlertCadenceMutation = {
  __typename?: 'Mutation'
  resetFavoriteListingsAlertCadence: {
    __typename?: 'ClientsFavoriteListings'
    favoriteListings: Array<{
      __typename?: 'FavoriteListing'
      alertCadenceDays?: number | null
      createdAt?: string | null
      updatedAt?: string | null
      clientId?: string | null
      addressKey?: string | null
    } | null>
  }
}

export type SavedSearchesQueryVariables = __GraphQLSchemaTypes.Exact<{
  input: __GraphQLSchemaTypes.SavedSearchesInput
}>

export type SavedSearchesQuery = {
  __typename?: 'Query'
  savedSearches: Array<{
    __typename?: 'SavedSearch'
    alertCadenceDays?: number | null
    clientId: string
    id: string
    lastNotificationAtNative?: string | null
    lastNotificationAtWeb?: string | null
    lastSeenAt?: string | null
    name: string
    subscribedAt?: string | null
    filters: {
      __typename?: 'ListingsFiltersOutput'
      hasOpenHouses?: boolean | null
      hasSellerConcession?: boolean | null
      isAssumable?: boolean | null
      maxAcres?: number | null
      maxPrice?: number | null
      maxSqft?: number | null
      maxYearBuilt?: number | null
      minAcres?: number | null
      minBathroomsCount?: number | null
      minBedroomsCount?: number | null
      minPrice?: number | null
      minSqft?: number | null
      minYearBuilt?: number | null
      propertyTypes?: Array<__GraphQLSchemaTypes.PropertyType> | null
      statuses?: Array<string> | null
      monthlyExpensesInput?: {
        __typename?: 'MonthlyExpenses'
        downpaymentAmountDollars: number
        interestRatePercent?: number | null
        maxMonthlyExpenses?: number | null
        minMonthlyExpenses?: number | null
      } | null
    }
    searchArea: {
      __typename?: 'SearchAreaOutput'
      boundingBox?: {
        __typename?: 'BoundingBox'
        level: __GraphQLSchemaTypes.BoundingBoxLevel
        bottomRight: { __typename?: 'CoordinatesOutput'; latitude: number; longitude: number }
        topLeft: { __typename?: 'CoordinatesOutput'; latitude: number; longitude: number }
      } | null
      geoArea?: {
        __typename?: 'GeoArea'
        city?: string | null
        latitude: number
        level: __GraphQLSchemaTypes.GeoAreaLevel
        longitude: number
        name: string
        placeId: string
        stateAbbreviation: string
        zipCode?: string | null
      } | null
      placeIdInput?: { __typename?: 'PlaceIdOutput'; placeId: string } | null
    }
    sortOrder: {
      __typename?: 'ListingsSortOrderOutput'
      orderBy: __GraphQLSchemaTypes.ListingsOrderBy
      orderDir: __GraphQLSchemaTypes.ListingsOrderDirection
    }
  } | null>
}

export type SendMessageMutationVariables = __GraphQLSchemaTypes.Exact<{
  input: __GraphQLSchemaTypes.MessageInput
}>

export type SendMessageMutation = { __typename?: 'Mutation'; sendMessage: boolean }

export type ToggleFavoriteListingMutationVariables = __GraphQLSchemaTypes.Exact<{
  toggleFavoriteListingInput: __GraphQLSchemaTypes.ToggleFavoriteListingInput
}>

export type ToggleFavoriteListingMutation = {
  __typename?: 'Mutation'
  toggleFavoriteListing: { __typename?: 'ToggleFavoriteListingResponse'; isFavorite: boolean }
}

export type ToggleFeedSubscribedMutationVariables = __GraphQLSchemaTypes.Exact<{
  feedId: __GraphQLSchemaTypes.Scalars['ID']['input']
}>

export type ToggleFeedSubscribedMutation = {
  __typename?: 'Mutation'
  toggleFeedSubscribed: { __typename?: 'ToggleFeedSubscribedResponse'; subscribed: boolean }
}

export type UpdateClientMutationVariables = __GraphQLSchemaTypes.Exact<{
  input: __GraphQLSchemaTypes.UpdateClientInput
}>

export type UpdateClientMutation = {
  __typename?: 'Mutation'
  updateClient: { __typename?: 'Client'; updatedAt?: string | null; favoriteListingsAlertCadenceDays?: number | null }
}

export type UpdateFavoriteListingMutationVariables = __GraphQLSchemaTypes.Exact<{
  listingId: __GraphQLSchemaTypes.Scalars['ID']['input']
  input: __GraphQLSchemaTypes.UpdateFavoriteListingInput
}>

export type UpdateFavoriteListingMutation = {
  __typename?: 'Mutation'
  updateFavoriteListing: {
    __typename?: 'FavoriteListing'
    updatedAt?: string | null
    alertCadenceDays?: number | null
    addressKey?: string | null
  }
}

export type UpdateSavedSearchAlertCadenceMutationVariables = __GraphQLSchemaTypes.Exact<{
  updateSavedSearchAlertCadenceId: __GraphQLSchemaTypes.Scalars['ID']['input']
  input: __GraphQLSchemaTypes.SavedSearchAlertCadenceInput
}>

export type UpdateSavedSearchAlertCadenceMutation = {
  __typename?: 'Mutation'
  updateSavedSearchAlertCadence: { __typename?: 'SavedSearch'; id: string }
}

export type CreateClientEventMutationVariables = __GraphQLSchemaTypes.Exact<{
  clientEventInput: __GraphQLSchemaTypes.ClientEventInput
}>

export type CreateClientEventMutation = { __typename?: 'Mutation'; createClientEvent: boolean }

export const AiChatDocument = `
    mutation AiChat($input: AiChatInput!) {
  aiChat(input: $input) {
    messages {
      content {
        text
      }
      role
    }
    interrupts {
      toolRequest {
        name
      }
    }
  }
}
    `

export const useAiChatMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    __GraphQLSchemaTypes.AiChatMutation,
    TError,
    __GraphQLSchemaTypes.AiChatMutationVariables,
    TContext
  >,
  headers?: RequestInit['headers']
) => {
  return useMutation<
    __GraphQLSchemaTypes.AiChatMutation,
    TError,
    __GraphQLSchemaTypes.AiChatMutationVariables,
    TContext
  >(
    ['AiChat'],
    (variables?: __GraphQLSchemaTypes.AiChatMutationVariables) =>
      fetcher<__GraphQLSchemaTypes.AiChatMutation, __GraphQLSchemaTypes.AiChatMutationVariables>(
        client,
        AiChatDocument,
        variables,
        headers
      )(),
    options
  )
}

export const AiChatHistoryDocument = `
    query AiChatHistory {
  aiChatHistory {
    messages {
      content {
        text
      }
      role
    }
  }
}
    `

export const useAiChatHistoryQuery = <TData = __GraphQLSchemaTypes.AiChatHistoryQuery, TError = unknown>(
  client: GraphQLClient,
  variables?: __GraphQLSchemaTypes.AiChatHistoryQueryVariables,
  options?: UseQueryOptions<__GraphQLSchemaTypes.AiChatHistoryQuery, TError, TData>,
  headers?: RequestInit['headers']
) => {
  return useQuery<__GraphQLSchemaTypes.AiChatHistoryQuery, TError, TData>(
    variables === undefined ? ['AiChatHistory'] : ['AiChatHistory', variables],
    fetcher<__GraphQLSchemaTypes.AiChatHistoryQuery, __GraphQLSchemaTypes.AiChatHistoryQueryVariables>(
      client,
      AiChatHistoryDocument,
      variables,
      headers
    ),
    options
  )
}

export const ChangeFeedLocationDocument = `
    mutation ChangeFeedLocation($feedId: ID!, $googlePlaceId: String!) {
  changeFeedLocation(feedId: $feedId, googlePlaceId: $googlePlaceId) {
    id
    feedType
  }
}
    `

export const useChangeFeedLocationMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    __GraphQLSchemaTypes.ChangeFeedLocationMutation,
    TError,
    __GraphQLSchemaTypes.ChangeFeedLocationMutationVariables,
    TContext
  >,
  headers?: RequestInit['headers']
) => {
  return useMutation<
    __GraphQLSchemaTypes.ChangeFeedLocationMutation,
    TError,
    __GraphQLSchemaTypes.ChangeFeedLocationMutationVariables,
    TContext
  >(
    ['ChangeFeedLocation'],
    (variables?: __GraphQLSchemaTypes.ChangeFeedLocationMutationVariables) =>
      fetcher<
        __GraphQLSchemaTypes.ChangeFeedLocationMutation,
        __GraphQLSchemaTypes.ChangeFeedLocationMutationVariables
      >(client, ChangeFeedLocationDocument, variables, headers)(),
    options
  )
}

export const ChangeFeedsLocationDocument = `
    mutation ChangeFeedsLocation($feedIds: [ID!]!, $googlePlaceId: String!) {
  changeFeedsLocation(feedIds: $feedIds, googlePlaceId: $googlePlaceId) {
    id
    feedType
  }
}
    `

export const useChangeFeedsLocationMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    __GraphQLSchemaTypes.ChangeFeedsLocationMutation,
    TError,
    __GraphQLSchemaTypes.ChangeFeedsLocationMutationVariables,
    TContext
  >,
  headers?: RequestInit['headers']
) => {
  return useMutation<
    __GraphQLSchemaTypes.ChangeFeedsLocationMutation,
    TError,
    __GraphQLSchemaTypes.ChangeFeedsLocationMutationVariables,
    TContext
  >(
    ['ChangeFeedsLocation'],
    (variables?: __GraphQLSchemaTypes.ChangeFeedsLocationMutationVariables) =>
      fetcher<
        __GraphQLSchemaTypes.ChangeFeedsLocationMutation,
        __GraphQLSchemaTypes.ChangeFeedsLocationMutationVariables
      >(client, ChangeFeedsLocationDocument, variables, headers)(),
    options
  )
}

export const CreateSavedSearchDocument = `
    mutation CreateSavedSearch($input: SavedSearchInput!) {
  createSavedSearch(input: $input) {
    id
  }
}
    `

export const useCreateSavedSearchMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    __GraphQLSchemaTypes.CreateSavedSearchMutation,
    TError,
    __GraphQLSchemaTypes.CreateSavedSearchMutationVariables,
    TContext
  >,
  headers?: RequestInit['headers']
) => {
  return useMutation<
    __GraphQLSchemaTypes.CreateSavedSearchMutation,
    TError,
    __GraphQLSchemaTypes.CreateSavedSearchMutationVariables,
    TContext
  >(
    ['CreateSavedSearch'],
    (variables?: __GraphQLSchemaTypes.CreateSavedSearchMutationVariables) =>
      fetcher<__GraphQLSchemaTypes.CreateSavedSearchMutation, __GraphQLSchemaTypes.CreateSavedSearchMutationVariables>(
        client,
        CreateSavedSearchDocument,
        variables,
        headers
      )(),
    options
  )
}

export const DeleteSavedSearchDocument = `
    mutation DeleteSavedSearch($deleteSavedSearchId: ID!) {
  deleteSavedSearch(id: $deleteSavedSearchId)
}
    `

export const useDeleteSavedSearchMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    __GraphQLSchemaTypes.DeleteSavedSearchMutation,
    TError,
    __GraphQLSchemaTypes.DeleteSavedSearchMutationVariables,
    TContext
  >,
  headers?: RequestInit['headers']
) => {
  return useMutation<
    __GraphQLSchemaTypes.DeleteSavedSearchMutation,
    TError,
    __GraphQLSchemaTypes.DeleteSavedSearchMutationVariables,
    TContext
  >(
    ['DeleteSavedSearch'],
    (variables?: __GraphQLSchemaTypes.DeleteSavedSearchMutationVariables) =>
      fetcher<__GraphQLSchemaTypes.DeleteSavedSearchMutation, __GraphQLSchemaTypes.DeleteSavedSearchMutationVariables>(
        client,
        DeleteSavedSearchDocument,
        variables,
        headers
      )(),
    options
  )
}

export const EndChatSessionDocument = `
    mutation EndChatSession {
  endChatSession
}
    `

export const useEndChatSessionMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    __GraphQLSchemaTypes.EndChatSessionMutation,
    TError,
    __GraphQLSchemaTypes.EndChatSessionMutationVariables,
    TContext
  >,
  headers?: RequestInit['headers']
) => {
  return useMutation<
    __GraphQLSchemaTypes.EndChatSessionMutation,
    TError,
    __GraphQLSchemaTypes.EndChatSessionMutationVariables,
    TContext
  >(
    ['EndChatSession'],
    (variables?: __GraphQLSchemaTypes.EndChatSessionMutationVariables) =>
      fetcher<__GraphQLSchemaTypes.EndChatSessionMutation, __GraphQLSchemaTypes.EndChatSessionMutationVariables>(
        client,
        EndChatSessionDocument,
        variables,
        headers
      )(),
    options
  )
}

export const FavoriteListingsDocument = `
    query FavoriteListings($clientId: ID) {
  favoriteListings(clientId: $clientId) {
    listings {
      id
      address {
        number
        street
        unitNumber
        city
        stateOrProvince
        postalCode
        latitude
        longitude
      }
      agent {
        email
        mlsId
        name
        phone
        stateLicenseState
        stateLicenseNumber
      }
      openHouses {
        directions
        endTime
        id
        refreshments
        remarks
        startTime
      }
      alertCadenceDays
      description
      disclaimer
      geohash
      imageCoverUrl
      mlsLogo
      mlsNumber
      onMarketDate
      originatingSystemName
      previousListPriceCents
      priceCents
      priceChangeTimestamp
      propertyType
      brokerage {
        name
        phone
      }
      bedroomsCount
      bathroomsCount
      sqftTotal
      sqftLot
      showMapLink
      status
      thumbnailImageFileNames
      updatedAt
      yearBuilt
    }
  }
}
    `

export const useFavoriteListingsQuery = <TData = __GraphQLSchemaTypes.FavoriteListingsQuery, TError = unknown>(
  client: GraphQLClient,
  variables?: __GraphQLSchemaTypes.FavoriteListingsQueryVariables,
  options?: UseQueryOptions<__GraphQLSchemaTypes.FavoriteListingsQuery, TError, TData>,
  headers?: RequestInit['headers']
) => {
  return useQuery<__GraphQLSchemaTypes.FavoriteListingsQuery, TError, TData>(
    variables === undefined ? ['FavoriteListings'] : ['FavoriteListings', variables],
    fetcher<__GraphQLSchemaTypes.FavoriteListingsQuery, __GraphQLSchemaTypes.FavoriteListingsQueryVariables>(
      client,
      FavoriteListingsDocument,
      variables,
      headers
    ),
    options
  )
}

export const FeedDocument = `
    query Feed($feedId: ID!, $options: FeedOptions) {
  feed(feedId: $feedId, options: $options) {
    feedType
    id
    homeId
    lastNotificationAtNative
    lastNotificationAtWeb
    subscribedAt
    subscribed
    webUserId
    listings {
      assumableRatePercent
      bathroomsCount
      bedroomsCount
      closeDate
      closePriceCents
      daysOnMarket
      onMarketDate
      geohash
      id
      listing_url
      mlsNumber
      openHouses {
        directions
        endTime
        id
        refreshments
        remarks
        startTime
      }
      originatingSystemName
      previousListPriceCents
      priceCents
      priceChangeTimestamp
      rank
      showMapLink
      sqftTotal
      sqftLot
      status
      propertyType
      isAssumable
      hasSellerConcession
      thumbnailImageFileNames
      address {
        number
        street
        unitNumber
        city
        stateOrProvince
        postalCode
        latitude
        longitude
      }
    }
    location {
      latitude
      longitude
      name
      placeId
      stateAbbreviation
      level
      city
      zipCode
    }
  }
}
    `

export const useFeedQuery = <TData = __GraphQLSchemaTypes.FeedQuery, TError = unknown>(
  client: GraphQLClient,
  variables: __GraphQLSchemaTypes.FeedQueryVariables,
  options?: UseQueryOptions<__GraphQLSchemaTypes.FeedQuery, TError, TData>,
  headers?: RequestInit['headers']
) => {
  return useQuery<__GraphQLSchemaTypes.FeedQuery, TError, TData>(
    ['Feed', variables],
    fetcher<__GraphQLSchemaTypes.FeedQuery, __GraphQLSchemaTypes.FeedQueryVariables>(
      client,
      FeedDocument,
      variables,
      headers
    ),
    options
  )
}

export const EphemeralFeedsDocument = `
    query EphemeralFeeds($input: EphemeralFeedsInput!) {
  ephemeralFeeds(input: $input) {
    id
    listings {
      address {
        city
        latitude
        longitude
        number
        postalCode
        stateOrProvince
        street
        unitNumber
      }
      assumableRatePercent
      bathroomsCount
      bedroomsCount
      brokerage {
        name
        phone
      }
      bucketedMonthlyHomeExpenses {
        homeInsurance
        monthlyHoaFees
        monthlyPrincipalAndInterest
        mortgageInsurance
        propertyTaxes
        totalMonthlyHomeExpenses
      }
      closeDate
      closePriceCents
      complianceBrokerageInfo
      daysOnMarket
      onMarketDate
      hasSellerConcession
      id
      internetAddressDisplayYn
      isAssumable
      listing_url
      loanInfo {
        downpayment
        interestRatePercent
        loanAmount
        termYears
      }
      mlsLogo
      mlsNumber
      originatingSystemName
      previousListPriceCents
      priceCents
      priceChangeTimestamp
      propertyType
      rank
      showMapLink
      sqftLot
      sqftTotal
      status
      thumbnailImageFileNames
    }
    zipCode
  }
}
    `

export const useEphemeralFeedsQuery = <TData = __GraphQLSchemaTypes.EphemeralFeedsQuery, TError = unknown>(
  client: GraphQLClient,
  variables: __GraphQLSchemaTypes.EphemeralFeedsQueryVariables,
  options?: UseQueryOptions<__GraphQLSchemaTypes.EphemeralFeedsQuery, TError, TData>,
  headers?: RequestInit['headers']
) => {
  return useQuery<__GraphQLSchemaTypes.EphemeralFeedsQuery, TError, TData>(
    ['EphemeralFeeds', variables],
    fetcher<__GraphQLSchemaTypes.EphemeralFeedsQuery, __GraphQLSchemaTypes.EphemeralFeedsQueryVariables>(
      client,
      EphemeralFeedsDocument,
      variables,
      headers
    ),
    options
  )
}

export const FeedsDocument = `
    query Feeds($googlePlaceId: String, $webUserId: ID) {
  feeds(googlePlaceId: $googlePlaceId, webUserId: $webUserId) {
    id
    homeId
    feedType
    lastNotificationAtNative
    lastNotificationAtWeb
    subscribedAt
    subscribed
    webUserId
    location {
      latitude
      longitude
      name
      placeId
      stateAbbreviation
      level
      city
      zipCode
    }
  }
}
    `

export const useFeedsQuery = <TData = __GraphQLSchemaTypes.FeedsQuery, TError = unknown>(
  client: GraphQLClient,
  variables?: __GraphQLSchemaTypes.FeedsQueryVariables,
  options?: UseQueryOptions<__GraphQLSchemaTypes.FeedsQuery, TError, TData>,
  headers?: RequestInit['headers']
) => {
  return useQuery<__GraphQLSchemaTypes.FeedsQuery, TError, TData>(
    variables === undefined ? ['Feeds'] : ['Feeds', variables],
    fetcher<__GraphQLSchemaTypes.FeedsQuery, __GraphQLSchemaTypes.FeedsQueryVariables>(
      client,
      FeedsDocument,
      variables,
      headers
    ),
    options
  )
}

export const ListingEnrichedDocument = `
    query ListingEnriched($listingId: ID!, $options: ListingEnrichedOptions) {
  listingEnriched(listingId: $listingId, options: $options) {
    listing {
      id
      isAssumable
      assumableLoanType
      assumableLoanBalance
      assumableMonthlyPayment
      assumableMonthsInHome
      assumableNumLoanPayments
      assumableRatePercent
      address {
        number
        street
        unitNumber
        city
        stateOrProvince
        postalCode
        latitude
        longitude
      }
      agent {
        email
        mlsId
        name
        phone
        stateLicenseState
        stateLicenseNumber
      }
      closeDate
      closePriceCents
      concessionSummary
      daysOnMarket
      description
      disclaimer
      geohash
      hasBasement
      hasFireplace
      hasGarage
      hasPool
      hasSpa
      hasSellerConcession
      hasView
      imageCoverUrl
      mlsLogo
      mlsNumber
      onMarketDate
      openHouses {
        directions
        endTime
        id
        refreshments
        remarks
        startTime
      }
      originatingSystemName
      previousListPriceCents
      priceCents
      priceChangeTimestamp
      propertyType
      brokerage {
        name
        phone
      }
      bedroomsCount
      bathroomsCount
      buyerBrokerageName
      sqftLot
      sqftTotal
      internetAddressDisplayYn
      showMapLink
      status
      thumbnailImageFileNames
      updatedAt
      utilities
      yearBuilt
    }
    similarCount
    similarListings {
      geohash
      address {
        number
        street
        unitNumber
        city
        stateOrProvince
        postalCode
        latitude
        longitude
      }
      bathroomsCount
      bedroomsCount
      closeDate
      closePriceCents
      daysOnMarket
      onMarketDate
      geohash
      id
      listing_url
      mlsNumber
      openHouses {
        directions
        endTime
        id
        refreshments
        remarks
        startTime
      }
      originatingSystemName
      propertyType
      previousListPriceCents
      priceCents
      priceChangeTimestamp
      rank
      showMapLink
      internetAddressDisplayYn
      isAssumable
      sqftLot
      sqftTotal
      status
      thumbnailImageFileNames
    }
    shareUrl
    smartMove {
      monthsToReach
      savingsIncrease
      creditIncrease
      debtPayOffAmount
      isLoanRequired
      purchasingPower
      preferredMonthlyHousingPayment
      milestones {
        type
        projectedBuyingPower
        buyingPowerIncrease
        savings
        totalCashIncrease
        creditScore
        creditScoreIncrease
        debtsToRepay
        debtsToRepayChange
        debtsToRepayTotalCost
        debtsToRepayChangeCost
        estimatedMonthsToComplete
        buyingPowerResult {
          programName
          buyingPower
          loanAmount
          downPayment
          reserves
          closingCosts
          flexFunds
          totalMonthlyHomeExpenses
          interestRate
          qualifies
          BEDTI
          FEDTI
          LTV
          rejectionReason
          constrainedByDTI
          dpaProgram
          firstMortgageAmount
          secondMortgageAmount
          constrainedByPreferredPayment
        }
        programName
        months
      }
    }
    bucketedMonthlyHomeExpenses {
      homeInsurance
      monthlyPrincipalAndInterest
      mortgageInsurance
      propertyTaxes
      totalMonthlyHomeExpenses
      monthlyHoaFees
    }
    loanInfo {
      loanAmount
      downpayment
      termYears
      interestRatePercent
    }
    marketReport {
      zipcode
      allHomesTab {
        averageBaths
        averageBeds
        averageSqft
        daysOnMarket
        inventoryTotal
        marketHistory {
          date
          score
        }
        priceMax
        priceMin
      }
      pricedAroundTab {
        averageBaths
        averageBeds
        averageSqft
        daysOnMarket
        inventoryTotal
        marketHistory {
          date
          score
        }
        priceMax
        priceMin
      }
    }
    assumableMortgageLoanInfo {
      downpayment
      interestRatePercent
      loanAmount
      termYears
    }
    assumableMortgageMonthlyHomeExpenses {
      homeInsurance
      monthlyHoaFees
      monthlyPrincipalAndInterest
      mortgageInsurance
      propertyTaxes
      secondMortgagePrincipalAndInterest
      totalMonthlyHomeExpenses
    }
    assumableMortgageSecondLoanInfo {
      downpayment
      interestRatePercent
      loanAmount
      termYears
    }
  }
}
    `

export const useListingEnrichedQuery = <TData = __GraphQLSchemaTypes.ListingEnrichedQuery, TError = unknown>(
  client: GraphQLClient,
  variables: __GraphQLSchemaTypes.ListingEnrichedQueryVariables,
  options?: UseQueryOptions<__GraphQLSchemaTypes.ListingEnrichedQuery, TError, TData>,
  headers?: RequestInit['headers']
) => {
  return useQuery<__GraphQLSchemaTypes.ListingEnrichedQuery, TError, TData>(
    ['ListingEnriched', variables],
    fetcher<__GraphQLSchemaTypes.ListingEnrichedQuery, __GraphQLSchemaTypes.ListingEnrichedQueryVariables>(
      client,
      ListingEnrichedDocument,
      variables,
      headers
    ),
    options
  )
}

export const ListingsForAreaDocument = `
    query ListingsForArea($searchArea: SearchArea!, $filters: ListingsFilters!, $sortOrder: ListingsSortOrder!, $perPage: Int!, $page: Int!) {
  listingsForArea(
    searchArea: $searchArea
    filters: $filters
    sortOrder: $sortOrder
    perPage: $perPage
    page: $page
  ) {
    listings {
      geohash
      address {
        number
        street
        unitNumber
        city
        stateOrProvince
        postalCode
        latitude
        longitude
      }
      brokerage {
        name
        phone
      }
      bathroomsCount
      bedroomsCount
      buyerBrokerageName
      closeDate
      closePriceCents
      daysOnMarket
      onMarketDate
      geohash
      id
      isAssumable
      hasSellerConcession
      listing_url
      mlsLogo
      mlsNumber
      bucketedMonthlyHomeExpenses {
        homeInsurance
        monthlyPrincipalAndInterest
        mortgageInsurance
        propertyTaxes
        totalMonthlyHomeExpenses
        monthlyHoaFees
      }
      openHouses {
        directions
        endTime
        id
        refreshments
        remarks
        startTime
      }
      originatingSystemName
      propertyType
      previousListPriceCents
      priceCents
      priceChangeTimestamp
      rank
      showMapLink
      internetAddressDisplayYn
      assumableRatePercent
      isAssumable
      sqftLot
      sqftTotal
      status
      thumbnailImageFileNames
      loanInfo {
        loanAmount
        downpayment
        termYears
        interestRatePercent
      }
    }
    page
    hasMore
    location {
      city
      latitude
      level
      longitude
      name
      placeId
      stateAbbreviation
      zipCode
    }
  }
}
    `

export const useListingsForAreaQuery = <TData = __GraphQLSchemaTypes.ListingsForAreaQuery, TError = unknown>(
  client: GraphQLClient,
  variables: __GraphQLSchemaTypes.ListingsForAreaQueryVariables,
  options?: UseQueryOptions<__GraphQLSchemaTypes.ListingsForAreaQuery, TError, TData>,
  headers?: RequestInit['headers']
) => {
  return useQuery<__GraphQLSchemaTypes.ListingsForAreaQuery, TError, TData>(
    ['ListingsForArea', variables],
    fetcher<__GraphQLSchemaTypes.ListingsForAreaQuery, __GraphQLSchemaTypes.ListingsForAreaQueryVariables>(
      client,
      ListingsForAreaDocument,
      variables,
      headers
    ),
    options
  )
}

export const PreferredAgentDocument = `
    query PreferredAgent($input: PreferredAgentInput) {
  preferredAgent(input: $input) {
    agentEmail
    agentFirstName
    agentLastName
    clientId
  }
}
    `

export const usePreferredAgentQuery = <TData = __GraphQLSchemaTypes.PreferredAgentQuery, TError = unknown>(
  client: GraphQLClient,
  variables?: __GraphQLSchemaTypes.PreferredAgentQueryVariables,
  options?: UseQueryOptions<__GraphQLSchemaTypes.PreferredAgentQuery, TError, TData>,
  headers?: RequestInit['headers']
) => {
  return useQuery<__GraphQLSchemaTypes.PreferredAgentQuery, TError, TData>(
    variables === undefined ? ['PreferredAgent'] : ['PreferredAgent', variables],
    fetcher<__GraphQLSchemaTypes.PreferredAgentQuery, __GraphQLSchemaTypes.PreferredAgentQueryVariables>(
      client,
      PreferredAgentDocument,
      variables,
      headers
    ),
    options
  )
}

export const UpdatePreferredAgentDocument = `
    mutation UpdatePreferredAgent($input: UpdatePreferredAgentInput!) {
  updatePreferredAgent(input: $input) {
    agentEmail
    agentFirstName
    agentLastName
    clientId
  }
}
    `

export const useUpdatePreferredAgentMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    __GraphQLSchemaTypes.UpdatePreferredAgentMutation,
    TError,
    __GraphQLSchemaTypes.UpdatePreferredAgentMutationVariables,
    TContext
  >,
  headers?: RequestInit['headers']
) => {
  return useMutation<
    __GraphQLSchemaTypes.UpdatePreferredAgentMutation,
    TError,
    __GraphQLSchemaTypes.UpdatePreferredAgentMutationVariables,
    TContext
  >(
    ['UpdatePreferredAgent'],
    (variables?: __GraphQLSchemaTypes.UpdatePreferredAgentMutationVariables) =>
      fetcher<
        __GraphQLSchemaTypes.UpdatePreferredAgentMutation,
        __GraphQLSchemaTypes.UpdatePreferredAgentMutationVariables
      >(client, UpdatePreferredAgentDocument, variables, headers)(),
    options
  )
}

export const ResetFavoriteListingsAlertCadenceDocument = `
    mutation ResetFavoriteListingsAlertCadence($clientId: ID!) {
  resetFavoriteListingsAlertCadence(clientId: $clientId) {
    favoriteListings {
      alertCadenceDays
      createdAt
      updatedAt
      clientId
      addressKey
    }
  }
}
    `

export const useResetFavoriteListingsAlertCadenceMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    __GraphQLSchemaTypes.ResetFavoriteListingsAlertCadenceMutation,
    TError,
    __GraphQLSchemaTypes.ResetFavoriteListingsAlertCadenceMutationVariables,
    TContext
  >,
  headers?: RequestInit['headers']
) => {
  return useMutation<
    __GraphQLSchemaTypes.ResetFavoriteListingsAlertCadenceMutation,
    TError,
    __GraphQLSchemaTypes.ResetFavoriteListingsAlertCadenceMutationVariables,
    TContext
  >(
    ['ResetFavoriteListingsAlertCadence'],
    (variables?: __GraphQLSchemaTypes.ResetFavoriteListingsAlertCadenceMutationVariables) =>
      fetcher<
        __GraphQLSchemaTypes.ResetFavoriteListingsAlertCadenceMutation,
        __GraphQLSchemaTypes.ResetFavoriteListingsAlertCadenceMutationVariables
      >(client, ResetFavoriteListingsAlertCadenceDocument, variables, headers)(),
    options
  )
}

export const SavedSearchesDocument = `
    query SavedSearches($input: SavedSearchesInput!) {
  savedSearches(input: $input) {
    alertCadenceDays
    clientId
    filters {
      hasOpenHouses
      hasSellerConcession
      isAssumable
      maxAcres
      maxPrice
      maxSqft
      maxYearBuilt
      minAcres
      minBathroomsCount
      minBedroomsCount
      minPrice
      minSqft
      minYearBuilt
      monthlyExpensesInput {
        downpaymentAmountDollars
        interestRatePercent
        maxMonthlyExpenses
        minMonthlyExpenses
      }
      propertyTypes
      statuses
    }
    id
    lastNotificationAtNative
    lastNotificationAtWeb
    lastSeenAt
    name
    searchArea {
      boundingBox {
        bottomRight {
          latitude
          longitude
        }
        level
        topLeft {
          latitude
          longitude
        }
      }
      geoArea {
        city
        latitude
        level
        longitude
        name
        placeId
        stateAbbreviation
        zipCode
      }
      placeIdInput {
        placeId
      }
    }
    sortOrder {
      orderBy
      orderDir
    }
    subscribedAt
  }
}
    `

export const useSavedSearchesQuery = <TData = __GraphQLSchemaTypes.SavedSearchesQuery, TError = unknown>(
  client: GraphQLClient,
  variables: __GraphQLSchemaTypes.SavedSearchesQueryVariables,
  options?: UseQueryOptions<__GraphQLSchemaTypes.SavedSearchesQuery, TError, TData>,
  headers?: RequestInit['headers']
) => {
  return useQuery<__GraphQLSchemaTypes.SavedSearchesQuery, TError, TData>(
    ['SavedSearches', variables],
    fetcher<__GraphQLSchemaTypes.SavedSearchesQuery, __GraphQLSchemaTypes.SavedSearchesQueryVariables>(
      client,
      SavedSearchesDocument,
      variables,
      headers
    ),
    options
  )
}

export const SendMessageDocument = `
    mutation SendMessage($input: MessageInput!) {
  sendMessage(input: $input)
}
    `

export const useSendMessageMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    __GraphQLSchemaTypes.SendMessageMutation,
    TError,
    __GraphQLSchemaTypes.SendMessageMutationVariables,
    TContext
  >,
  headers?: RequestInit['headers']
) => {
  return useMutation<
    __GraphQLSchemaTypes.SendMessageMutation,
    TError,
    __GraphQLSchemaTypes.SendMessageMutationVariables,
    TContext
  >(
    ['SendMessage'],
    (variables?: __GraphQLSchemaTypes.SendMessageMutationVariables) =>
      fetcher<__GraphQLSchemaTypes.SendMessageMutation, __GraphQLSchemaTypes.SendMessageMutationVariables>(
        client,
        SendMessageDocument,
        variables,
        headers
      )(),
    options
  )
}

export const ToggleFavoriteListingDocument = `
    mutation ToggleFavoriteListing($toggleFavoriteListingInput: ToggleFavoriteListingInput!) {
  toggleFavoriteListing(toggleFavoriteListingInput: $toggleFavoriteListingInput) {
    isFavorite
  }
}
    `

export const useToggleFavoriteListingMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    __GraphQLSchemaTypes.ToggleFavoriteListingMutation,
    TError,
    __GraphQLSchemaTypes.ToggleFavoriteListingMutationVariables,
    TContext
  >,
  headers?: RequestInit['headers']
) => {
  return useMutation<
    __GraphQLSchemaTypes.ToggleFavoriteListingMutation,
    TError,
    __GraphQLSchemaTypes.ToggleFavoriteListingMutationVariables,
    TContext
  >(
    ['ToggleFavoriteListing'],
    (variables?: __GraphQLSchemaTypes.ToggleFavoriteListingMutationVariables) =>
      fetcher<
        __GraphQLSchemaTypes.ToggleFavoriteListingMutation,
        __GraphQLSchemaTypes.ToggleFavoriteListingMutationVariables
      >(client, ToggleFavoriteListingDocument, variables, headers)(),
    options
  )
}

export const ToggleFeedSubscribedDocument = `
    mutation ToggleFeedSubscribed($feedId: ID!) {
  toggleFeedSubscribed(feedId: $feedId) {
    subscribed
  }
}
    `

export const useToggleFeedSubscribedMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    __GraphQLSchemaTypes.ToggleFeedSubscribedMutation,
    TError,
    __GraphQLSchemaTypes.ToggleFeedSubscribedMutationVariables,
    TContext
  >,
  headers?: RequestInit['headers']
) => {
  return useMutation<
    __GraphQLSchemaTypes.ToggleFeedSubscribedMutation,
    TError,
    __GraphQLSchemaTypes.ToggleFeedSubscribedMutationVariables,
    TContext
  >(
    ['ToggleFeedSubscribed'],
    (variables?: __GraphQLSchemaTypes.ToggleFeedSubscribedMutationVariables) =>
      fetcher<
        __GraphQLSchemaTypes.ToggleFeedSubscribedMutation,
        __GraphQLSchemaTypes.ToggleFeedSubscribedMutationVariables
      >(client, ToggleFeedSubscribedDocument, variables, headers)(),
    options
  )
}

export const UpdateClientDocument = `
    mutation UpdateClient($input: UpdateClientInput!) {
  updateClient(input: $input) {
    updatedAt
    favoriteListingsAlertCadenceDays
  }
}
    `

export const useUpdateClientMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    __GraphQLSchemaTypes.UpdateClientMutation,
    TError,
    __GraphQLSchemaTypes.UpdateClientMutationVariables,
    TContext
  >,
  headers?: RequestInit['headers']
) => {
  return useMutation<
    __GraphQLSchemaTypes.UpdateClientMutation,
    TError,
    __GraphQLSchemaTypes.UpdateClientMutationVariables,
    TContext
  >(
    ['UpdateClient'],
    (variables?: __GraphQLSchemaTypes.UpdateClientMutationVariables) =>
      fetcher<__GraphQLSchemaTypes.UpdateClientMutation, __GraphQLSchemaTypes.UpdateClientMutationVariables>(
        client,
        UpdateClientDocument,
        variables,
        headers
      )(),
    options
  )
}

export const UpdateFavoriteListingDocument = `
    mutation UpdateFavoriteListing($listingId: ID!, $input: UpdateFavoriteListingInput!) {
  updateFavoriteListing(listingId: $listingId, input: $input) {
    updatedAt
    alertCadenceDays
    addressKey
  }
}
    `

export const useUpdateFavoriteListingMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    __GraphQLSchemaTypes.UpdateFavoriteListingMutation,
    TError,
    __GraphQLSchemaTypes.UpdateFavoriteListingMutationVariables,
    TContext
  >,
  headers?: RequestInit['headers']
) => {
  return useMutation<
    __GraphQLSchemaTypes.UpdateFavoriteListingMutation,
    TError,
    __GraphQLSchemaTypes.UpdateFavoriteListingMutationVariables,
    TContext
  >(
    ['UpdateFavoriteListing'],
    (variables?: __GraphQLSchemaTypes.UpdateFavoriteListingMutationVariables) =>
      fetcher<
        __GraphQLSchemaTypes.UpdateFavoriteListingMutation,
        __GraphQLSchemaTypes.UpdateFavoriteListingMutationVariables
      >(client, UpdateFavoriteListingDocument, variables, headers)(),
    options
  )
}

export const UpdateSavedSearchAlertCadenceDocument = `
    mutation UpdateSavedSearchAlertCadence($updateSavedSearchAlertCadenceId: ID!, $input: SavedSearchAlertCadenceInput!) {
  updateSavedSearchAlertCadence(
    id: $updateSavedSearchAlertCadenceId
    input: $input
  ) {
    id
  }
}
    `

export const useUpdateSavedSearchAlertCadenceMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    __GraphQLSchemaTypes.UpdateSavedSearchAlertCadenceMutation,
    TError,
    __GraphQLSchemaTypes.UpdateSavedSearchAlertCadenceMutationVariables,
    TContext
  >,
  headers?: RequestInit['headers']
) => {
  return useMutation<
    __GraphQLSchemaTypes.UpdateSavedSearchAlertCadenceMutation,
    TError,
    __GraphQLSchemaTypes.UpdateSavedSearchAlertCadenceMutationVariables,
    TContext
  >(
    ['UpdateSavedSearchAlertCadence'],
    (variables?: __GraphQLSchemaTypes.UpdateSavedSearchAlertCadenceMutationVariables) =>
      fetcher<
        __GraphQLSchemaTypes.UpdateSavedSearchAlertCadenceMutation,
        __GraphQLSchemaTypes.UpdateSavedSearchAlertCadenceMutationVariables
      >(client, UpdateSavedSearchAlertCadenceDocument, variables, headers)(),
    options
  )
}

export const CreateClientEventDocument = `
    mutation CreateClientEvent($clientEventInput: ClientEventInput!) {
  createClientEvent(clientEventInput: $clientEventInput)
}
    `

export const useCreateClientEventMutation = <TError = unknown, TContext = unknown>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    __GraphQLSchemaTypes.CreateClientEventMutation,
    TError,
    __GraphQLSchemaTypes.CreateClientEventMutationVariables,
    TContext
  >,
  headers?: RequestInit['headers']
) => {
  return useMutation<
    __GraphQLSchemaTypes.CreateClientEventMutation,
    TError,
    __GraphQLSchemaTypes.CreateClientEventMutationVariables,
    TContext
  >(
    ['CreateClientEvent'],
    (variables?: __GraphQLSchemaTypes.CreateClientEventMutationVariables) =>
      fetcher<__GraphQLSchemaTypes.CreateClientEventMutation, __GraphQLSchemaTypes.CreateClientEventMutationVariables>(
        client,
        CreateClientEventDocument,
        variables,
        headers
      )(),
    options
  )
}
