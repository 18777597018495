import omitBy from 'lodash/omitBy'
import {
  MIN_PRICE,
  MAX_PRICE,
  MAX_ACRES,
  MAX_SQFT,
  MAX_YEAR_BUILT,
  MIN_BATHROOMS_COUNT,
  MIN_BEDROOMS_COUNT,
  MIN_ACRES,
  MIN_SQFT,
  MIN_YEAR_BUILT,
  PROPERTY_TYPES,
  STATUSES,
  HAS_SELLER_CONCESSION,
  DOWN_PAYMENT,
  MIN_MONTHLY_PAYMENT,
  MAX_MONTHLY_PAYMENT,
  IS_ASSUMABLE,
  HAS_OPEN_HOUSES
} from '../../constants/listings'
import { ListingStatusEnum } from '../../types/listingStatus'

export const constructFiltersAndOmitEmpty = rawData => omitBy(constructFilters(rawData), value => !value)

export const constructFilters = rawData => {
  const maxPrice = parseInt(rawData[MAX_PRICE], 10) || null
  const maxAcres = parseFloat(rawData[MAX_ACRES]) || null
  const maxSqft = parseInt(rawData[MAX_SQFT], 10) || null
  const maxYearBuilt = rawData[MAX_YEAR_BUILT] || null
  const minBathroomsCount = parseInt(rawData[MIN_BATHROOMS_COUNT], 10) || null
  const minBedroomsCount = parseInt(rawData[MIN_BEDROOMS_COUNT], 10) || null
  const minPrice = parseInt(rawData[MIN_PRICE], 10) || null
  const downPayment = parseInt(rawData[DOWN_PAYMENT], 10) || null
  const minMonthlyPayment = parseInt(rawData[MIN_MONTHLY_PAYMENT], 10) || null
  const maxMonthlyPayment = parseInt(rawData[MAX_MONTHLY_PAYMENT], 10) || null
  const minAcres = parseFloat(rawData[MIN_ACRES]) || null
  const minSqft = parseInt(rawData[MIN_SQFT], 10) || null
  const minYearBuilt = rawData[MIN_YEAR_BUILT] || null
  const propertyTypes = rawData[PROPERTY_TYPES] || []
  const statuses = rawData[STATUSES] || [ListingStatusEnum.ACTIVE, ListingStatusEnum.COMING_SOON]
  const hasSellerConcession = rawData[HAS_SELLER_CONCESSION] || false
  const isAssumable = rawData[IS_ASSUMABLE] || false
  const hasOpenHouses = rawData[HAS_OPEN_HOUSES] || false

  const filters = {
    [MAX_PRICE]: maxPrice,
    [MAX_MONTHLY_PAYMENT]: maxMonthlyPayment,
    [MAX_ACRES]: maxAcres,
    [MAX_SQFT]: maxSqft,
    [MAX_YEAR_BUILT]: maxYearBuilt,
    [MIN_BATHROOMS_COUNT]: minBathroomsCount,
    [MIN_BEDROOMS_COUNT]: minBedroomsCount,
    [MIN_PRICE]: minPrice,
    [MIN_MONTHLY_PAYMENT]: minMonthlyPayment,
    [DOWN_PAYMENT]: (minMonthlyPayment || maxMonthlyPayment) && downPayment, // remove downpayment if no monthly payment, because downpayment is only relevant if there is a monthly payment
    [MIN_ACRES]: minAcres,
    [MIN_SQFT]: minSqft,
    [MIN_YEAR_BUILT]: minYearBuilt,
    [PROPERTY_TYPES]: propertyTypes,
    [STATUSES]: statuses,
    [HAS_SELLER_CONCESSION]: hasSellerConcession,
    [IS_ASSUMABLE]: isAssumable,
    [HAS_OPEN_HOUSES]: hasOpenHouses
  }

  return filters
}
