import { GeoAreaLevel } from '../../../../../../../api/gqlaxy/gql/generated/graphql'
import { CurrentSearchLocation } from '../../../../../GoogleAutoComplete/state/currentSearchLocation'
import { convertAutocompleteLevelToGeoAreaLevel } from './convertAutocompleteLevelToGeoAreaLevel'

export const convertSelectedLocationToGeoArea = (selectedLocation: CurrentSearchLocation | undefined) => {
  return {
    latitude: Number(selectedLocation?.lat ?? selectedLocation?.latitude),
    longitude: Number(selectedLocation?.lon ?? selectedLocation?.longitude),
    level: convertAutocompleteLevelToGeoAreaLevel(selectedLocation?.level),
    name: selectedLocation?.displayName ?? '',
    stateAbbreviation: selectedLocation?.stateAbbreviation ?? '',
    city: selectedLocation?.level === 'city' ? selectedLocation?.name : undefined,
    ...(convertAutocompleteLevelToGeoAreaLevel(selectedLocation?.level) === GeoAreaLevel.PostalCode
      ? { zipCode: selectedLocation?.name }
      : {})
  }
}
