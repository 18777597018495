import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import flatten from 'lodash/flatten'
import get from 'lodash/get'
import { createContext, useContext, useState, useMemo, useCallback, useEffect } from 'react'
import { toggleListingSearchModal } from '../../actions/listings'
import { selectCustomerId } from '../../store/selectors/customerProfile'
import {
  DEBOUNCE_TIME_LISTING_SEARCH,
  LISTING_INPUT_ID,
  LISTING_RESULTS_LIST_ID,
  LISTING_DESCRIBE_ID,
  PLACE_SEARCHES,
  NO_OP_MORE_BUTTON,
  RECENT_SEARCHES,
  NUMBER_OF_SAVED_RECENT_SEARCHES
} from '../../constants/listings'
import { constructFiltersAndOmitEmpty } from '../../helpers/listings/filters'
import { formatDisplayNameByLocationLevel } from '../../helpers/listings/search'
import { getLocations } from '../../api/mikasa/requests'
import useDebounce from '../../hooks/useDebounce'
import useRouteToListingsOnSearch from '../../hooks/listings/useRouteToListingsOnSearch'
import { useRecoilState } from 'recoil'
import { currentSelectedLocation } from 'src/Components/homeSearch/GoogleAutoComplete/state/currentSelectedLocation'

const ListingSearchContext = createContext()

// TODO - Part II - Add some specs for this file
const ListingSearchProvider = ({ children }) => {
  const dispatch = useDispatch()
  const customerId = useSelector(selectCustomerId)

  const isLead = useSelector(state => state.auth.isLead)

  const [_, setSelectedLocation] = useRecoilState(currentSelectedLocation)

  const inputId = LISTING_INPUT_ID
  const resultsListId = LISTING_RESULTS_LIST_ID
  const describeId = LISTING_DESCRIBE_ID

  const formatRecentSearches = useCallback(() => {
    return JSON.parse(localStorage.getItem(RECENT_SEARCHES)) || []
  }, [])

  const initialResults = useMemo(() => {
    if (isLead) {
      return []
    }
    const formattedRecentSearches = formatRecentSearches()

    return formattedRecentSearches.length
      ? {
          [RECENT_SEARCHES]: formattedRecentSearches
        }
      : {}
  }, [formatRecentSearches])

  const determineIsInEmptyState = useCallback(() => isEmpty(initialResults), [initialResults])

  const initialState = useMemo(
    () => ({
      results: initialResults,
      inputValue: '',
      selectedIndex: null,
      loading: false,
      isInInitialState: true,
      isInEmptyState: determineIsInEmptyState(),
      isInNoResultsState: false,
      filter: null
    }),
    [initialResults, determineIsInEmptyState]
  )

  const [state, setState] = useState(initialState)

  const { results, inputValue, loading, isInInitialState, isInNoResultsState, selectedIndex, filter, isInEmptyState } =
    state

  const flattenedResults = useMemo(() => flatten(Object.values(results)), [results])

  useRouteToListingsOnSearch(filter, customerId)

  const debouncedInputValue = useDebounce(inputValue, DEBOUNCE_TIME_LISTING_SEARCH)

  const isValidSearchLength = param => param.length >= 2

  const resetForm = useCallback(() => {
    setState(initialState)
  }, [initialState])

  const formatLocationSearchResults = results => {
    return results?.map(result => {
      const level = result?.level
      const name = result?.displayName

      return {
        ...result,
        displayName: formatDisplayNameByLocationLevel(level, name)
      }
    })
  }

  const fetchResults = useCallback(async param => {
    const resp = await getLocations({ query: param })
    const listingResults = formatLocationSearchResults(resp?.data?.data)
    // filter out locations without lat and lon for landing page flow
    let filteredListingResults
    if (isLead) {
      filteredListingResults = listingResults.filter(location => location.lat != null && location.lon != null)
    } else {
      filteredListingResults = listingResults
    }
    // Handle No Results
    if (isEmpty(filteredListingResults)) {
      setState(prevState => ({
        ...prevState,
        loading: false,
        isInEmptyState: false,
        isInNoResultsState: true,
        results: {}
      }))

      return
    }

    // Handle Valid Results
    setState(prevState => ({
      ...prevState,
      loading: false,
      results: { [PLACE_SEARCHES]: filteredListingResults },
      selectedIndex: null,
      isInEmptyState: false
    }))
  }, [])

  // Begin Fetch Results once debounced input >= 2 character
  useEffect(() => {
    if (isValidSearchLength(debouncedInputValue)) {
      fetchResults(debouncedInputValue)
    }
  }, [fetchResults, debouncedInputValue])

  // Toggle initial/default state based on input value length
  useEffect(() => {
    if (isValidSearchLength(inputValue)) {
      setState(prevState => ({
        ...prevState,
        isInInitialState: false
      }))
    } else {
      setState(prevState => ({
        ...prevState,
        isInInitialState: true
      }))
    }
  }, [inputValue, initialResults])

  // Reset to initial state once input value length is <= 2 characters
  useEffect(() => {
    if (isInInitialState) {
      setState(prevState => ({
        ...prevState,
        results: initialResults,
        isInEmptyState: determineIsInEmptyState()
      }))
    }
  }, [isInInitialState, initialResults, determineIsInEmptyState])

  // Toggle Loading if Input Value changes and Input Value is valid length
  useEffect(() => {
    const inputValueChanging = inputValue !== debouncedInputValue && isValidSearchLength(inputValue)

    if (!isInInitialState && !loading) {
      setState(prevState => ({
        ...prevState,
        loading: inputValueChanging
      }))
    }
  }, [inputValue, debouncedInputValue, isInInitialState, loading])

  // Close modal after search location sent
  useEffect(() => {
    if (filter) {
      dispatch(toggleListingSearchModal(false))
    }
  }, [dispatch, filter])

  const setRecentSearchInLocalStorage = selectedResult => {
    const recentSearches = JSON.parse(localStorage.getItem(RECENT_SEARCHES)) || []

    const previousSearch = recentSearches[0]

    if (previousSearch?.id !== selectedResult.id) {
      recentSearches.unshift(selectedResult)
    }

    if (recentSearches.length > NUMBER_OF_SAVED_RECENT_SEARCHES) {
      recentSearches.pop()
    }

    localStorage.setItem(RECENT_SEARCHES, JSON.stringify(recentSearches))
  }

  const handleLocationSelection = useCallback((selectedResult, currentFilter) => {
    if (isLead) {
      setSelectedLocation(selectedResult)
    }
    // Hack to set the correct location for the search filter
    // Saved Searches and Location Searches have a different api responses
    const savedSearchResult = selectedResult?.location
    const placesSearchResult = selectedResult

    if (!isLead) {
      setRecentSearchInLocalStorage(selectedResult)
    }

    const additionalFilters = constructFiltersAndOmitEmpty(selectedResult)
    // default to setting status to active when navigating from the search modal
    // ideally this would use "ListingStatusEnum.ACTIVE" & "ListingStatusEnum.COMING_SOON" but converting this file to TS is going to be a slog
    additionalFilters?.statuses?.push('Active', 'Coming Soon')
    // calling this setState triggers the useRouteToListingsOnSearch hook which does a history.push to the listings list view

    if (!isLead) {
      setState(prevState => ({
        ...prevState,
        filter: {
          additionalFilters,
          ...currentFilter,
          location: savedSearchResult || placesSearchResult,
          page: 1
        }
      }))
    }
  }, [])

  const selectedResultFromState = useMemo(() => flattenedResults[selectedIndex], [flattenedResults, selectedIndex])

  const handleUserAction = (type, index, currentFilter) => {
    const selectedResult = get(results, `${type}.${index}`) || selectedResultFromState

    if (!selectedResult) return

    if (selectedResult?.id) {
      handleLocationSelection(selectedResult, currentFilter)
    }
  }

  const handleOnChange = useCallback(
    ({ target: { value } }) => {
      if (isEmpty(value)) {
        resetForm()
        return
      }

      setState(prevState => ({
        ...prevState,
        inputValue: value,
        isInNoResultsState: false
      }))
    },
    [resetForm]
  )

  const handleArrowDown = useCallback(() => {
    if (selectedIndex === initialState.selectedIndex) {
      const nextIndex = selectedIndex + 0

      setState(prevState => ({
        ...prevState,
        selectedIndex: nextIndex
      }))

      return
    }

    if (selectedIndex + 1 < flattenedResults.length) {
      const nextIndex = selectedIndex + 1

      setState(prevState => ({
        ...prevState,
        selectedIndex: nextIndex
      }))
    }
  }, [flattenedResults, selectedIndex, initialState.selectedIndex])

  const handleArrowUp = useCallback(() => {
    if (selectedIndex === 0) {
      setState(prevState => ({
        ...prevState,
        selectedIndex: null
      }))

      return
    }

    if (selectedIndex > 0) {
      const nextSelectedIndex = selectedIndex - 1

      setState(prevState => ({
        ...prevState,
        selectedIndex: nextSelectedIndex
      }))
    }
  }, [selectedIndex])

  const handleBackSpace = useCallback(() => {
    setState(prevState => ({
      ...prevState,
      selectedIndex: 0
    }))
  }, [])

  const handleEscape = useCallback(() => {
    resetForm()
    dispatch(toggleListingSearchModal(false))
  }, [dispatch, resetForm])

  const handleKeyDown = useCallback(
    event => {
      const { key } = event
      switch (key) {
        case 'ArrowDown': {
          handleArrowDown()
          break
        }
        case 'ArrowUp': {
          handleArrowUp()
          break
        }
        case 'Enter': {
          event.preventDefault()
          handleUserAction()
          break
        }
        case 'Backspace': {
          handleBackSpace()
          break
        }
        case 'Escape': {
          handleEscape()
          break
        }
        default:
      }
    },
    [handleUserAction, handleArrowDown, handleArrowUp, handleBackSpace, handleEscape]
  )

  const handleOnClick = useCallback(
    (type, index, currentFilter) => handleUserAction(type, index, currentFilter),
    [handleUserAction]
  )

  const handleOnTouchStart = useCallback(index => {
    setState(prevState => ({
      ...prevState,
      selectedIndex: index
    }))
  }, [])

  const hasResultsAfterSearch = !isEmpty(results)

  const activeDescendantId = (() => {
    if (hasResultsAfterSearch && selectedIndex !== null) {
      const selectedLocation = results[selectedIndex]
      return selectedLocation?.id
    }

    return ''
  })()

  const store = useMemo(
    () => ({
      handleOnChange,
      handleKeyDown,
      inputValue,
      loading,
      results,
      isInInitialState,
      isInNoResultsState,
      hasResultsAfterSearch,
      selectedIndex,
      handleOnClick,
      handleOnTouchStart,
      inputId,
      describeId,
      activeDescendantId,
      resultsListId,
      flattenedResults,
      isInEmptyState
    }),
    [
      handleOnChange,
      handleKeyDown,
      inputValue,
      loading,
      results,
      isInInitialState,
      isInNoResultsState,
      hasResultsAfterSearch,
      selectedIndex,
      handleOnClick,
      handleOnTouchStart,
      inputId,
      describeId,
      activeDescendantId,
      resultsListId,
      flattenedResults,
      isInEmptyState
    ]
  )

  return <ListingSearchContext.Provider value={store}>{children}</ListingSearchContext.Provider>
}

const useListingSearchContext = () => {
  const context = useContext(ListingSearchContext)

  if (!context) {
    throw new Error('useSearchProvider must be used within an SearchProvider')
  }

  return { ...context }
}

ListingSearchProvider.propTypes = {
  children: PropTypes.any.isRequired
}

export { ListingSearchProvider, useListingSearchContext }
