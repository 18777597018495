export enum ListingStatus {
  Active = 'Active',
  ActiveUnderContract = 'Active Under Contract',
  Pending = 'Pending',
  ComingSoon = 'Coming Soon',
  Closed = 'Closed',
  Canceled = 'Canceled',
  Expired = 'Expired',
  Hold = 'Hold',
  OffMarket = 'Off Market',
  Withdrawn = 'Withdrawn'
}

export interface CurrencyOptions {
  grouping?: boolean
  minorDigits?: boolean
  symbol?: boolean
  sign?: boolean
  short?: 'auto' | 'K' | 'M' | boolean
  cutoff?: number
}

export const DEFAULT_CURRENCY_OPTIONS = {
  grouping: true,
  minorDigits: true,
  symbol: true,
  sign: false,
  short: false,
  cutoff: 100
} as const

export interface ListingAgent {
  email?: string
  mlsId?: string
  name?: string
  phone?: string
  stateLicenseState?: string
  stateLicenseNumber?: string
}

export interface ListingAddress {
  number?: string
  street?: string
  city?: string
  unitNumber?: string
  stateOrProvince: string
  postalCode: string
  latitude: number
  longitude: number
}

export interface ListingCompact {
  address: ListingAddress
  agent: ListingAgent // always present, but can be empty
  /** @depreciated */
  agentName?: string
  /** @depreciated */
  architectureStyle?: string
  description?: string
  disclaimer: string
  id: string
  /** @depreciated */
  imageCoverUrl: string
  /** @depreciated */
  lastCheckedAt: string // ISO date string
  mlsLogo?: string
  mlsNumber: string
  originatingSystemName: string
  /** @depreciated */
  parkingTypes?: unknown
  previousListPriceCents: number
  priceCents: number
  priceChangeTimestamp?: string // iso date
  propertyType: string
  brokerage?: {
    name?: string
    phone?: string
  }
  bedroomsCount?: number
  bathroomsCount?: number
  sqftTotal?: number
  sqftLot?: number
  showMapLink?: boolean
  status: ListingStatus
  thumbnailImageFileNames?: string[]
  updatedAt: string // ISO date string
  yearBuilt?: number
}
