import { matchPath } from 'react-router'
import { defineMessages } from 'react-intl'
import qs from 'qs'
import { getCurrentPath } from '../helpers/browser'
import { kebablizeCamelCasedString } from '../helpers/utils'

export const LISTING = 'listing'
export const MARKET_TAB = 'MARKET_TAB'
export const NAVIGATION_DELAY = 600
export const SOLO_BUYER = 'SOLO_BUYER'
export const TOS_LINK = 'https://homebot.ai/terms-of-service'
export const PRIVACY_LINK = 'https://homebot.ai/privacy-policy'
export const A11Y_LINK = 'https://homebot.ai/accessibility-statement'
export const DESKTOP_NAV_DROPDOWN_OPEN_DELAY = 350
export const DESKTOP_NAV_DROPDOWN_CLOSE_DELAY = 500
export const NAVBAR_DROPDOWN_ID = 'navbar-dropdown'

export const PATH_HELPERS = {
  buy: {
    matchPath: '/reports/:clientId([-0-9A-Fa-f]{36})/buy',
    regex: /^\/reports\/([-0-9A-Fa-f]{36}|initialize)\/buy/,
    buildPath: (clientId, destination) => (clientId ? `/reports/${clientId}/buy${destination || ''}` : '/')
  },
  client: {
    matchPath: '/reports/:clientId([-0-9A-Fa-f]{36})',
    regex: /^\/reports\/([-0-9A-Fa-f]{36})/,
    buildPath: clientId => (clientId ? `/reports/${clientId}` : '/')
  },
  home: {
    matchPath: '/reports/:clientId([-0-9A-Fa-f]{36})/home/:homeId([0-9A-Fa-f]{24})',
    regex: /^\/reports\/([-0-9A-Fa-f]{36})\/home\/([0-9A-Fa-f]{24})/,
    buildPath: (clientId, homeId) => (clientId && homeId ? `/reports/${clientId}/home/${homeId}` : '/')
  },
  homeSearch: {
    matchPath: '/home-search/:customerProfileId([-0-9A-Fa-f]{36})',
    regex: /^\/home-search\/([-0-9A-Fa-f]{36})/,
    buildPath: customerProfileId => (customerProfileId ? `/home-search/${customerProfileId}` : '/')
  },
  searchLandingPage: {
    matchPath: '/home-search/lead/:customerProfileId([-0-9A-Fa-f]{36})',
    regex: /^\/home-search\/\/lead\/([-0-9A-Fa-f]{36})/,
    buildPath: customerProfileId => (customerProfileId ? `/home-search/lead/${customerProfileId}` : '/')
  },
  markets: {
    matchPath: '/reports/:clientId([-0-9A-Fa-f]{36})/markets',
    regex: /^\/reports\/([-0-9A-Fa-f]{36}|initialize)\/markets/,
    buildPath: (clientId, marketPath) => (clientId ? `/reports/${clientId}/markets${marketPath || ''}` : '/')
  },
  report: {
    matchPath: '/reports/:clientId([-0-9A-Fa-f]{36})/:routeKey(home|buy)/:reportResourceId([-0-9A-Fa-f]{24,36})?'
  },
  signup: {
    buildPath: redirectUrl => (redirectUrl ? `/sign-up?redirectUrl=${redirectUrl}` : `/sign-up`)
  },
  tuneYourValue: {
    matchPath: '/reports/:clientId([-0-9A-Fa-f]{36})/home/:homeId([0-9A-Fa-f]{24})/tune-your-value',
    regex: /^\/reports\/([-0-9A-Fa-f]{36})\/home\/([0-9A-Fa-f]{24}|new)/,
    buildPath: (clientId, homeId) => (clientId && homeId ? `/reports/${clientId}/home/${homeId}/tune-your-value` : '/')
  },
  zip: {
    matchPath: '/reports/:clientId([-0-9A-Fa-f]{36})/markets/:zipcode([0-9]{5})',
    regex: /^\/reports\/([-0-9A-Fa-f]{36}|initialize)\/markets\/([0-9]{5})/,
    buildPath: (clientId, zipcode) => (clientId ? `/reports/${clientId}/markets/${zipcode || ''}` : '/')
  },
  listing: {
    // eslint-disable-next-line prettier/prettier
    matchPath: '/home-search/:customerProfileId([-0-9A-Fa-f]{36})/listing/:listingId',
    regex: /^\/home-search\/([-0-9A-Fa-f]{36})\/listing\/([-0-9A-Fa-f]{36}|\d+)/,
    buildPath: (customerProfileId, listingId) =>
      customerProfileId && listingId ? `/home-search/${customerProfileId}/listing/${listingId}` : '/'
  },
  publicListing: {
    // eslint-disable-next-line prettier/prettier
    matchPath: '/home-search/public/listing/:listingId',
    regex: /^\/home-search\/public\/listing\/([-0-9A-Fa-f]{36}|\d+)/,
    buildPath: listingId => (listingId ? `/home-search/public/listing/${listingId}` : '/')
  },
  publicListings: {
    matchPath: '/home-search/public/listings/',
    regex: /^\/home-search\/public\/listings/,
    buildPath: (level, name, additionalFilters) => {
      let additional = qs.stringify(additionalFilters)
      additional = additional ? `&${additional}` : ''

      return `/home-search/public/listings/?${level}=${name}${additional}`
    }
  },
  listings: {
    matchPath: '/home-search/:customerProfileId([-0-9A-Fa-f]{36})/listings/',
    regex: /^\/home-search\/([-0-9A-Fa-f]{36})\/listings/,
    buildPath: (customerProfileId, level, name, additionalFilters) => {
      let additional = qs.stringify(additionalFilters)
      additional = additional ? `&${additional}` : ''

      const url = customerProfileId ? `/home-search/${customerProfileId}/listings?${level}=${name}${additional}` : '/'
      return url
    }
  },
  listingsIndex: {
    buildPath: customerProfileId => {
      const url = customerProfileId ? `/home-search/${customerProfileId}` : '/'
      return url
    }
  },
  purl: {
    buildPath: customerProfileId => `${process.env.REACT_APP_PURL_URL}?id=${customerProfileId}` // Beats me, but this is the only url ENV var that has a backslash already in it
  },
  homePriceIndexWidget: {
    matchPath: '/reports/:clientId([-0-9A-Fa-f]{36})/home/:homeId([0-9A-Fa-f]{24})/home-price-index-widget',
    regex: /^\/reports\/([-0-9A-Fa-f]{36})\/home\/([0-9A-Fa-f]{24}|new)/,
    buildPath: (clientId, homeId) =>
      clientId && homeId ? `/reports/${clientId}/home/${homeId}/home-price-index-widget` : '/'
  }
}

// NEW SEARCH ROUTES
export const SEARCH_PATH_HELPERS = {
  search: {
    regex: /^\/home-search\/([-0-9A-Fa-f]{36})/,
    buildPath: customerProfileId => (customerProfileId ? `/home-search/${customerProfileId}` : '/')
  },
  savedSearches: {
    regex: /^\/home-search\/([-0-9A-Fa-f]{36})\/saved-searches/,
    buildPath: customerProfileId => (customerProfileId ? `/home-search/${customerProfileId}/saved-searches` : '/')
  },
  favoriteListings: {
    buildPath: (customerProfileId, clientId) =>
      customerProfileId
        ? `/home-search/${customerProfileId}/favorite-listings${clientId ? `?clientId=${clientId}` : ''}`
        : '/'
  },
  forYouTab: {
    regex: /^\/for-you\/([-0-9A-Fa-f]{36})\?clientId=([-0-9A-Fa-f]{36})/,
    buildPath: (customerProfileId, clientId) =>
      customerProfileId ? `/for-you/${customerProfileId}?clientId=${clientId}` : '/'
  },
  gallery: {
    buildPath: (customerProfileId, feedId, feedType, clientId) =>
      customerProfileId && feedId && feedType
        ? `/for-you/${customerProfileId}/gallery/${kebablizeCamelCasedString(feedType)}/${feedId}?clientId=${clientId}`
        : '/'
  }
}

const OVERLAY_PANEL_TITLES = defineMessages({
  airDna: {
    id: 'Homeowners.overlay.airDna.learnMore.title',
    defaultMessage: 'Airbnb rental potential'
  },
  addHome: {
    id: 'HomeownerNavigationLinksOptions.AddHome',
    defaultMessage: 'Add a Home'
  },
  avmDiffWarning: {
    id: 'Homeowners.overlay.avmDiffWarning.title',
    defaultMessage: 'Your home value'
  },
  avmHotMarketWarning: {
    id: 'Homeowners.overlay.avmHotMarketWarning.title',
    defaultMessage: 'Your home value'
  },
  emailUpdates: {
    id: 'Homeowners.overlay.emailUpdates.title',
    defaultMessage: 'Email updates'
  },
  equitySmartSearch: {
    id: 'Homeowners.overlay.equitySmartSearchCalculation.title',
    defaultMessage: 'How is this calculated?'
  },
  instantOffer: {
    id: 'Homeowners.overlay.instantOffer.title',
    defaultMessage: 'Instant Offer'
  },
  marketSpotlight: {
    id: 'Homeowners.overlay.marketSpotlight.title',
    defaultMessage: 'Your Market'
  },
  mortgageCoach: {
    id: 'Homeowners.overlay.mortgageCoach.title',
    defaultMessage: 'Mortgage Coach'
  },
  mortgageRelief: {
    id: 'Homeowners.overlay.mortgageRelief.title',
    defaultMessage: 'Mortgage Payment Options'
  },
  nonDisclosureWarning: {
    id: 'Homeowners.overlay.nonDisclosureWarning.title',
    defaultMessage: 'Your home value'
  },
  yourInsurance: {
    id: 'Homeowners.overlay.homeInsurance.yourInsurance.title',
    defaultMessage: 'Your Insurance'
  },
  moreDetails: {
    id: 'Homeowners.overlay.homeInsurance.moreDetails.title',
    defaultMessage: "Homeowner's Insurance"
  },
  preferences: {
    id: 'Homeowners.overlay.preferences.title',
    defaultMessage: 'Manage Preferences'
  },
  principalPayment: {
    id: 'Homeowners.overlay.principal.title',
    defaultMessage: 'Learn More'
  },
  purchasing: {
    id: 'Homeowners.overlay.purchasing.title',
    defaultMessage: 'Purchasing Power'
  },
  refinance: {
    id: 'Homeowners.overlay.refinance.title',
    defaultMessage: 'Refi Options'
  },
  refiRateRequest: {
    id: 'Homeowners.overlay.refinance.getRate.title',
    defaultMessage: 'Get Your Rate'
  },
  reverseMortgage: {
    id: 'Homeowners.overlay.reverseMortgage.title',
    defaultMessage: 'Reverse Mortgage'
  },
  shareHomebot: {
    id: 'Homeowners.overlay.shareHomebot.title',
    defaultMessage: 'Share Homebot'
  },
  shouldYouSell: {
    id: 'Homeowners.overlay.shouldYouSell.title',
    defaultMessage: 'Should you sell?'
  },
  team: {
    id: 'Homeowners.overlay.team.title',
    defaultMessage: 'Your Team'
  },
  textUpdates: {
    id: 'Homeowners.overlay.textUpdates.title',
    defaultMessage: 'Text Updates'
  },
  tuneYourValue: {
    id: 'Homeowners.overlay.tyv.title',
    defaultMessage: 'Tune Your Value'
  },
  unpaidBalance: {
    id: 'Homeowners.overlay.unpaidBalance.title',
    defaultMessage: 'Update your loan balance'
  },
  pmiLearnMore: {
    id: 'Homeowners.overlay.pmiLearnMore.title',
    defaultMessage: 'Mortgage Insurance (PMI)'
  },
  homeValueEstimate: {
    id: 'Homeowners.overlay.homeValueEstimate.title',
    defaultMessage: 'Home value estimate'
  },
  homePriceIndexWidget: {
    id: 'Homeowners.overlay.homePriceIndexWidget.title',
    defaultMessage: 'Quick Check'
  },
  renovations: {
    id: 'EquityUnlock.overlay.renovations.title',
    defaultMessage: 'Using equity to renovate'
  },
  equityUnlock: {
    id: 'EquityUnlock.overlay.equityUnlock.title',
    defaultMessage: 'Equity Unlock'
  }
})

export const OVERLAY_HELPERS = {
  airDnaLearnMore: {
    overlay: 'air-dna',
    titleMessage: OVERLAY_PANEL_TITLES.airDna,
    matchPath: [
      `${PATH_HELPERS.home.matchPath}/air-dna/learn-more`,
      `${PATH_HELPERS.buy.matchPath}/air-dna/learn-more`
    ],
    getOrigin: () => {
      const homeRoutes = getCurrentPath().match(PATH_HELPERS.home.regex)
      const buyRoutes = getCurrentPath().match(PATH_HELPERS.buy.regex)
      const matchedRoutes = homeRoutes || buyRoutes
      return matchedRoutes ? matchedRoutes[0] : null
    },
    buildPath: () => {
      const homeRoutes = getCurrentPath().match(PATH_HELPERS.home.regex)
      const buyRoutes = getCurrentPath().match(PATH_HELPERS.buy.regex)
      const matchedRoutes = homeRoutes || buyRoutes
      return matchedRoutes ? `${matchedRoutes[0]}/air-dna/learn-more` : '/not-found'
    }
  },
  addHome: {
    overlay: 'add-home',
    titleMessage: OVERLAY_PANEL_TITLES.addHome,
    matchPath: `${PATH_HELPERS.client.matchPath}/home/new`,
    buildPath: () => {
      const clientRoutes = getCurrentPath().match(PATH_HELPERS.client.regex)
      return clientRoutes ? `${clientRoutes[0]}/home/new` : '/not-found'
    }
  },
  avmDiffWarning: {
    overlay: 'your-home-value',
    titleMessage: OVERLAY_PANEL_TITLES.avmDiffWarning,
    matchPath: `${PATH_HELPERS.home.matchPath}/your-home-value`,
    getOrigin: () => {
      const homeRoutes = getCurrentPath().match(PATH_HELPERS.home.regex)
      return homeRoutes ? homeRoutes[0] : '/'
    },
    buildPath: () => {
      const homeRoutes = getCurrentPath().match(PATH_HELPERS.home.regex)
      return homeRoutes ? `${homeRoutes[0]}/your-home-value` : '/not-found'
    }
  },
  avmHotMarketWarning: {
    overlay: 'high-demand-home-value',
    titleMessage: OVERLAY_PANEL_TITLES.avmHotMarketWarning,
    matchPath: `${PATH_HELPERS.home.matchPath}/high-demand-home-value`,
    getOrigin: () => {
      const homeRoutes = getCurrentPath().match(PATH_HELPERS.home.regex)
      return homeRoutes ? homeRoutes[0] : '/'
    },
    buildPath: () => {
      const homeRoutes = getCurrentPath().match(PATH_HELPERS.home.regex)
      return homeRoutes ? `${homeRoutes[0]}/high-demand-home-value` : '/not-found'
    }
  },
  cashoutEmergencyReserve: {
    overlay: 'cashoutEmergencyReserve',
    titleMessage: OVERLAY_PANEL_TITLES.equityUnlock,
    matchPath: `${PATH_HELPERS.home.matchPath}/cashout/emergency-reserve`,
    getOrigin: () => {
      const homeRoutes = getCurrentPath().match(PATH_HELPERS.home.regex)
      return homeRoutes ? homeRoutes[0] : null
    },
    buildPath: () => {
      const homeRoutes = getCurrentPath().match(PATH_HELPERS.home.regex)
      return homeRoutes ? `${homeRoutes[0]}/cashout/emergency-reserve` : '/not-found'
    }
  },
  cashoutOtherOptions: {
    overlay: 'cashoutOtherOptions',
    titleMessage: OVERLAY_PANEL_TITLES.equityUnlock,
    matchPath: `${PATH_HELPERS.home.matchPath}/cashout/other-options`,
    getOrigin: () => {
      const homeRoutes = getCurrentPath().match(PATH_HELPERS.home.regex)
      return homeRoutes ? homeRoutes[0] : null
    },
    buildPath: () => {
      const homeRoutes = getCurrentPath().match(PATH_HELPERS.home.regex)
      return homeRoutes ? `${homeRoutes[0]}/cashout/other-options` : '/not-found'
    }
  },
  cashoutLearnMore: {
    overlay: 'cashoutLearnMore',
    titleMessage: OVERLAY_PANEL_TITLES.equityUnlock,
    matchPath: `${PATH_HELPERS.home.matchPath}/cashout/learn-more`,
    getOrigin: () => {
      const homeRoutes = getCurrentPath().match(PATH_HELPERS.home.regex)
      return homeRoutes ? homeRoutes[0] : null
    },
    buildPath: () => {
      const homeRoutes = getCurrentPath().match(PATH_HELPERS.home.regex)
      return homeRoutes ? `${homeRoutes[0]}/cashout/learn-more` : '/not-found'
    }
  },
  debtCalculator: {
    overlay: 'debtCalculator',
    titleMessage: OVERLAY_PANEL_TITLES.equityUnlock,
    matchPath: `${PATH_HELPERS.home.matchPath}/cashout/debt-calculator`,
    getOrigin: () => {
      const homeRoutes = getCurrentPath().match(PATH_HELPERS.home.regex)
      return homeRoutes ? homeRoutes[0] : null
    },
    buildPath: () => {
      const homeRoutes = getCurrentPath().match(PATH_HELPERS.home.regex)
      return homeRoutes ? `${homeRoutes[0]}/cashout/debt-calculator` : '/not-found'
    }
  },
  debtCalculatorCustom: {
    overlay: 'debtCalculatorCustom',
    titleMessage: OVERLAY_PANEL_TITLES.equityUnlock,
    matchPath: `${PATH_HELPERS.home.matchPath}/cashout/debt-calculator-custom`,
    getOrigin: () => {
      const homeRoutes = getCurrentPath().match(PATH_HELPERS.home.regex)
      return homeRoutes ? homeRoutes[0] : null
    },
    buildPath: () => {
      const homeRoutes = getCurrentPath().match(PATH_HELPERS.home.regex)
      return homeRoutes ? `${homeRoutes[0]}/cashout/debt-calculator-custom` : '/not-found'
    }
  },
  debtCalculatorCustomResults: {
    overlay: 'debtCalculatorCustomResults',
    titleMessage: OVERLAY_PANEL_TITLES.equityUnlock,
    matchPath: `${PATH_HELPERS.home.matchPath}/cashout/debt-calculator-custom-results`,
    getOrigin: () => {
      const homeRoutes = getCurrentPath().match(PATH_HELPERS.home.regex)
      return homeRoutes ? homeRoutes[0] : null
    },
    buildPath: () => {
      const homeRoutes = getCurrentPath().match(PATH_HELPERS.home.regex)
      return homeRoutes ? `${homeRoutes[0]}/cashout/debt-calculator-custom-results` : '/not-found'
    }
  },
  emailUpdates: {
    overlay: 'email-updates',
    titleMessage: OVERLAY_PANEL_TITLES.emailUpdates,
    matchPath: [`${PATH_HELPERS.home.matchPath}/email-updates`, `${PATH_HELPERS.buy.matchPath}/email-updates`],
    getOrigin: () => {
      const homeRoutes = getCurrentPath().match(PATH_HELPERS.home.regex)
      const buyRoutes = getCurrentPath().match(PATH_HELPERS.buy.regex)
      const matchedRoutes = homeRoutes || buyRoutes
      return matchedRoutes ? matchedRoutes[0] : null
    },
    buildPath: () => {
      const homeRoutes = getCurrentPath().match(PATH_HELPERS.home.regex)
      const buyRoutes = getCurrentPath().match(PATH_HELPERS.buy.regex)
      const matchedRoutes = homeRoutes || buyRoutes
      return matchedRoutes ? `${matchedRoutes[0]}/email-updates` : '/not-found'
    }
  },
  equitySmartSearch: {
    overlay: 'how-is-this-calculated',
    titleMessage: OVERLAY_PANEL_TITLES.equitySmartSearch,
    matchPath: `${PATH_HELPERS.home.matchPath}/how-is-this-calculated`,
    getOrigin: () => {
      const homeRoutes = getCurrentPath().match(PATH_HELPERS.home.regex)
      return homeRoutes ? homeRoutes[0] : '/'
    },
    buildPath: () => {
      const homeRoutes = getCurrentPath().match(PATH_HELPERS.home.regex)
      return homeRoutes ? `${homeRoutes[0]}/how-is-this-calculated` : '/not-found'
    }
  },
  homeInsurance: {
    overlay: 'home-insurance',
    titleMessage: OVERLAY_PANEL_TITLES.homeInsurance,
    matchPath: `${PATH_HELPERS.home.matchPath}/home-insurance`,
    getOrigin: () => {
      const homeRoutes = getCurrentPath().match(PATH_HELPERS.home.regex)
      return homeRoutes ? homeRoutes[0] : '/'
    },
    buildPath: path => {
      const homeRoutes = getCurrentPath().match(PATH_HELPERS.home.regex)
      return homeRoutes ? `${homeRoutes[0]}/home-insurance/${path}` : '/not-found'
    }
  },
  instantOffer: {
    overlay: 'instant-offer',
    titleMessage: OVERLAY_PANEL_TITLES.instantOffer,
    matchPath: `${PATH_HELPERS.home.matchPath}/instant-offer`,
    getOrigin: () => {
      const homeRoutes = getCurrentPath().match(PATH_HELPERS.home.regex)
      return homeRoutes ? homeRoutes[0] : '/'
    },
    buildPath: () => {
      const homeRoutes = getCurrentPath().match(PATH_HELPERS.home.regex)
      return homeRoutes ? `${homeRoutes[0]}/instant-offer` : '/not-found'
    }
  },
  marketSpotlight: {
    overlay: 'your-market',
    titleMessage: OVERLAY_PANEL_TITLES.marketSpotlight,
    matchPath: `${PATH_HELPERS.home.matchPath}/your-market`,
    getOrigin: () => {
      const homeRoutes = getCurrentPath().match(PATH_HELPERS.home.regex)
      return homeRoutes ? homeRoutes[0] : '/'
    },
    buildPath: () => {
      const homeRoutes = getCurrentPath().match(PATH_HELPERS.home.regex)
      return homeRoutes ? `${homeRoutes[0]}/your-market` : '/not-found'
    }
  },
  mortgageCoach: {
    overlay: 'mortgage-coach',
    titleMessage: OVERLAY_PANEL_TITLES.mortgageCoach,
    matchPath: `${PATH_HELPERS.home.matchPath}/refi-options/:refiKey([^/]+?)/mortgage-coach`,
    getOrigin: () => {
      const homeRoutes = getCurrentPath().match(`${PATH_HELPERS.home.regex}/refi-options/([^/]+?)`)
      return homeRoutes ? homeRoutes[0] : null
    },
    buildPath: () => {
      const homeRoutes = getCurrentPath().match(`${PATH_HELPERS.home.regex}/refi-options/([^/]+?)`)
      return homeRoutes ? `${homeRoutes[0]}/mortgage-coach` : '/not-found'
    }
  },
  mortgageRelief: {
    overlay: 'mortgage-relief',
    titleMessage: OVERLAY_PANEL_TITLES.mortgageRelief,
    matchPath: `${PATH_HELPERS.home.matchPath}/mortgage-relief`,
    getOrigin: () => {
      const homeRoutes = getCurrentPath().match(PATH_HELPERS.home.regex)
      return homeRoutes ? homeRoutes[0] : '/'
    },
    buildPath: () => {
      const homeRoutes = getCurrentPath().match(PATH_HELPERS.home.regex)
      return homeRoutes ? `${homeRoutes[0]}/mortgage-relief` : '/not-found'
    }
  },
  nonDisclosureWarning: {
    overlay: 'non-disclosure-state',
    titleMessage: OVERLAY_PANEL_TITLES.nonDisclosureWarning,
    matchPath: `${PATH_HELPERS.home.matchPath}/non-disclosure-state`,
    getOrigin: () => {
      const homeRoutes = getCurrentPath().match(PATH_HELPERS.home.regex)
      return homeRoutes ? homeRoutes[0] : '/'
    },
    buildPath: () => {
      const homeRoutes = getCurrentPath().match(PATH_HELPERS.home.regex)
      return homeRoutes ? `${homeRoutes[0]}/non-disclosure-state` : '/not-found'
    }
  },
  preferences: {
    overlay: 'preferences',
    titleMessage: OVERLAY_PANEL_TITLES.preferences,
    matchPath: [`${PATH_HELPERS.home.matchPath}/preferences`, `${PATH_HELPERS.buy.matchPath}/preferences`],
    getOrigin: () => {
      const homeRoutes = getCurrentPath().match(PATH_HELPERS.home.regex)
      const buyRoutes = getCurrentPath().match(PATH_HELPERS.buy.regex)
      const listingsRoutes = getCurrentPath().match(PATH_HELPERS.listings.regex)
      if (homeRoutes) {
        return homeRoutes[0]
      }
      if (buyRoutes) {
        return buyRoutes[0]
      }
      if (listingsRoutes) {
        return listingsRoutes[0]
      }
      return null
    },
    buildPath: () => {
      const homeRoutes = getCurrentPath().match(PATH_HELPERS.home.regex)
      const buyRoutes = getCurrentPath().match(PATH_HELPERS.buy.regex)
      const listingsRoutes = getCurrentPath().match(PATH_HELPERS.listings.regex)
      if (homeRoutes) {
        return `${homeRoutes[0]}/preferences`
      }
      if (buyRoutes) {
        return `${buyRoutes[0]}/preferences`
      }
      if (listingsRoutes) {
        return `${listingsRoutes[0]}/preferences`
      }
      return '/not-found'
    }
  },
  principalPaymentLearnMore: {
    overlay: 'principal-payment',
    titleMessage: OVERLAY_PANEL_TITLES.principalPayment,
    matchPath: [
      `${PATH_HELPERS.home.matchPath}/principal-payment/learn-more`,
      `${PATH_HELPERS.buy.matchPath}/principal-payment/learn-more`
    ],
    getOrigin: () => {
      const homeRoutes = getCurrentPath().match(PATH_HELPERS.home.regex)
      const buyRoutes = getCurrentPath().match(PATH_HELPERS.buy.regex)
      const matchedRoutes = homeRoutes || buyRoutes
      return matchedRoutes ? matchedRoutes[0] : null
    },
    buildPath: () => {
      const homeRoutes = getCurrentPath().match(PATH_HELPERS.home.regex)
      const buyRoutes = getCurrentPath().match(PATH_HELPERS.buy.regex)
      const matchedRoutes = homeRoutes || buyRoutes
      return matchedRoutes ? `${matchedRoutes[0]}/principal-payment/learn-more` : '/not-found'
    }
  },
  purchasing: {
    overlay: 'purchasing',
    titleMessage: OVERLAY_PANEL_TITLES.purchasing,
    matchPath: `${PATH_HELPERS.home.matchPath}/purchasing/:gemKey([^/]+?)`,
    getOrigin: () => {
      const homeRoutes = getCurrentPath().match(PATH_HELPERS.home.regex)
      return homeRoutes ? homeRoutes[0] : null
    },
    buildPath: gemKey => {
      const homeRoutes = getCurrentPath().match(PATH_HELPERS.home.regex)
      return homeRoutes && gemKey ? `${homeRoutes[0]}/purchasing/${gemKey.toLowerCase()}` : '/not-found'
    }
  },
  refinance: {
    overlay: 'refi-options',
    titleMessage: OVERLAY_PANEL_TITLES.refinance,
    matchPath: `${PATH_HELPERS.home.matchPath}/refi-options/:refiKey([^/]+?)`,
    getOrigin: () => {
      const homeRoutes = getCurrentPath().match(PATH_HELPERS.home.regex)
      return homeRoutes ? homeRoutes[0] : null
    },
    buildPath: refiKey => {
      const homeRoutes = getCurrentPath().match(PATH_HELPERS.home.regex)
      return homeRoutes && refiKey ? `${homeRoutes[0]}/refi-options/${refiKey.toLowerCase()}` : '/not-found'
    }
  },
  refiRateRequest: {
    overlay: 'get-rate',
    titleMessage: OVERLAY_PANEL_TITLES.refiRateRequest,
    matchPath: `${PATH_HELPERS.home.matchPath}/refi-options/:refiKey([^/]+?)/get-rate`,
    getOrigin: () => {
      const refiRoutes =
        matchPath(getCurrentPath(), {
          path: `${PATH_HELPERS.home.matchPath}/refi-options/:refiKey([^/]+?)`
        }) || {}
      return refiRoutes.url || null
    },
    buildPath: () => {
      const refiRoutes =
        matchPath(getCurrentPath(), {
          path: `${PATH_HELPERS.home.matchPath}/refi-options/:refiKey([^/]+?)`
        }) || {}
      return refiRoutes.url ? `${refiRoutes.url}/get-rate` : '/not-found'
    }
  },
  shareHomebot: {
    overlay: 'share-homebot',
    titleMessage: OVERLAY_PANEL_TITLES.shareHomebot,
    matchPath: `${PATH_HELPERS.home.matchPath}/share-homebot`,
    getOrigin: () => {
      const homeRoutes = getCurrentPath().match(PATH_HELPERS.home.regex)
      const buyRoutes = getCurrentPath().match(PATH_HELPERS.buy.regex)
      const matchedRoutes = homeRoutes || buyRoutes
      return matchedRoutes ? matchedRoutes[0] : '/'
    },
    buildPath: () => {
      const homeRoutes = getCurrentPath().match(PATH_HELPERS.home.regex)
      const buyRoutes = getCurrentPath().match(PATH_HELPERS.buy.regex)
      const matchedRoutes = homeRoutes || buyRoutes
      return matchedRoutes ? `${matchedRoutes[0]}/share-homebot` : '/not-found'
    }
  },
  shouldYouSell: {
    overlay: 'should-you-sell',
    titleMessage: OVERLAY_PANEL_TITLES.shouldYouSell,
    matchPath: `${PATH_HELPERS.home.matchPath}/should-you-sell`,
    getOrigin: () => {
      const homeRoutes = getCurrentPath().match(PATH_HELPERS.home.regex)
      return homeRoutes ? homeRoutes[0] : '/'
    },
    buildPath: () => {
      const homeRoutes = getCurrentPath().match(PATH_HELPERS.home.regex)
      return homeRoutes ? `${homeRoutes[0]}/should-you-sell` : '/not-found'
    }
  },
  team: {
    overlay: 'team',
    titleMessage: OVERLAY_PANEL_TITLES.team,
    matchPath: [`${PATH_HELPERS.home.matchPath}/team`, `${PATH_HELPERS.buy.matchPath}/team`],
    getOrigin: () => {
      const homeRoutes = getCurrentPath().match(PATH_HELPERS.home.regex)
      const buyRoutes = getCurrentPath().match(PATH_HELPERS.buy.regex)
      const matchedRoutes = homeRoutes || buyRoutes
      return matchedRoutes ? matchedRoutes[0] : null
    },
    buildPath: () => {
      const homeRoutes = getCurrentPath().match(PATH_HELPERS.home.regex)
      const buyRoutes = getCurrentPath().match(PATH_HELPERS.buy.regex)
      const matchedRoutes = homeRoutes || buyRoutes
      return matchedRoutes ? `${matchedRoutes[0]}/team` : '/not-found'
    }
  },
  textUpdates: {
    overlay: 'text-updates',
    titleMessage: OVERLAY_PANEL_TITLES.textUpdates,
    matchPath: [`${PATH_HELPERS.home.matchPath}/text-updates`, `${PATH_HELPERS.buy.matchPath}/text-updates`],
    getOrigin: () => {
      const homeRoutes = getCurrentPath().match(PATH_HELPERS.home.regex)
      const buyRoutes = getCurrentPath().match(PATH_HELPERS.buy.regex)
      const matchedRoutes = homeRoutes || buyRoutes
      return matchedRoutes ? matchedRoutes[0] : null
    },
    buildPath: key => {
      const homeRoutes = getCurrentPath().match(PATH_HELPERS.home.regex)
      const buyRoutes = getCurrentPath().match(PATH_HELPERS.buy.regex)
      const matchedRoutes = homeRoutes || buyRoutes
      return matchedRoutes ? `${matchedRoutes[0]}/text-updates${key ? `/${key}` : ''}` : '/not-found'
    }
  },
  tuneYourValue: {
    overlay: 'tune-your-value',
    titleMessage: OVERLAY_PANEL_TITLES.tuneYourValue,
    matchPath: `${PATH_HELPERS.home.matchPath}/tune-your-value`,
    getOrigin: () => {
      const homeRoutes = getCurrentPath().match(PATH_HELPERS.home.regex)
      return homeRoutes ? homeRoutes[0] : '/'
    },
    buildPath: () => {
      const homeRoutes = getCurrentPath().match(PATH_HELPERS.home.regex)
      return homeRoutes ? `${homeRoutes[0]}/tune-your-value` : '/not-found'
    }
  },
  unpaidBalance: {
    overlay: 'unpaid-balance',
    titleMessage: OVERLAY_PANEL_TITLES.unpaidBalance,
    matchPath: `${PATH_HELPERS.home.matchPath}/unpaid-balance`,
    getOrigin: () => {
      const homeRoutes = getCurrentPath().match(PATH_HELPERS.home.regex)
      return homeRoutes ? homeRoutes[0] : '/'
    },
    buildPath: () => {
      const homeRoutes = getCurrentPath().match(PATH_HELPERS.home.regex)
      return homeRoutes ? `${homeRoutes[0]}/unpaid-balance` : '/not-found'
    }
  },
  reverseMortgage: {
    overlay: 'reverse-mortgage',
    titleMessage: OVERLAY_PANEL_TITLES.reverseMortgage,
    matchPath: `${PATH_HELPERS.home.matchPath}/reverse-mortgage`,
    getOrigin: () => {
      const homeRoutes = getCurrentPath().match(PATH_HELPERS.home.regex)
      return homeRoutes ? homeRoutes[0] : '/'
    },
    buildPath: () => {
      const homeRoutes = getCurrentPath().match(PATH_HELPERS.home.regex)
      return homeRoutes ? `${homeRoutes[0]}/reverse-mortgage` : '/not-found'
    }
  },
  getTitle: (key, intl) => {
    const title = OVERLAY_PANEL_TITLES[key]
    if (!title) {
      return
    }

    if (typeof intl === 'function') {
      return intl(title)
    }
    if (intl && intl.formatMessage) {
      return intl.formatMessage(title)
    }
    return title.defaultMessage
  },
  pmiLearnMore: {
    overlay: 'pmiLearnMore',
    titleMessage: OVERLAY_PANEL_TITLES.pmiLearnMore,
    matchPath: `${PATH_HELPERS.home.matchPath}/pmi-learn-more`,
    getOrigin: () => {
      const homeRoutes = getCurrentPath().match(PATH_HELPERS.home.regex)
      return homeRoutes ? homeRoutes[0] : '/'
    },
    buildPath: () => {
      const homeRoutes = getCurrentPath().match(PATH_HELPERS.home.regex)
      return homeRoutes ? `${homeRoutes[0]}/pmi-learn-more` : '/not-found'
    }
  },
  homeValueEstimate: {
    overlay: 'homeValueEstimate',
    titleMessage: OVERLAY_PANEL_TITLES.homeValueEstimate,
    matchPath: `${PATH_HELPERS.home.matchPath}/home-value-estimate`,
    getOrigin: () => {
      const homeRoutes = getCurrentPath().match(PATH_HELPERS.home.regex)
      return homeRoutes ? homeRoutes[0] : '/'
    },
    buildPath: () => {
      const homeRoutes = getCurrentPath().match(PATH_HELPERS.home.regex)
      return homeRoutes ? `${homeRoutes[0]}/home-value-estimate` : '/not-found'
    }
  },
  /**
   * The overlay that is used for the Home Price Index (HPI) flow
   * when a clients AVM is not available.
   */
  homePriceIndexWidget: {
    overlay: 'home-price-index-widget',
    titleMessage: OVERLAY_PANEL_TITLES.homePriceIndexWidget,
    matchPath: `${PATH_HELPERS.home.matchPath}/home-price-index-widget`,
    getOrigin: () => {
      const homeRoutes = getCurrentPath().match(PATH_HELPERS.home.regex)
      return homeRoutes ? homeRoutes[0] : '/'
    },
    buildPath: () => {
      const homeRoutes = getCurrentPath().match(PATH_HELPERS.home.regex)
      return homeRoutes ? `${homeRoutes[0]}/home-price-index-widget` : '/not-found'
    }
  },
  renovations: {
    overlay: 'renovations',
    titleMessage: OVERLAY_PANEL_TITLES.equityUnlock,
    matchPath: `${PATH_HELPERS.home.matchPath}/cashout/renovations`,
    getOrigin: () => {
      const homeRoutes = getCurrentPath().match(PATH_HELPERS.home.regex)
      return homeRoutes ? homeRoutes[0] : '/'
    },
    buildPath: () => {
      const homeRoutes = getCurrentPath().match(PATH_HELPERS.home.regex)
      return homeRoutes ? `${homeRoutes[0]}/cashout/renovations` : '/not-found'
    }
  }
}

export const getClientIdFromPath = () => {
  const pathname = getCurrentPath()
  const clientMatch = matchPath(pathname, {
    path: '/reports/:clientId([-0-9A-Fa-f]{36})'
  })

  return clientMatch ? clientMatch.params.clientId : null
}

export const getHomeIdFromPath = () => {
  const pathname = getCurrentPath()
  const homeMatch = matchPath(pathname, {
    path: '/reports/:clientId?/home/:homeId([0-9A-Fa-f]{24})'
  })
  return homeMatch ? homeMatch.params.homeId : null
}

export const EMAIL_PROVIDERS = {
  GMAIL: {
    title: 'Gmail',
    url: 'https://mail.google.com/mail/u/0/'
  },
  OUTLOOK: {
    title: 'Outlook',
    url: 'https://outlook.live.com/mail/0/inbox'
  },
  YAHOO: {
    title: 'Yahoo',
    url: 'https://mail.yahoo.com'
  }
}
