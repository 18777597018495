import { useIntl, defineMessages } from 'react-intl'
import { useTrackingContext } from '../../../providers/tracking'
import { FormControl, FormHelperText, Switch } from '@homebotapp/hb-react-component-catalog'
import { PREQUAL_TOPIC_KEYS_UI_CONTEXT, TopicKey } from '../../../constants/customerMessages'

export const MSG = defineMessages({
  getPrequalified: {
    id: 'PrequalCheckbox.getPrequalified',
    defaultMessage: 'Get pre-qualified'
  },
  noCreditPull: {
    id: 'PrequalCheckbox.noCreditPull',
    defaultMessage: 'Check affordability with no obligation moving forward'
  }
})

export interface PrequalSwitchProps {
  checked: boolean
  prequalTopicKey: TopicKey
  setChecked(checked: boolean): void
}

export const PrequalSwitch = ({ checked, prequalTopicKey, setChecked }: PrequalSwitchProps) => {
  const intl = useIntl()

  return (
    <FormControl ml={2} display='flex' justifyContent='flex-start' flexDirection='column' alignItems='flex-start'>
      <Switch
        mb={0}
        mt={1}
        size='sm'
        name='prequal'
        isChecked={checked}
        onChange={() => setChecked(!checked)}
        tracking={{
          guid: 'iyxRnCeXymFWvbfBSnjsU6',
          ui_context: `Prequal Checkbox - ${PREQUAL_TOPIC_KEYS_UI_CONTEXT[prequalTopicKey]}`,
          descriptor: 'Get pre-qualified'
        }}
      >
        {intl.formatMessage(MSG.getPrequalified)}
      </Switch>
      <FormHelperText ml={9} mt={0} textAlign='left' fontSize='small'>
        {intl.formatMessage(MSG.noCreditPull)}
      </FormHelperText>
    </FormControl>
  )
}
