/* eslint-disable prettier/prettier */
// PLACE ALL HOMEOWNER DIGEST RELATED TRACKING EVENTS HERE

export const HOMEOWNER_DIGEST_ADD_LOAN_MODAL = 'homeowner_digest_add_loan_modal'
export const HOMEOWNER_DIGEST_AIRDNA_MODULE = 'homeowner_digest_airdna_module'
export const HOMEOWNER_DIGEST_AUTH_ERROR_MODAL = 'homeowner_digest_auth_error_modal'
export const HOMEOWNER_DIGEST_AVM_MODULE = 'homeowner_digest_avm_module'
export const HOMEOWNER_DIGEST_AVM_MODULE_CONFIRM_LOAN_SECTION = 'homeowner_digest_avm_module_confirm_loan_section'
export const HOMEOWNER_DIGEST_AVM_PANEL = 'homeowner_digest_avm_panel'
export const HOMEOWNER_DIGEST_BOMB_BOMB_WINDOW_ERROR = 'homeowner_digest_bomb_bomb_window_error'
export const HOMEOWNER_DIGEST_EDIT_LOAN_MODAL = 'homeowner_digest_edit_loan_modal'
export const HOMEOWNER_DIGEST_EXTRA_PAYMENTS_MODULE = 'homeowner_digest_extra_payments_module'
export const HOMEOWNER_DIGEST_FOOTER = 'homeowner_digest_footer'
export const HOMEOWNER_DIGEST_GALLERY_MODULE = 'homeowner_digest_gallery_module'
export const HOMEOWNER_DIGEST_INTEREST_MODULE = 'homeowner_digest_interest_module'
export const HOMEOWNER_DIGEST_LOANS_MODAL = 'homeowner_digest_loans_modal'
export const HOMEOWNER_DIGEST_LOANS_MODAL_DELETE_ALERT = 'homeowner_digest_loans_modal_delete_alert'
export const HOMEOWNER_DIGEST_NATIVE_APP_AD_MODULE = 'homeowner_digest_native_app_ad_module'
export const HOMEOWNER_DIGEST_NO_LOANS_MODULE = 'homeowner_digest_no_loans_module'
export const HOMEOWNER_DIGEST_CUSTOM_MODULE = 'homeowner_digest_custom_module'
export const HOMEOWNER_DIGEST_PMI_MODULE = 'homeowner_digest_pmi_module'
export const HOMEOWNER_DIGEST_PMI_PANEL = 'homeowner_digest_pmi_panel'
export const HOMEOWNER_DIGEST_PREVIEW_AVM_MODULE = 'homeowner_digest_preview_avm_module'
export const HOMEOWNER_DIGEST_PRINCIPLE_AND_INTEREST_MODULE = 'homeowner_digest_principle_and_interest_module'
export const HOMEOWNER_DIGEST_PRINCIPLE_AND_INTEREST_REFI_OPTION_CARD = 'homeowner_digest_principle_and_interest_refi_option_card'
export const HOMEOWNER_DIGEST_PURCHASING_POWER_INVESTMENT_PROPERTY_PANEL = 'homeowner_digest_purchasing_power_investment_property_panel'
export const HOMEOWNER_DIGEST_PURCHASING_POWER_NEW_HOME_PANEL = 'homeowner_digest_purchasing_power_new_home_panel'
export const HOMEOWNER_DIGEST_PURCHASING_POWER_RENT_AND_BUY_ANOTHER_PANEL = 'homeowner_digest_purchasing_power_rent_and_buy_another_panel'
export const HOMEOWNER_DIGEST_PURCHASING_POWER_SELL_AND_POCKET_PANEL = 'homeowner_digest_purchasing_power_sell_and_pocket_panel'
export const HOMEOWNER_DIGEST_REFI_MODULE = 'homeowner_digest_refi_module'
export const HOMEOWNER_DIGEST_REVERSE_MORTGAGE_MODULE = 'homeowner_digest_reverse_mortgage_module'
export const HOMEOWNER_DIGEST_UPDATE_LOAN_WARNING_SECTION = 'homeowner_digest_update_loan_warning_section'
export const HOMEOWNER_DIGEST_UPDATE_LOAN_BALANCE_MODULE = 'homeowner_digest_update_loan_balance_module'
export const HOMEOWNER_DIGEST_INTRO_MODULE = 'homeowner_digest_intro_module'
export const HOMEOWNER_DIGEST_MOBILE_SHORTCUTS_DRAWER = 'homeowner_digest_mobile_shortcuts_drawer'
export const HOMEOWNER_DIGEST_MOBILE_SHORTCUTS_DRAWER_SELECT_COMPONENT = 'homeowner_digest_mobile_shortcuts_drawer_select_component'
export const HOMEOWNER_DIGEST_SIDE_NAViGATION_PANEL = 'homeowner_digest_side_navigation_panel'
export const HOMEOWNER_DIGEST_ADDRESS_SELECT_COMPONENT = 'homeowner_digest_address_select_component'
export const HOMEOWNER_DIGEST_YOUR_MARKET_PAGE = 'homeowner_digest_your_market_page'
export const HOMEOWNER_DIGEST_EQUITY_UNLOCK_MODULE = 'homeowner_digest_equity_unlock_module'

// HPI
export const HOMEOWNER_DIGEST_HOME_PRICE_INDEX_BLOCKING_MODAL = 'homeowner_digest_home_price_index_blocking_modal'
export const HOMEOWNER_DIGEST_HOME_PRICE_INDEX_BLOCKING_MODAL_BUTTON = 'homeowner_digest_home_price_index_blocking_modal_button'
export const HOMEOWNER_DIGEST_HOME_PRICE_INDEX_WIZARD_APPRAISAL_DETAILS_STEP = 'homeowner_digest_home_price_index_wizard_appraisal_details_step'
export const HOMEOWNER_DIGEST_HOME_PRICE_INDEX_WIZARD_BEST_GUESS_STEP = 'homeowner_digest_home_price_index_wizard_best_guess_step'
export const HOMEOWNER_DIGEST_HOME_PRICE_INDEX_WIZARD_COMPLETE_STEP = 'homeowner_digest_home_price_index_wizard_complete_step'
export const HOMEOWNER_DIGEST_HOME_PRICE_INDEX_WIZARD_CURRENT_APPRAISAL_CHECK_STEP = 'homeowner_digest_home_price_index_wizard_current_appraisal_check_step'
export const HOMEOWNER_DIGEST_HOME_PRICE_INDEX_WIZARD_PROPERTY_TYPE_STEP = 'homeowner_digest_home_price_index_wizard_property_type_step'
export const HOMEOWNER_DIGEST_HOME_PRICE_INDEX_WIZARD_PURCHASE_DETAILS_STEP = 'homeowner_digest_home_price_index_wizard_purchase_details_step'
