import {
  FETCH_CUSTOMER_PROFILE_SETTINGS_REQUEST,
  FETCH_CUSTOMER_PROFILE_SETTINGS_SUCCESS,
  FETCH_CUSTOMER_PROFILE_SETTINGS_FAILURE
} from '../actions/actionTypes'

const initialState = {
  data: null,
  loading: false,
  error: null
}

export default function customerProfileSettings(state = initialState, { type, data, error }) {
  switch (type) {
    case FETCH_CUSTOMER_PROFILE_SETTINGS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      }
    case FETCH_CUSTOMER_PROFILE_SETTINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        data
      }
    case FETCH_CUSTOMER_PROFILE_SETTINGS_FAILURE:
      return {
        ...state,
        loading: false,
        error: error
      }
    default:
      return state
  }
}
