import {
  Box,
  Button,
  Flex,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text
} from '@homebotapp/hb-react-component-catalog'
import useGoogle from 'react-google-autocomplete/lib/usePlacesAutocompleteService'
import { useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { Divider } from '../../shared/Navbar'
import { useRecoilState } from 'recoil'
import { recentlySelectedLocations } from './state/recentlySelectedLocations'
import { useTrackingContext } from '../../../providers/tracking'
import { CurrentSelectedLocation } from './state/currentSelectedLocation'
import { SearchResultsEmptyIcon } from '../Search/SearchModal/SearchModalResults/SearchResultsEmpty/SearchResultsEmptyIcon'

export interface GoogleAutoCompleteProps {
  showFullModal?: boolean
  setPlaceDetails?: (details: any) => void
  onPlaceSelected: (place: any) => void
  onClose?: () => void
  types?: string[] // for available types, see: https://developers.google.com/maps/documentation/javascript/place-autocomplete#constrain-place-types
}

export const MSG = defineMessages({
  findAPlace: {
    id: 'General.findAPlace',
    defaultMessage: 'Find a place...'
  },
  cancel: {
    id: 'General.cancel',
    defaultMessage: 'Cancel'
  },
  recent: {
    id: 'General.recent',
    defaultMessage: 'Recent'
  },
  places: {
    id: 'General.places',
    defaultMessage: 'Places'
  },
  loading: {
    id: 'General.loading',
    defaultMessage: 'Loading...'
  },
  poweredByGoogle: {
    id: 'General.poweredByGoogle',
    defaultMessage: 'Powered by Google'
  },
  locationNotFound: {
    id: 'General.locationNotFound',
    defaultMessage: 'Location not found. Try another search.'
  },
  searchForAnArea: {
    id: 'General.searchForAnArea',
    defaultMessage: 'Search for a new area'
  }
})

const AddressOption = ({ address, onClick }) => {
  return (
    <>
      <Button
        m={0}
        px={1}
        py={0}
        mt='1px'
        w='100%'
        size='sm'
        variant='ghost'
        borderRadius={0}
        onClick={onClick}
        justifyContent='flex-start'
      >
        <Text noOfLines={1} m={0} size='sm' fontWeight={500}>
          {address}
        </Text>
      </Button>
      <Divider style={{ height: 2, margin: '0' }} />
    </>
  )
}

export const GoogleAutoComplete = ({
  showFullModal = true,
  onPlaceSelected,
  setPlaceDetails,
  onClose,
  types = ['locality', 'administrative_area_level_3', 'postal_code']
}: GoogleAutoCompleteProps) => {
  const intl = useIntl()
  const [searchTerm, setSearchTerm] = useState('')
  const { trackingClient } = useTrackingContext()

  const { placePredictions, getPlacePredictions, isPlacePredictionsLoading, placesService } = useGoogle({
    apiKey: process.env.REACT_APP_GOOGLE_KEY,
    debounce: 500
  })

  const [recentLocations, setRecentLocations] =
    useRecoilState<google.maps.places.AutocompletePrediction[]>(recentlySelectedLocations)

  const onClick = (place: CurrentSelectedLocation) => {
    trackingClient?.autocompleteInteraction({
      ui_context: 'Google_Auto_Complete',
      descriptor: place.description,
      action: 'Selected',
      guid: '3GtcZMbe9X4C7YUKKiRUEE'
    })

    // check if the place is already in the recent locations
    if (!recentLocations || recentLocations?.findIndex(r => r.description === place.description) === -1) {
      setRecentLocations([...(recentLocations || []), place].splice(-3))
    }

    onPlaceSelected(place)

    if (setPlaceDetails) {
      placesService?.getDetails(
        {
          placeId: place.place_id
        },
        placeDetails => setPlaceDetails(placeDetails)
      )
    }
  }

  if (showFullModal) {
    return (
      <Modal closeOnOverlayClick isOpen onClose={() => onClose?.()}>
        <ModalOverlay />
        <ModalContent mx={4} borderRadius={16}>
          <Flex px={5} py={5} alignItems='center' flexDirection='row'>
            <Flex flex={1}>
              <Input
                size='sm'
                tracking={{
                  guid: '3GtcZMbe9X4C7YUKKiAyjg',
                  descriptor: 'Search for a place'
                }}
                background='neutral.200'
                flex={1}
                mr={5}
                name={MSG.findAPlace.id}
                value={searchTerm}
                onChange={e => {
                  getPlacePredictions({
                    input: e.target.value,
                    componentRestrictions: {
                      country: 'us'
                    },
                    types
                  })
                  setSearchTerm(e.target.value)
                }}
                placeholder={intl.formatMessage(MSG.findAPlace)}
              />
            </Flex>

            <Button m={0} onClick={onClose} size='sm'>
              {intl.formatMessage(MSG.cancel)}
            </Button>
          </Flex>

          <ModalBody>
            {recentLocations && (
              <Box>
                <Flex mb={1} alignItems='center'>
                  <Icon mr={1} name='clock' color='neutral.500' />
                  <Text m={0} size='sm' fontWeight={600}>
                    {intl.formatMessage(MSG.recent)}
                  </Text>
                </Flex>
                {recentLocations?.map((recent, index) => (
                  <AddressOption
                    key={index}
                    address={recent.description}
                    onClick={() => {
                      onClick(recent as CurrentSelectedLocation)
                    }}
                  />
                ))}
              </Box>
            )}
            <Box mt={5}>
              <Flex mb={1} alignItems='center'>
                <Icon mr={1} name='map-marker' color='neutral.500' />
                <Text as='span' size='sm' fontWeight={600}>
                  {intl.formatMessage(MSG.places)}
                  {isPlacePredictionsLoading && (
                    <Text ml={2} as='span' size='3xs' fontStyle='italic'>
                      ({intl.formatMessage(MSG.loading)})
                    </Text>
                  )}
                </Text>
              </Flex>
              {placePredictions?.length > 0 &&
                placePredictions.map((prediction, index) => {
                  return (
                    <AddressOption
                      key={index}
                      address={prediction.description}
                      onClick={() => {
                        onClick(prediction as CurrentSelectedLocation)
                      }}
                    />
                  )
                })}
            </Box>
            {!placePredictions?.length && !isPlacePredictionsLoading && (
              <Flex mt={4} gap={4} direction='column' justify='center' align='center'>
                <SearchResultsEmptyIcon />
                <Text size='xs' fontWeight='semibold'>
                  {intl.formatMessage(!!searchTerm?.length ? MSG.locationNotFound : MSG.searchForAnArea)}
                </Text>
              </Flex>
            )}
          </ModalBody>
          <ModalFooter>
            <Flex justifyContent='flex-end'>
              <Text size='3xs' m={0}>
                {intl.formatMessage(MSG.poweredByGoogle)}
              </Text>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    )
  } else {
    return (
      <>
        <Flex px={5} py={5} alignItems='center' flexDirection='row'>
          <Flex flex={1}>
            <Input
              size='sm'
              tracking={{
                guid: '3GtcZMbe9X4C7YUKKiAyjg',
                descriptor: 'Search for a place'
              }}
              background='neutral.200'
              flex={1}
              mr={5}
              name={MSG.findAPlace.id}
              value={searchTerm}
              onChange={e => {
                getPlacePredictions({
                  input: e.target.value,
                  componentRestrictions: {
                    country: 'us'
                  },
                  types
                })
                setSearchTerm(e.target.value)
              }}
              placeholder={intl.formatMessage(MSG.findAPlace)}
            />
          </Flex>
        </Flex>
        {placePredictions?.length > 0 && (
          <>
            <Text my={2} size='sm' fontWeight={600}>
              {intl.formatMessage(MSG.places)}
            </Text>
            {isPlacePredictionsLoading && <Text>{intl.formatMessage(MSG.loading)}</Text>}
            {placePredictions.map((prediction, index) => (
              <AddressOption
                key={index}
                address={prediction.description}
                onClick={() => {
                  onClick(prediction as CurrentSelectedLocation)
                }}
              />
            ))}
          </>
        )}
      </>
    )
  }
}
