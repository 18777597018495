import * as Sentry from '@sentry/browser'
import get from 'lodash/get'
import moment from 'moment'
import { getSafeSessionStorage } from '../helpers/browser'
import { flashNotification } from './.'
import { fetchValuationHistory } from './valuationHistory'
import { fetchHomeInsurance, setHomeInsuranceOverride } from './homeInsurance'
import { ERRORS, NO_ACCESS_ERROR, HOME_INSURANCE_CTA } from '../constants/messages'

import {
  createLoan,
  deleteLoan,
  submitLoanBalance,
  updateLoan,
  restoreLoan,
  updateHomeRequest,
  getHomeDetail,
  addHomeAnswerRequest,
  trackClientHomeEvent,
  recompileClient
} from '../api/mikasa/requests'
import { getAuthEmail } from '../auth'
import { selectHomeId, selectHomeUuid, getHomeUserFromEmail } from '../store/selectors/home'
import { selectClientFetchId } from '../store/selectors/client'
import { selectLatestValuationForCurrentHome } from '../store/selectors/valuationHistory'

import {
  SET_HOME_FETCH_ID,
  SET_HOME_FROM_CACHE,
  SET_CLIENT_FETCH_ID,
  FETCH_HOME_REQUEST,
  FETCH_HOME_SUCCESS,
  FETCH_HOME_FAILURE,
  UPDATE_HOME_REQUEST,
  UPDATE_HOME_SUCCESS,
  UPDATE_HOME_ERROR,
  ADD_HOME_ANSWER_REQUEST,
  ADD_HOME_ANSWER_SUCCESS,
  ADD_HOME_ANSWER_ERROR,
  CREATE_HOME_LOAN_REQUEST,
  CREATE_HOME_LOAN_SUCCESS,
  CREATE_HOME_LOAN_FAILURE,
  DELETE_LOAN_REQUEST,
  DELETE_LOAN_SUCCESS,
  DELETE_LOAN_FAILURE,
  UPDATE_LOAN_REQUEST,
  UPDATE_LOAN_SUCCESS,
  UPDATE_LOAN_FAILURE,
  UPDATE_LOAN_BALANCES_REQUEST,
  UPDATE_LOAN_BALANCES_SUCCESS,
  UPDATE_LOAN_BALANCES_FAILURE,
  UPDATE_LOAN_BALANCES_SAVED_RESET,
  UNSET_HOME_INSURANCE
} from './actionTypes'
import { PENDING } from '../types/loadingStatus'
import { fetchShowPmiModules } from '../store/slices/pmi'
import { defineMessages } from 'react-intl'

const MSG = defineMessages({
  updateSuccess: {
    id: 'home.updateSuccess',
    defaultMessage: 'Loan updated!'
  },
  deleteSuccess: {
    id: 'home.deleteSuccess',
    defaultMessage: 'Loan deleted!'
  },
  createSuccess: {
    id: 'home.createSuccess',
    defaultMessage: 'Loan added!'
  }
})

export const fetchHomeDetail = homeId => async (dispatch, getState) => {
  const state = getState()
  const { cache } = state.home
  const homeUuid = selectHomeUuid(state)
  const isCached = Boolean(cache[homeId])
  const clientFetchId = selectClientFetchId(state)
  const fetchShowPmiModulesStatus = state.pmi.fetchShowPmiModulesStatus

  dispatch({
    type: FETCH_HOME_REQUEST,
    id: homeId,
    loading: !isCached
  })

  if (clientFetchId !== null) {
    dispatch(fetchValuationHistory(clientFetchId, homeId))

    if (homeUuid && fetchShowPmiModulesStatus !== PENDING) {
      dispatch(fetchShowPmiModules({ clientId: clientFetchId, homeUuid: homeUuid }))
    }
  }

  try {
    const response = await getHomeDetail({ homeId })

    dispatch({
      type: FETCH_HOME_SUCCESS,
      data: response.data
    })

    // Home data to set clientId for old home routes
    if (clientFetchId === null) {
      dispatch({
        type: SET_CLIENT_FETCH_ID,
        id: getHomeUserFromEmail(response.data, getAuthEmail()).uuid
      })
    }
  } catch (error) {
    dispatch({
      type: FETCH_HOME_FAILURE,
      error
    })
  }
}

export const setCurrentHomeDetail = homeId => async (dispatch, getState) => {
  const { cache } = getState().home
  const isCached = Boolean(cache[homeId])

  dispatch({
    type: homeId && isCached ? SET_HOME_FROM_CACHE : SET_HOME_FETCH_ID,
    id: homeId,
    keepCache: true
  })

  dispatch({
    type: UNSET_HOME_INSURANCE
  })
}

export const updateHomeInsurancePolicy =
  ({ doNavBack, homeUuid, attrs } = {}) =>
  async dispatch => {
    const onSuccess = () => {
      dispatch(fetchHomeInsurance(homeUuid))
      dispatch(
        flashNotification({
          message: HOME_INSURANCE_CTA.confirmDetailsSuccess,
          messageType: 'success'
        })
      )
      dispatch(setHomeInsuranceOverride({ override: true }))
      doNavBack()
    }
    const onFailure = () =>
      flashNotification({
        message: ERRORS.defaultHeadline,
        messageType: 'error'
      })

    await dispatch(
      updateHome(attrs, {
        homeUuid,
        onSuccess,
        onFailure
      })
    )
  }

export const updateHome =
  (data, { homeUuid, onSuccess, onFailure } = {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: UPDATE_HOME_REQUEST
    })

    const id = homeUuid || getState().home.data.uuid

    try {
      const response = await updateHomeRequest(id, data)

      dispatch({
        type: UPDATE_HOME_SUCCESS,
        data: response.data
      })

      if (typeof onSuccess === 'function') onSuccess()
    } catch (error) {
      if (typeof failure === 'function') onFailure()

      dispatch({
        type: UPDATE_HOME_ERROR,
        error
      })
    }
  }

export const handleAcknowledgeClientHasSeenAvmDiff = () => async (dispatch, getState) => {
  const state = getState()
  const latestValuationForCurrentHome = selectLatestValuationForCurrentHome(state)

  dispatch(
    addHomeAnswer({
      question: 'dateOfLastClientAcknowledgedValuation',
      answer: latestValuationForCurrentHome.date
    })
  )
}

export const addSnoozeAnswer = () => async dispatch => {
  const onSuccess = () =>
    dispatch(
      flashNotification({
        message: HOME_INSURANCE_CTA.snoozeSuccess,
        messageType: 'success'
      })
    )

  const onFailure = () =>
    dispatch(
      flashNotification({
        message: [ERRORS.defaultHeadline, ERRORS.defaultMessage],
        messageType: 'error'
      })
    )

  await dispatch(
    addHomeAnswer(
      {
        question: 'snoozeHomeInsuranceReminder',
        answer: 'true'
      },
      true,
      { onSuccess, onFailure }
    )
  )
}

export const addHomeAnswer =
  (answerData, withHomeUpdate = true, { onSuccess, onFailure } = {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: ADD_HOME_ANSWER_REQUEST
    })

    const { id, uuid } = getState().home.data

    try {
      const response = await addHomeAnswerRequest({
        homeUuid: uuid,
        answerData
      })

      dispatch({
        type: ADD_HOME_ANSWER_SUCCESS,
        data: response.data
      })

      if (typeof onSuccess === 'function') onSuccess()

      if (withHomeUpdate) {
        await dispatch(fetchHomeDetail(id))
      }
    } catch (error) {
      if (typeof onFailure === 'function') onFailure()

      dispatch({
        type: ADD_HOME_ANSWER_ERROR,
        error
      })
    }
  }

export const createHomeLoan = (clientId, homeUuid, data) => async (dispatch, getState) => {
  dispatch({
    type: CREATE_HOME_LOAN_REQUEST
  })

  try {
    const response = await createLoan(homeUuid, data)
    const state = getState()
    await dispatch(fetchHomeDetail(selectHomeId(state)))
    await recompileClient(clientId)
    await dispatch(
      flashNotification({
        message: MSG.createSuccess,
        messageType: 'error'
      })
    )
    dispatch({
      type: CREATE_HOME_LOAN_SUCCESS,
      data: response.data
    })
  } catch (error) {
    dispatch({
      type: CREATE_HOME_LOAN_FAILURE,
      error
    })
  }
}

export const updateLoanBalances = loanAmountMap => async (dispatch, getState) => {
  dispatch({
    type: UPDATE_LOAN_BALANCES_REQUEST
  })
  try {
    const now = moment().toJSON()
    const promises = Object.keys(loanAmountMap).map(key => submitLoanBalance(key, loanAmountMap[key], now))

    await Promise.all(promises).then(responses => {
      dispatch({
        type: UPDATE_LOAN_BALANCES_SUCCESS,
        data: responses
      })
    })

    const state = getState()
    dispatch(fetchHomeDetail(selectHomeId(state)))
  } catch (error) {
    dispatch({
      type: UPDATE_LOAN_BALANCES_FAILURE,
      error
    })
    if (error.message.includes('403')) {
      dispatch(
        flashNotification({
          message: NO_ACCESS_ERROR.defaultMessage,
          messageType: 'error'
        })
      )
    } else {
      dispatch(
        flashNotification({
          message: [ERRORS.defaultHeadline, ERRORS.defaultMessage],
          messageType: 'error'
        })
      )
    }
  }
}

export const resetLoanBalancedSaved = () => dispatch => {
  dispatch({ type: UPDATE_LOAN_BALANCES_SAVED_RESET })
}

export const deleteHomeLoan = (clientId, loan) => async dispatch => {
  dispatch({
    type: DELETE_LOAN_REQUEST
  })

  try {
    const response = await deleteLoan(loan.loanId)
    await dispatch(fetchHomeDetail(loan.homeId))
    await recompileClient(clientId)
    await dispatch(
      flashNotification({
        message: MSG.deleteSuccess,
        messageType: 'error'
      })
    )
    dispatch({
      type: DELETE_LOAN_SUCCESS,
      data: response.data
    })
  } catch (error) {
    dispatch({
      type: DELETE_LOAN_FAILURE,
      error
    })
  }
}

export const restoreHomeLoan = (clientId, loanUuid, homeId) => async dispatch => {
  dispatch({
    type: UPDATE_LOAN_REQUEST
  })

  try {
    const response = await restoreLoan(loanUuid)
    await dispatch(fetchHomeDetail(homeId))
    await recompileClient(clientId)
    await dispatch(
      flashNotification({
        message: MSG.updateSuccess,
        messageType: 'error'
      })
    )
    dispatch({
      type: UPDATE_LOAN_SUCCESS,
      data: response.data
    })
  } catch (error) {
    dispatch({
      type: UPDATE_LOAN_FAILURE,
      error
    })
  }
}

export const updateHomeLoan = (clientId, data) => async dispatch => {
  const { loanUuid, homeId, ...loanProperties } = data

  dispatch({
    type: UPDATE_LOAN_REQUEST
  })

  try {
    const response = await updateLoan(loanUuid, loanProperties)
    await dispatch(fetchHomeDetail(homeId))
    await recompileClient(clientId)
    await dispatch(
      flashNotification({
        message: MSG.updateSuccess,
        messageType: 'error'
      })
    )
    dispatch({
      type: UPDATE_LOAN_SUCCESS,
      data: response.data
    })
  } catch (error) {
    dispatch({
      type: UPDATE_LOAN_FAILURE,
      error
    })
  }
}

const sessionStorage = getSafeSessionStorage()

export const setHomeTrackingEventSubmitted = key => sessionStorage.setItem(key, true)

export const getHomeTrackingEventSubmitted = key => sessionStorage.getItem(key)
