import { MouseEvent } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { Button, Text } from '@homebotapp/hb-react-component-catalog'

export const MSG = defineMessages({
  send: {
    id: 'DirectMessageButton.send',
    defaultMessage: 'Send'
  }
})

export interface DirectMessageButtonProps {
  loading: boolean
  disabled: boolean
  onClick: (e: MouseEvent<HTMLButtonElement>) => void
}

export const DirectMessageButton = ({ onClick, loading, disabled }: DirectMessageButtonProps) => {
  const intl = useIntl()

  return (
    <Button
      onClick={onClick}
      colorScheme='brand'
      disabled={disabled}
      isLoading={loading}
      data-qa='directMessageButton'
      aria-label='send direct message'
      tracking={{ guid: 'kBX55EEcjw6ZZWpFsGZhQ4', ui_context: 'Direct Message', descriptor: 'Send' }}
    >
      <Text as='span'>{intl.formatMessage(MSG.send)}</Text>
    </Button>
  )
}
