import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { useIntl, defineMessage } from 'react-intl'
import { selectCorporateProfile } from '../../../../store/selectors/customerProfile'
import { Avatar } from '@homebotapp/hb-react-component-catalog'
import Questions from '../../Questions/QuestionsContainer'

import styles from './OverlayFooters.module.scss'

const messageShape = PropTypes.shape({
  id: PropTypes.string,
  defaultMessage: PropTypes.string
})

const propTypes = {
  topic: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  topicKey: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  questions: PropTypes.arrayOf(messageShape),
  placeholder: PropTypes.oneOfType([PropTypes.bool, messageShape]),
  disableCospo: PropTypes.bool,
  renderHeader: PropTypes.func,
  id: PropTypes.string
}

const defaultPlaceholder = defineMessage({
  id: 'Questions.placeholder',
  defaultMessage: 'Ask a question…'
})

const OverlayQuestionsFooter = ({ topic, topicKey, questions, placeholder, disableCospo, renderHeader, id }) => {
  const intl = useIntl()
  const corporateProfile = useSelector(selectCorporateProfile) || {}

  let formattedQuestions = []
  if (questions && questions.length) {
    const result = questions.reduce((list, question) => {
      list.push({
        question: intl.formatMessage(question)
      })
      return list
    }, [])
    formattedQuestions = result
  }
  if (placeholder || !questions) {
    formattedQuestions.push({
      placeholder: intl.formatMessage(typeof placeholder === 'object' ? placeholder : defaultPlaceholder)
    })
  }

  return (
    <div className={styles.footer} id={id}>
      <div className={styles.topDecoration}>
        {corporateProfile.avatarUri && (
          <Avatar size='lg' transform='translateY(-25%)' src={corporateProfile.avatarUri} />
        )}
      </div>
      <Questions
        location='Overlay'
        questionTopic={topic}
        questionTopicKey={topicKey}
        questions={formattedQuestions}
        disableCospo={disableCospo}
        renderHeader={renderHeader}
      />
    </div>
  )
}

OverlayQuestionsFooter.defaultProps = {
  topic: 'HomeownerHelp'
}
OverlayQuestionsFooter.propTypes = propTypes

export default OverlayQuestionsFooter
