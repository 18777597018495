import { differenceInDays, format } from 'date-fns'
import { Flex, Text } from '@homebotapp/hb-react-component-catalog'
import { Listing, ListingCompact } from '../../../api/gqlaxy/gql/generated/graphql'
import { defineMessages, useIntl } from 'react-intl'

export interface ListingOpenHouseTagProps {
  listing: ListingCompact | Listing
}

const MSG = defineMessages({
  openHouse: {
    id: 'HomeSearch.listingCard.openHouse',
    defaultMessage: 'Open: {openHouseStartDate} {openHouseStartTime}-{openHouseEndTime}'
  }
})

export const ListingOpenHouseTag = ({ listing }: ListingOpenHouseTagProps) => {
  const intl = useIntl()
  if (!listing?.openHouses?.length) {
    return null
  }

  const futureOpenHouses = listing.openHouses.filter(
    openHouse => !!openHouse?.endTime && new Date(openHouse?.endTime) > new Date()
  )

  if (!futureOpenHouses?.length || !futureOpenHouses?.[0]?.startTime || !futureOpenHouses?.[0]?.endTime) {
    return null
  }

  const openHouseStartDay = format(new Date(futureOpenHouses[0]?.startTime), 'E')
  const openHouseEndTime = format(new Date(futureOpenHouses[0]?.endTime), 'h:mma')
  const openHouseStartDate = format(new Date(futureOpenHouses[0]?.startTime), 'M/d')
  const openHouseStartTime = format(new Date(futureOpenHouses[0]?.startTime), 'h:mm')

  return futureOpenHouses?.length > 0 ? (
    <Flex
      px={2}
      py={1}
      ml={2}
      gap={2}
      borderWidth={1}
      borderRadius={9}
      alignItems='center'
      justifyItems='center'
      borderColor='neutral.500'
      backgroundColor='neutral.100'
    >
      <Text margin={0} fontWeight={600} size='2xs'>
        {intl.formatMessage(MSG.openHouse, {
          openHouseStartDate:
            differenceInDays(new Date(futureOpenHouses[0]?.startTime), new Date()) > 7
              ? openHouseStartDate
              : openHouseStartDay,
          openHouseStartTime,
          openHouseEndTime
        })}
      </Text>
    </Flex>
  ) : null
}
