import client from '../client'

export const createClientTeamMembershipRequest = (clientId, { firstName, lastName, email, isVerified }) =>
  client.post(`/clients/${clientId}/hbn-team-memberships`, {
    client_team_membership: {
      added_by: 'client',
      is_verified: isVerified,
      customer_profile: {
        first_name: firstName,
        last_name: lastName,
        email
      }
    }
  })

export const createClientTeamMembershipWithCustomerRequest = (clientId, { customerProfileId, isVerified }) =>
  client.post(`/clients/${clientId}/hbn-team-memberships`, {
    client_team_membership: {
      added_by: 'client',
      is_verified: isVerified,
      customer_profile_id: customerProfileId
    }
  })

export const replaceClientTeamMembershipRequest = (
  clientId,
  clientTeamMembershipId,
  { firstName, lastName, email, isVerified }
) =>
  client.post(`/clients/${clientId}/hbn_team_memberships/${clientTeamMembershipId}/replace`, {
    client_team_membership: {
      added_by: 'client',
      is_verified: isVerified,
      customer_profile: {
        first_name: firstName,
        last_name: lastName,
        email
      }
    }
  })

export const replaceClientTeamMembershipWithCustomerRequest = (
  clientId,
  clientTeamMembershipId,
  { customerProfileId, isVerified }
) =>
  client.post(`/clients/${clientId}/hbn_team_memberships/${clientTeamMembershipId}/replace`, {
    client_team_membership: {
      added_by: 'client',
      is_verified: isVerified,
      customer_profile_id: customerProfileId
    }
  })

export const verifyClientTeamMembershipRequest = (clientId, clientTeamMembershipId) =>
  client.put(`/clients/${clientId}/hbn_team_memberships/${clientTeamMembershipId}/verify`)

export const deleteClientTeamMembershipRequest = (clientId, membershipId) =>
  client.delete(`clients/${clientId}/hbn_team_memberships/${membershipId}`)

export const getHbnCustomerProfileByEmailRequest = (email: string) =>
  client.get(`/hbn-customer-profile?email=${encodeURIComponent(email)}`, {
    headers: {
      Accept: 'application/json'
    },
    transformResponse: [
      data => {
        // Parse the response if it's a string
        const parsed = typeof data === 'string' ? JSON.parse(data) : data
        return parsed
      }
    ]
  })
