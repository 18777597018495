import { Tab, TabProps, forwardRef, useTheme } from '@homebotapp/hb-react-component-catalog'

export const NavigationTab = forwardRef((tabProps: TabProps, forwardedRef) => {
  const { theme } = useTheme()
  const isLight = theme.isLightThemeType

  return (
    <Tab
      {...tabProps}
      ref={forwardedRef}
      _selected={{
        color: 'brand.700',
        borderColor: 'currentColor !important',
        fontWeight: 'semibold',
        // [CFE Global Styling]: This one is caused by CFE styling the focus state of all link elements
        _hover: {
          color: 'brand.700'
        },
        // [CFE Global Styling]: This one is caused by CFE styling the focus state of all link elements
        _focus: {
          color: 'brand.700'
        }
      }}
      display='flex'
      flexDirection={['column', null, null, 'row']}
      justifyContent='center'
      alignItems='center'
      borderRadius='0'
      borderTop={['2px solid', null, null, 'none']}
      borderBottomStyle='none'
      borderColor='chakra-border-color'
      // [CFE Global Styling]: This one is caused by CFE styling the focus state of all link elements
      _hover={{
        color: 'neutral.900',
        backgroundColor: isLight ? 'blackAlpha.200' : 'whiteAlpha.200'
      }}
      // [CFE Global Styling]: This one is caused by CFE styling the focus state of all link elements
      _focus={{
        color: 'neutral.900'
      }}
    />
  )
})
