export const DIGEST_V2 = 'digestV2'

// matching MS and Mikasa constants
export enum PlayerTypeEnum {
  YOUTUBE = 'youtube',
  BOMBBOMB = 'bombbomb',
  VIMEO = 'vimeo',
  VIDYARD = 'vidyard',
  WISTIA = 'wistia',
  PITCHHUB = 'pitchhub'
}

export const PLAYER_TYPES = Object.values(PlayerTypeEnum)
