import { useSelector } from 'react-redux'
import { defineMessages, useIntl } from 'react-intl'

// Components
import { Avatar, If, Text, Button, ExternalLinkButton } from '../..'
import { Box } from '@homebotapp/hb-react-component-catalog'

// Selectors
import {
  selectCompanyLogo,
  selectCompanyName,
  selectCustomerBrandingStatement,
  selectCustomerEmail,
  selectCustomerFirstName,
  selectCustomerFormattedPhone,
  selectCustomerNMLS,
  selectCustomerPhone,
  selectCustomerPhotoUri,
  selectCustomerWebsite,
  selectFullName,
  selectLinkedinUrl,
  selectOfficeAddress,
  selectOfficeName,
  selectOfficeNmls,
  selectOfficeFormattedPhone,
  selectShowOfficeAddress,
  selectStateLicense,
  selectLoanApplicationUri,
  selectLoanApplicationCtaEnabled,
  selectScheduleACallUri
} from '../../../store/selectors/customerProfile'

// Utils
import { safeExternal } from '../../../helpers/links'

// Assets
import TopoSvg from './TopoSvg'
import QuotesSvg from './QuotesSvg'

// styles
import styles from './CardPro.module.scss'
import { LeavingHomebotCtaButton } from '../LeavingHomebotCta/LeavingHomebotCtaButton'
import { clientEventSourceMapper } from '../../homeSearch/Utils/clientEventSourceMapper'

export const MSG = defineMessages({
  ctaButtonText: {
    id: 'CardPro.customer.ctaButtonText',
    defaultMessage: 'Review your financing options'
  },
  email: {
    id: 'CardPro.customer.email',
    defaultMessage: 'Email'
  },
  phone: {
    id: 'CardPro.customer.phone',
    defaultMessage: 'Phone'
  },
  website: {
    id: 'CardPro.customer.website',
    defaultMessage: 'Website'
  },
  linkedIn: {
    id: 'CardPro.customer.linkedin',
    defaultMessage: 'LinkedIn'
  },
  stateLicense: {
    id: 'CardPro.customer.stateLicense',
    defaultMessage: 'State license'
  },
  aNotefrom: {
    id: 'CardPro.customer.note',
    defaultMessage: 'A note from {customerFirstName}'
  },
  scheduleACallHeaderText: {
    id: 'CardPro.customer.scheduleACallHeaderText',
    defaultMessage: 'Schedule a call'
  },
  continueText: {
    id: 'CardPro.customer.continueText',
    defaultMessage: "Do you want to continue to {name}'s scheduling site in a new tab?"
  }
})

export const CardPro = () => {
  const intl = useIntl()
  // Company
  const companyLogo = useSelector(selectCompanyLogo)
  const companyName = useSelector(selectCompanyName)

  // Customer Profile
  const customerBrandingStatement = useSelector(selectCustomerBrandingStatement)
  const customerEmail = useSelector(selectCustomerEmail)
  const customerFirstName = useSelector(selectCustomerFirstName)
  const customerFormattedPhone = useSelector(selectCustomerFormattedPhone)
  const customerFullName = useSelector(selectFullName)
  const customerLinkedin = useSelector(selectLinkedinUrl)
  const customerNmls = useSelector(selectCustomerNMLS)
  const customerPhone = useSelector(selectCustomerPhone)
  const customerPhoto = useSelector(selectCustomerPhotoUri)
  const customerStateLicense = useSelector(selectStateLicense)
  const customerWebsite = useSelector(selectCustomerWebsite)
  const scheduleACallUri = useSelector(selectScheduleACallUri)

  // Office
  const officeAddress = useSelector(selectOfficeAddress)
  const officeName = useSelector(selectOfficeName)
  const officeNmls = useSelector(selectOfficeNmls)
  const officeFormattedPhone = useSelector(selectOfficeFormattedPhone)
  const showOfficeAddress = useSelector(selectShowOfficeAddress)
  const loanApplicationUri = useSelector(selectLoanApplicationUri)
  const loanApplicatioCtaEnabled = useSelector(selectLoanApplicationCtaEnabled)
  const { street, city, state, zipCode, zip } = officeAddress || {}
  const showCta = loanApplicatioCtaEnabled && loanApplicationUri

  const location = window.location.pathname

  const scheduleACallRenderModalBody = () => {
    return (
      <Text>
        {intl.formatMessage(MSG.continueText, {
          name: customerFirstName
        })}
      </Text>
    )
  }

  return (
    <div className={styles.root} data-qa='CardPro'>
      <div className={styles.avatarContainer}>
        <Avatar className={styles.avatarShifted} src={customerPhoto} />
        <If condition={companyLogo}>
          <Avatar className={styles.avatarShifted} src={companyLogo} />
        </If>
      </div>

      <div className={styles.container}>
        <div className={styles.nameContainer}>
          <If condition={companyName}>
            <Text className={styles.companyName} weight='bold' variant='neutralCool' variantCount='600'>
              {companyName}
            </Text>
          </If>

          <If condition={customerFullName}>
            <Text className={styles.customerName} size='xl' weight='bold' variant='neutralCool' variantCount='900'>
              {customerFullName}
            </Text>
          </If>

          <If condition={customerNmls}>
            <Text size='tiny' variant='neutralCool' variantCount='600'>
              <abbr title='Nationwide Multistate Licensing System'>NMLS</abbr> #{customerNmls}
            </Text>
          </If>
        </div>

        {(customerBrandingStatement || showCta) && (
          <Box mt={14} mb={12} data-qa='cardpro-middle-contents'>
            {customerBrandingStatement && (
              <div className={styles.noteContainer}>
                <div className={styles.circle}>
                  <QuotesSvg />
                </div>
                <div className={styles.noteContent}>
                  <Text element='div' weight='bold' size='xs' variant='neutralCool' variantCount='700'>
                    {intl.formatMessage(MSG.aNotefrom, {
                      customerFirstName: customerFirstName
                    })}
                  </Text>
                  <Text className={styles.note} element='p' size='s' variant='neutralCool' variantCount='700'>
                    {customerBrandingStatement}
                  </Text>
                </div>
              </div>
            )}

            {showCta && (
              <Box mt={3} mb={12}>
                <LeavingHomebotCtaButton
                  ctaButtonSize='sm'
                  ctaButtonVariant='ghost'
                  ctaButtonColorScheme='primary'
                  ctaLinkUrl={loanApplicationUri}
                  tracking={{
                    guid: 'qjrRRtg7QQenNSt5y4yZYo',
                    ui_context: 'Footer',
                    descriptor: 'Cta button clicked'
                  }}
                >
                  {intl.formatMessage(MSG.ctaButtonText)}
                </LeavingHomebotCtaButton>
              </Box>
            )}
          </Box>
        )}

        <If condition={officeName}>
          <address>
            <div className={styles.topoContainer}>
              <div className={styles.officeContainer}>
                <div className={styles.officeNameAndNMLS}>
                  <If condition={officeNmls}>
                    <Text size='tiny' variant='neutralCool' variantCount='500'>
                      <abbr title='Nationwide Multistate Licensing System'>NMLS</abbr> #{officeNmls}
                    </Text>
                  </If>
                  <Text
                    className={styles.officeName}
                    weight='semibold'
                    size='s'
                    variant='neutralCool'
                    variantCount='900'
                  >
                    {officeName}
                  </Text>
                </div>
                <If condition={showOfficeAddress}>
                  <div className={styles.officeAddress}>
                    <Text size='s' variant='neutralCool' variantCount='800'>
                      {street}
                    </Text>
                    <Text size='xxs' variant='neutralCool' variantCount='600'>
                      {`${city}, ${state} ${zipCode || zip}`}
                    </Text>
                  </div>
                </If>
                <If condition={officeFormattedPhone}>
                  <Button element='a' minimal href={`tel:${officeFormattedPhone}`} className={styles.officePhone}>
                    {officeFormattedPhone}
                  </Button>
                </If>
              </div>
              <TopoSvg className={styles.topoSvg} />
            </div>
          </address>
        </If>

        <dl>
          <address>
            <div className={styles.contactContainer}>
              <div className={styles.rowContainer}>
                <If condition={customerEmail}>
                  <div className={styles.row}>
                    <Text element='dt' weight='semibold' variant='neutralCool' variantCount='800'>
                      {intl.formatMessage(MSG.email)}
                    </Text>
                    <Button minimal element='a' href={`mailto:${customerEmail}`} className={styles.contactButton}>
                      <dd>{customerEmail}</dd>
                    </Button>
                  </div>
                </If>
                <If condition={customerPhone}>
                  <div className={styles.row}>
                    <Text element='dt' weight='semibold' variant='neutralCool' variantCount='800'>
                      {intl.formatMessage(MSG.phone)}
                    </Text>
                    <Button element='a' minimal href={`tel:${customerPhone}`} className={styles.contactButton}>
                      <dd>{customerFormattedPhone}</dd>
                    </Button>
                  </div>
                </If>
                <If condition={customerWebsite}>
                  <div className={styles.row}>
                    <Text element='dt' weight='semibold' variant='neutralCool' variantCount='800'>
                      {intl.formatMessage(MSG.website)}
                    </Text>
                    <ExternalLinkButton
                      minimal
                      element='a'
                      href={safeExternal(customerWebsite)}
                      className={styles.contactButton}
                    >
                      <dd>{customerWebsite}</dd>
                    </ExternalLinkButton>
                  </div>
                </If>
                <If condition={customerLinkedin}>
                  <div className={styles.row}>
                    <Text element='dt' weight='semibold' variant='neutralCool' variantCount='800'>
                      {intl.formatMessage(MSG.linkedIn)}
                    </Text>
                    <ExternalLinkButton
                      minimal
                      element='a'
                      href={safeExternal(customerLinkedin)}
                      className={styles.contactButton}
                    >
                      <dd>{customerLinkedin}</dd>
                    </ExternalLinkButton>
                  </div>
                </If>
                <If condition={customerStateLicense}>
                  <div className={styles.row}>
                    <Text element='dt' weight='semibold' variant='neutralCool' variantCount='800'>
                      {intl.formatMessage(MSG.stateLicense)}
                    </Text>
                    <dd className={styles.customerStateLicense}>{customerStateLicense}</dd>
                  </div>
                </If>
                {scheduleACallUri && (
                  <LeavingHomebotCtaButton
                    fullWidth
                    scheduleACall
                    ctaButtonSize='sm'
                    ctaButtonColorScheme='brand'
                    hideIllustration
                    eventSource={clientEventSourceMapper(location)}
                    headerText={intl.formatMessage(MSG.scheduleACallHeaderText)}
                    ctaButtonVariant={'outline'}
                    ctaLinkUrl={scheduleACallUri}
                    renderModalBody={scheduleACallRenderModalBody}
                    tracking={{
                      guid: '1YPnBwmE77mMsWYwtMnukH',
                      ui_context: 'Footer',
                      descriptor: 'Schedule a call button clicked'
                    }}
                  >
                    {intl.formatMessage(MSG.scheduleACallHeaderText)}
                  </LeavingHomebotCtaButton>
                )}
              </div>
            </div>
          </address>
        </dl>
      </div>
    </div>
  )
}
