import * as Sentry from '@sentry/browser'
import get from 'lodash/get'
import { getLocations } from '../api/mikasa/requests'
import { SET_FILTER } from './actionTypes'

export const setFilter = filter => ({
  type: SET_FILTER,
  filter
})

export const loadSuggestedLocations = params => dispatch => {
  getLocations({ query: params.query })
    .then(resp => {
      const location = get(resp, 'data.data', [])
      const selectedLocation = location.find(loc => loc.displayName === params.fullLocationName) || location[0]
      selectedLocation.lat = parseFloat(selectedLocation.lat)
      selectedLocation.lon = parseFloat(selectedLocation.lon)

      const newFilters = Object.assign({}, params.filter, {
        location: selectedLocation,
        order: params.filter?.order || 'DAYS_ON_MARKET, asc'
      })
      dispatch(setFilter(newFilters))
    })
    .catch(error => {
      Sentry.captureException(error)
    })
}
