import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import qs from 'qs'
import { Redirect } from 'react-router-dom'

// Utils
import { buildUrlParamObj } from '../helpers/utils'
import { redirect } from '../helpers/browser'
import { getClientIdFromToken } from '../quartermaster/src'
import { setClientToken } from '../auth'

// Actions
import { setReferrerSource } from '../actions/trackingSource'
import { fetchCustomMailer } from '../actions/customMailer'
import { flagClientForUnsubscribe } from '../actions/client'
import { setExternalRedirectUrl } from '../store/slices/auth'

import { showDisclaimer } from '../actions/layout'
import {
  unsubscribeFromHomeDigestFlow,
  unsubscribeFromHomeMarketFlow,
  unsubscribeFromHomeownerEmailsFlow,
  unsubscribeFromListingsFlow,
  unsubscribeFromCustomerFlow
} from '../constants/unsubscribe'

// Constants
import { AUTH } from '../constants'
import { PATH_HELPERS } from '../constants/navigation'

const propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    hash: PropTypes.string,
    search: PropTypes.string
  })
}

const AuthCallback = ({ location }) => {
  const dispatch = useDispatch()
  const { hash, search } = location

  const {
    state: resourceId,
    type: resourceIndicator,
    unsubscribe: unsubParam,
    targetEl: elementRef,
    targetPath: routeForSource,
    'referrer-source': referrerSource,
    toggleDisclaimer: disclaimerParam,
    togglePrequal,
    customMailerId,
    redirectUrl,
    externalRedirectUrl,
    zipCode
  } = qs.parse(search, { ignoreQueryPrefix: true })

  let accessTokenParams

  if (hash) {
    accessTokenParams = buildUrlParamObj(hash, '#')
  }

  const {
    access_token: token,
    homeDigestUnsubscribe: homeDigestUnsubFragment,
    homeMarketUnsubscribe: homeMarketUnsubFragment,
    listingsUnsubscribe: listingsUnsubFragment,
    managePreferences: managePreferencesFragment,
    unsubscribe: unsubFragment,
    unsubscribeFromCustomer: unsubscribeFromCustomerFragment
  } = accessTokenParams || {}

  const parsedClientId = getClientIdFromToken(token)

  // Old unsub flows, to be retired but need to support until links expire
  const forHomeowners = resourceIndicator === 'homes'
  const homeDigestUnsubscribe = Boolean(homeDigestUnsubFragment)
  const homeMarketUnsubscribe = Boolean(homeMarketUnsubFragment)
  const listingsUnsubscribe = Boolean(listingsUnsubFragment)
  const unsubscribe = Boolean(unsubParam || unsubFragment)

  const managePreferences = Boolean(managePreferencesFragment)
  const shouldShowDisclaimer = Boolean(disclaimerParam)
  const shouldShowPrequal = Boolean(togglePrequal)
  const unsubscribeFromCustomer = Boolean(unsubscribeFromCustomerFragment)

  if (unsubscribe && listingsUnsubscribe) {
    dispatch(flagClientForUnsubscribe(unsubscribeFromListingsFlow))
  } else if (unsubscribe && homeMarketUnsubscribe) {
    dispatch(flagClientForUnsubscribe(unsubscribeFromHomeMarketFlow))
  } else if (unsubscribe && homeDigestUnsubscribe) {
    dispatch(flagClientForUnsubscribe(unsubscribeFromHomeDigestFlow))
  } else if (unsubscribe) {
    if (forHomeowners) {
      // unsubscribe link is going to be retired
      // temporary support until old link will expire
      // usubscribe both home digest and home market
      dispatch(flagClientForUnsubscribe(unsubscribeFromHomeownerEmailsFlow))
    } else {
      dispatch(flagClientForUnsubscribe(resourceIndicator))
    }
  } else if (unsubscribeFromCustomer) {
    dispatch(flagClientForUnsubscribe(unsubscribeFromCustomerFlow))
  } else if (shouldShowDisclaimer) {
    dispatch(showDisclaimer())
  }

  if (!token || !resourceId || !hash) {
    redirect(AUTH.SSO_REDIRECT_URI)
    return null
  }

  setClientToken(token)
  dispatch(setReferrerSource(referrerSource))

  if (customMailerId) {
    dispatch(fetchCustomMailer(customMailerId))
  }

  if (redirectUrl) {
    const url = new URL(redirectUrl as string)
    const pathnameWithSearch = url.pathname + url.search

    return <Redirect to={pathnameWithSearch} />
  }

  if (externalRedirectUrl) {
    dispatch(setExternalRedirectUrl(externalRedirectUrl as string))

    return <Redirect to='/external-redirect-loading' />
  }

  if (resourceIndicator === 'homes') {
    const clientId = parsedClientId

    let path
    if (clientId && clientId.length === 36) {
      path = PATH_HELPERS.home.buildPath(clientId, resourceId)
      if (managePreferences || unsubscribe || unsubscribeFromCustomer) {
        path += `/preferences`
      }
    } else {
      path = `/reports/home/${resourceId}`
    }

    if (elementRef) {
      path += `#${elementRef}`
    }

    if (routeForSource) {
      path += routeForSource
    }

    return <Redirect to={path} />
  }

  let path
  if (customMailerId) {
    path = PATH_HELPERS.buy.buildPath(resourceId)
  } else if (zipCode) {
    path = PATH_HELPERS.zip.buildPath(resourceId, zipCode)
  } else {
    path = PATH_HELPERS.buy.buildPath(resourceId)
  }

  if (managePreferences || unsubscribe || unsubscribeFromCustomer) {
    path = PATH_HELPERS.buy.buildPath(resourceId, '/preferences')
  }

  if (shouldShowPrequal) {
    path += '?openPrequalModal=true'
  }

  return <Redirect to={path} />
}

AuthCallback.propTypes = propTypes

export default AuthCallback
