import * as Sentry from '@sentry/browser'
import axios from 'axios'
import get from 'lodash/get'
import set from 'lodash/set'

import { QUESTION_DATE } from '../constants/client/onboarding'
import { QUESTION_HOME_INSURANCE_MODULE, SNOOZED_AVM_HOT_MARKET } from '../constants/client/answers'
import { SUBSCRIPTIONS, PREFERENCES } from '../constants/messages'
import { HOMEBOT_LISTINGS_FEATURE } from '../constants/listings'

import { hasCustomerToken } from '../auth'
import {
  getClientData,
  getClientAnswers,
  createAnswer,
  updateAnswer,
  enableHBB,
  setMarketInterest,
  patchClientAttributeFields,
  getClientHomes as requestGetClientHomes,
  createDigest as createDigestRequest
} from '../api/mikasa/requests'
import { selectHomeData, selectZipcode } from '../store/selectors/home'
import { selectClientId, selectClientFetchId } from '../store/selectors/client'
import { setLocaleData } from './locale'
import { setProfile } from './customerProfile'
import { fetchBuyerInfoData } from './buyerInfo'
import { flashNotification } from './notification'
import { addToast } from './toasts'
import {
  SET_CLIENT_FETCH_ID,
  FETCH_CLIENT_REQUEST,
  FETCH_CLIENT_SUCCESS,
  FETCH_CLIENT_FAILURE,
  FETCH_CLIENT_HOMES_REQUEST,
  FETCH_CLIENT_HOMES_SUCCESS,
  FETCH_CLIENT_HOMES_FAILURE,
  SAVE_CLIENT_ANSWER_REQUEST,
  SAVE_CLIENT_ANSWER_SUCCESS,
  SAVE_CLIENT_ANSWER_FAILURE,
  SAVE_CLIENT_DATA_REQUEST,
  SAVE_CLIENT_DATA_SUCCESS,
  SAVE_CLIENT_DATA_FAILURE,
  RESET_CLIENT_STATUS,
  CREATE_DIGEST_REQUEST,
  CREATE_DIGEST_SUCCESS,
  CREATE_DIGEST_FAILURE,
  FLAG_FOR_UNSUBSCRIBE,
  SET_CLIENT_IS_GUEST,
  SET_CLIENT_IS_LOADING,
  SET_CLIENT_FAVORITE_LISTINGS_ALERT_CADENCE_DAYS
} from './actionTypes'
import { format } from 'date-fns'
import { setClientTeamMemberships } from 'src/store/slices/homebotNetwork/clientTeamMemberships'

const changedSubscriptionMessage = ({ subscribed }) => {
  return `Successfully ${subscribed ? 'subscribed' : 'unsubscribed'}`
}

export const setCurrentClient = clientId => async dispatch => {
  dispatch({
    type: SET_CLIENT_FETCH_ID,
    id: clientId
  })
}

export const fetchClientData = (clientId, currentCustomerProfileId) => async dispatch => {
  dispatch({
    type: FETCH_CLIENT_REQUEST
  })

  if (!clientId) {
    dispatch({
      type: SET_CLIENT_IS_GUEST,
      data: true
    })

    return
  }

  try {
    const [buyerResponse, answerResponse] = await Promise.all([getClientData(clientId), getClientAnswers(clientId)])
    const data = {
      ...get(buyerResponse, 'data.data'),
      ...set({}, 'clientAnswers', get(answerResponse, 'data.data'))
    }

    if (hasCustomerToken()) {
      // allows buyer nav link to work correctly when homesearch is refreshed while impersonating
      localStorage.setItem('impersonatedClientHbbAccess', data.hbbAccess)
    }

    // This is a bit of a hack, but when we get the client data, we 
    // set the state of the redux slice so that we have a nice top level 
    // key for client team memberships.
    const memberships = get(buyerResponse, 'data.data.clientTeamMemberships')
    dispatch(setClientTeamMemberships(memberships))

    const { clientTeamMemberships, ...dataWithoutMemberships } = data
    dispatch({
      type: FETCH_CLIENT_SUCCESS,
      data: dataWithoutMemberships
    })
    // End of hack

    dispatch(setLocaleData(data.locale))

    const clientCustomerProfile = get(buyerResponse, 'data.data.customerProfile')

    const isGuest = currentCustomerProfileId && currentCustomerProfileId !== clientCustomerProfile?.id

    if (clientCustomerProfile) {
      if (isGuest) {
        dispatch({
          type: SET_CLIENT_IS_GUEST,
          data: true
        })
      } else {
        dispatch(setProfile(buyerResponse.data.data.customerProfile))
      }
    }
  } catch (error) {
    Sentry.captureException(error)
    dispatch({
      type: FETCH_CLIENT_FAILURE,
      error
    })
  }
}

export const fetchClientHomes = clientId => async dispatch => {
  dispatch({
    type: FETCH_CLIENT_HOMES_REQUEST
  })

  try {
    const response = await requestGetClientHomes(clientId)
    dispatch({
      type: FETCH_CLIENT_HOMES_SUCCESS,
      data: response.data.data
    })
  } catch (error) {
    dispatch({
      type: FETCH_CLIENT_HOMES_FAILURE,
      error
    })
  }
}

const tolerate422 = promise =>
  promise.catch(error => Promise[get(error, 'response.status') === 422 ? 'resolve' : 'reject'](error))

export const createDigest =
  ({ clientId, email }) =>
  async dispatch => {
    dispatch({
      type: CREATE_DIGEST_REQUEST
    })

    try {
      await createDigestRequest({ clientId, email })
      dispatch({
        type: CREATE_DIGEST_SUCCESS
      })
    } catch (e) {
      dispatch({
        type: CREATE_DIGEST_FAILURE
      })
    }
  }

export const setupBuyer = affordability => async (dispatch, getState) => {
  const state = getState()
  const home = selectHomeData(state)
  const clientId = selectClientId(state)
  const zipcode = selectZipcode(state)

  if (!home || !clientId) {
    return
  }

  const enableRequest = enableHBB(clientId, { affordability })

  const marketIntRequest = setMarketInterest(clientId, zipcode)

  try {
    await Promise.all([enableRequest, tolerate422(marketIntRequest)])
    dispatch({
      type: SET_CLIENT_FETCH_ID,
      id: clientId
    })
    dispatch(fetchClientData(clientId))
    dispatch(fetchBuyerInfoData(clientId))
  } catch (error) {
    Sentry.captureException(error)
  }
}

export const saveClientAnswer = (answer, notify) => async (dispatch, getState) => {
  const client = get(getState(), 'client.data')

  if (!client) {
    return
  }

  dispatch({
    type: SAVE_CLIENT_ANSWER_REQUEST
  })

  const existingAnswer = client.clientAnswers && client.clientAnswers.find(({ question: q }) => q === answer.question)

  const reqMethod = existingAnswer ? updateAnswer : createAnswer
  try {
    const { data } = await reqMethod(client.id, {
      ...existingAnswer,
      ...answer
    })
    dispatch({
      type: SAVE_CLIENT_ANSWER_SUCCESS,
      data: data.data
    })
    if (notify && notify.successMessage) {
      dispatch(
        flashNotification({
          message: notify.successMessage,
          messageType: 'success'
        })
      )
    }
  } catch (e) {
    dispatch({
      type: SAVE_CLIENT_ANSWER_FAILURE,
      error: e
    })
    if (notify && notify.failureMessage) {
      dispatch(
        flashNotification({
          message: notify.failureMessage
        })
      )
    }
  }
}

export const saveCloseDate = value =>
  saveClientAnswer({
    question: QUESTION_DATE,
    response: { value }
  })

export const saveHomeInsuranceOptOut = value =>
  saveClientAnswer({
    question: QUESTION_HOME_INSURANCE_MODULE,
    response: { value }
  })

export const saveClientData = attributes => async (dispatch, getState) => {
  const state = getState()
  const clientId = selectClientId(state)

  const { CancelToken } = axios
  const source = CancelToken.source()

  const payload = {
    ...get(state, 'client.saveRequest.attributes', {}),
    ...attributes
  }
  const oldSource = get(state, 'client.saveRequest.source')

  if (oldSource && typeof oldSource.cancel === 'function') {
    oldSource.cancel()
  }

  dispatch({
    type: SAVE_CLIENT_DATA_REQUEST,
    saveRequest: {
      clientId,
      source,
      attributes: payload
    }
  })

  try {
    const response = await patchClientAttributeFields(clientId, payload, source)
    dispatch({
      type: SAVE_CLIENT_DATA_SUCCESS,
      data: response.data.data
    })
  } catch (error) {
    if (!axios.isCancel(error)) {
      dispatch({
        type: SAVE_CLIENT_DATA_FAILURE,
        error
      })
    }
  }
}

export const resetClientStatus = () => dispatch => dispatch({ type: RESET_CLIENT_STATUS })

const updateSubscriptionStatus = (attributes, notifyMessage) => async (dispatch, getState) => {
  const state = getState()
  const clientId = selectClientFetchId(state)

  dispatch({
    type: SAVE_CLIENT_DATA_REQUEST,
    saveRequest: {
      clientId,
      attributes
    }
  })

  try {
    const response = await patchClientAttributeFields(clientId, attributes)
    dispatch({
      type: SAVE_CLIENT_DATA_SUCCESS,
      data: response.data.data
    })

    if (notifyMessage) {
      dispatch(
        flashNotification({
          message: notifyMessage,
          messageType: 'error'
        })
      )
    }
    dispatch({ type: RESET_CLIENT_STATUS })
  } catch (error) {
    dispatch({
      type: SAVE_CLIENT_DATA_FAILURE,
      error
    })

    if (notifyMessage) {
      dispatch(
        flashNotification({
          message: SUBSCRIPTIONS.notificationError,
          messageType: 'error'
        })
      )
      dispatch({ type: RESET_CLIENT_STATUS })
    }
  }
}

export const setClientIsSubscribedToBuy =
  ({ setTo, notify }) =>
  async dispatch => {
    if (typeof setTo === 'undefined') {
      console.error('setClientIsSubscribedToBuy requires an explicit "setTo" argument')
    } else if (hasCustomerToken()) {
      dispatch(
        flashNotification({
          message: PREFERENCES.duh,
          messageType: 'error'
        })
      )
      dispatch(flagClientForUnsubscribe(null))
    } else if (notify) {
      dispatch(
        updateSubscriptionStatus(
          {
            'subscribed-to-buyer-digest': setTo
          },
          setTo ? SUBSCRIPTIONS.notificationSubscribed : SUBSCRIPTIONS.notificationUnsubscribed
        )
      )
    } else {
      dispatch(saveClientData({ 'subscribed-to-buyer-digest': setTo }))
      dispatch(addToast({ message: changedSubscriptionMessage({ subscribed: setTo }) }))
    }
  }

export const setClientIsSubscribedToHome =
  ({ setTo, notify }) =>
  async dispatch => {
    if (typeof setTo === 'undefined') {
      console.error('setClientIsSubscribedToHome requires an explicit "setTo" argument')
    } else if (hasCustomerToken()) {
      dispatch(
        flashNotification({
          message: PREFERENCES.duh,
          messageType: 'error'
        })
      )
      dispatch(flagClientForUnsubscribe(null))
    } else if (notify) {
      dispatch(
        updateSubscriptionStatus(
          {
            'digest-email-unsubscribed': !setTo,
            'subscribed-to-home-digest': setTo
          },
          setTo ? SUBSCRIPTIONS.notificationSubscribed : SUBSCRIPTIONS.notificationUnsubscribed
        )
      )
    } else {
      dispatch(
        saveClientData({
          'digest-email-unsubscribed': !setTo,
          'subscribed-to-home-digest': setTo
        })
      )
      dispatch(addToast({ message: changedSubscriptionMessage({ subscribed: setTo }) }))
    }
  }

export const setClientIsSubscribedToListings =
  ({ setTo, notify }) =>
  async dispatch => {
    if (typeof setTo === 'undefined') {
      console.error('setClientIsSubscribedToListings requires an explicit "setTo" argument')
    } else if (hasCustomerToken()) {
      dispatch(
        flashNotification({
          message: PREFERENCES.duh,
          messageType: 'error'
        })
      )
      dispatch(flagClientForUnsubscribe(null))
    } else if (notify) {
      dispatch(
        updateSubscriptionStatus(
          {
            'subscribed-to-listing-alerts': setTo
          },
          setTo ? SUBSCRIPTIONS.notificationSubscribedListings : SUBSCRIPTIONS.notificationUnsubscribedListings
        )
      )
    } else {
      dispatch(saveClientData({ 'subscribed-to-listing-alerts': setTo }))
      dispatch(addToast({ message: changedSubscriptionMessage({ subscribed: setTo }) }))
    }
  }

export const setClientIsUnsubscribedFromCustomer =
  ({ setTo, notify }) =>
  async dispatch => {
    if (typeof setTo === 'undefined') {
      console.error('setClientIsUnsubscribedFromCustomer requires an explicit "setTo" argument')
    } else if (hasCustomerToken()) {
      dispatch(
        flashNotification({
          message: PREFERENCES.duh,
          messageType: 'error'
        })
      )
      dispatch(flagClientForUnsubscribe(null))
    } else if (notify) {
      dispatch(
        updateSubscriptionStatus(
          {
            'unsubscribed-from-customer': setTo
          },
          setTo ? SUBSCRIPTIONS.notificationUnsubscribedFromCustomer : SUBSCRIPTIONS.notificationSubscribedToCustomer
        )
      )
    } else {
      dispatch(saveClientData({ 'unsubscribed-from-customer': setTo }))
      dispatch(
        addToast({
          message: changedSubscriptionMessage({ subscribed: !setTo })
        })
      )
    }
  }

// support for legacy links with unsubscribe=1
// will be removed once old links expire
export const setClientIsSubscribedToHomeownerEmails =
  ({ setTo, notify }) =>
  async dispatch => {
    if (typeof setTo === 'undefined') {
      console.error('setClientIsSubscribedToHomeownerEmails requires an explicit "setTo" argument')
    } else if (hasCustomerToken()) {
      dispatch(
        flashNotification({
          message: PREFERENCES.duh,
          messageType: 'error'
        })
      )
      dispatch(flagClientForUnsubscribe(null))
    } else if (notify) {
      dispatch(
        updateSubscriptionStatus(
          {
            'subscribed-to-home-market': setTo,
            'subscribed-to-home-digest': setTo,
            'digest-email-unsubscribed': !setTo
          },
          setTo
            ? SUBSCRIPTIONS.notificationSubscribedHomeownerEmails
            : SUBSCRIPTIONS.notificationUnsubscribedHomeownerEmails
        )
      )
    } else {
      dispatch(
        saveClientData({
          'subscribed-to-home-market': setTo,
          'subscribed-to-home-digest': setTo,
          'digest-email-unsubscribed': !setTo
        })
      )
    }
  }

export const setClientIsSubscribedToHomeMarket =
  ({ setTo, notify }) =>
  async dispatch => {
    if (typeof setTo === 'undefined') {
      console.error('setClientIsSubscribedToHomeMarket requires an explicit "setTo" argument')
    } else if (hasCustomerToken()) {
      dispatch(
        flashNotification({
          message: PREFERENCES.duh,
          messageType: 'error'
        })
      )
      dispatch(flagClientForUnsubscribe(null))
    } else if (notify) {
      dispatch(
        updateSubscriptionStatus(
          {
            'subscribed-to-home-market': setTo
          },
          setTo ? SUBSCRIPTIONS.notificationSubscribedHomeMarket : SUBSCRIPTIONS.notificationUnsubscribedHomeMarket
        )
      )
    } else {
      dispatch(saveClientData({ 'subscribed-to-home-market': setTo }))
      dispatch(addToast({ message: changedSubscriptionMessage({ subscribed: setTo }) }))
    }
  }

export const setClientOptedIntoSms =
  ({ setTo }) =>
  async dispatch => {
    if (typeof setTo === 'undefined') {
      console.error('setClientOptedIntoSms requires an explicit "setTo" argument')
    } else if (hasCustomerToken()) {
      console.warn('Customer auth does not allow persisting client changes')
    } else {
      dispatch(
        saveClientData({
          'opted-into-sms': setTo
        })
      )
      dispatch(addToast({ message: changedSubscriptionMessage({ subscribed: setTo }) }))
    }
  }

export const flagClientForUnsubscribe = resource => async dispatch => {
  dispatch({ type: FLAG_FOR_UNSUBSCRIBE, resource })
}

export const clientSnoozeAvmHotMarket = () => async dispatch => {
  dispatch(
    saveClientAnswer({
      question: SNOOZED_AVM_HOT_MARKET,
      response: {
        value: format(new Date(), 'yyyy-MM-dd')
      }
    })
  )
}

export const setClientIsGuest = bool => async dispatch => {
  dispatch({
    type: SET_CLIENT_IS_GUEST,
    data: bool
  })
}

export const setClientLoading = bool => async dispatch => {
  dispatch({
    type: SET_CLIENT_IS_LOADING,
    data: bool
  })
}

export const setClientFavoriteListingsAlertCadenceDays = integer => async dispatch => {
  dispatch({
    type: SET_CLIENT_FAVORITE_LISTINGS_ALERT_CADENCE_DAYS,
    data: integer
  })
}
