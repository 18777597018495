import { LISTING_SEARCH_MAX_LENGTH, LISTING_SEARCH_ARIA_LABELLED_BY } from '../../../../../constants/listings'
import { useDispatch } from 'react-redux'
import { useRef, useEffect, RefObject } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { TEXT } from '../../../../shared/FormField/types'
import { toggleListingSearchModal } from '../../../../../actions/listings'
import { useListingSearchContext } from '../../../../../context/listingSearch'
import { Input, Button, FormControl, FormLabel } from '@homebotapp/hb-react-component-catalog'
import { HOME_SEARCH_LISTINGS_INDEX_PAGE_LIST_VIEW } from '../../../../../constants/tracking/trackingEventLocations/homeSearchTrackingEventLocation'
import { useRecoilState } from 'recoil'
import { currentSelectedLocation } from '../../../GoogleAutoComplete/state/currentSelectedLocation'

export const MSG = defineMessages({
  desc: {
    id: 'SearchModal.describe.description',
    defaultMessage:
      'Type 2 characters to initiate address, zip, county, state, or location search. When autocomplete results are available use up and down arrows to review and enter to select.'
  },
  cancel: {
    id: 'SearchModal.input.cancel',
    defaultMessage: 'Cancel'
  },
  enterLocation: {
    id: 'SearchModal.input.enterLocation',
    defaultMessage: 'Enter a location'
  }
})

interface SearchModalInputProps {
  locationSearchInputRef?: RefObject<HTMLInputElement> | null | undefined
}

export const SearchModalInput = ({ locationSearchInputRef = null }: SearchModalInputProps) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const [, setSelectedLocation] = useRecoilState(currentSelectedLocation)
  const innerRef = useRef<HTMLInputElement>()
  const {
    inputId,
    inputValue,
    resultsListId,
    handleKeyDown,
    handleOnChange,
    activeDescendantId,
    hasResultsAfterSearch
  } = useListingSearchContext()

  useEffect(() => {
    if (locationSearchInputRef?.current) {
      locationSearchInputRef.current?.focus()
    } else {
      innerRef.current?.focus()
    }
  }, [])

  return (
    <FormControl display='flex' alignItems='center' flex='0 1 auto' w='100%'>
      <FormLabel srOnly>{intl.formatMessage(MSG.desc)}</FormLabel>
      <Input
        autoFocus
        type={TEXT}
        id={inputId}
        // @ts-ignore
        ref={locationSearchInputRef || innerRef}
        role='combobox'
        autoCorrect='off'
        value={inputValue}
        autoComplete='off'
        placeholder={intl.formatMessage(MSG.enterLocation)}
        autoCapitalize='off'
        aria-haspopup='listbox'
        aria-autocomplete='list'
        name='listingSearchInput'
        onChange={e => {
          setSelectedLocation(null)
          handleOnChange(e)
        }}
        onKeyDown={handleKeyDown}
        aria-owns={resultsListId}
        maxLength={LISTING_SEARCH_MAX_LENGTH}
        aria-expanded={hasResultsAfterSearch}
        data-active-option={activeDescendantId}
        aria-activedescendant={activeDescendantId}
        aria-labelledby={LISTING_SEARCH_ARIA_LABELLED_BY}
        tracking={{
          guid: 'nwVQMy7md1EDtpN5B3B6qd',
          ui_context: HOME_SEARCH_LISTINGS_INDEX_PAGE_LIST_VIEW,
          descriptor: 'Search Modal'
        }}
      />
      <Button
        ml={2}
        type='submit'
        variant='ghost'
        colorScheme='primary'
        id='cancelSearchModalBtn'
        onClick={() => dispatch(toggleListingSearchModal(false))}
        tracking={{
          guid: 'im4wy5dCFpZpAQmzBufXDG',
          ui_context: HOME_SEARCH_LISTINGS_INDEX_PAGE_LIST_VIEW,
          descriptor: 'Search Modal Cancel Button'
        }}
      >
        {intl.formatMessage(MSG.cancel)}
      </Button>
    </FormControl>
  )
}
