import { atom } from 'recoil'
import { StateKeys } from '../../../../constants/stateKeys'
import { StorageKey } from '../../../../constants/StorageKey'
import { storageEffect } from '../../../../effects/storageEffect'

export interface CurrentSelectedLocation extends google.maps.places.AutocompletePrediction {
  latitude?: number
  longitude?: number
  lat?: number // looks like in some instance we return lat instead of latitude
  lon?: number // looks like in some instance we return lon instead of longitude
  displayName: string
  placeId: string
  stateAbbreviation: string
  city?: string | null
  level?: string
  name?: string | null
}

export const currentSelectedLocation = atom<CurrentSelectedLocation | undefined>({
  key: StateKeys.currentSelectedLocation,
  default: undefined,
  effects: [storageEffect(StorageKey.CurrentSelectedLocation)]
})
