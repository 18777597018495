import { LT30 } from './loan'

export const avgSellingCostPercent = 0.07
export const lowFeePercent = 0.05
export const highFeePercent = 0.13

export const newHome = {
  downPaymentPercent: 0.2,
  loanTerm: LT30,
  budgetCap: 1.5
}
export const rentalProperty = {
  minimumEquity: 25000,
  targetLTV: 0.7,
  downPaymentPercent: 0.3,
  loanTerm: LT30,
  interestRateAdjustment: 0.75
}

export const rentBuy = {
  downPaymentPercent: 0.3,
  loanTerm: LT30
}

export const studentDebt = {
  average: 37000
}

export const equitySearchTable = {
  FIRST_BREAK_POINT: 250000,
  SECOND_BREAK_POINT: 999999,
  FIRST_STEP_DOWN_AMT: 25000,
  SECOND_STEP_DOWN_AMT: 50000,
  THIRD_STEP_DOWN_AMT: 100000,
  SELLING_COST_PERCENT: 0.07,
  MINIMUM_POCKETED_CASH: 10000,
  MAX_PAYMENT_INCREASE: 0.5,
  TWENTY_PERCENT_DOWN: 0.2,
  LTV_MAX_PERCENT_FOR_ESS: 70,
  LTV_MIN_PERCENT_FOR_ESS: 40
}
