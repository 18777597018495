/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * Ampli - A strong typed wrapper for your Analytics
 *
 * This file is generated by Amplitude.
 * To update run 'ampli pull kraken-web-ts'
 *
 * Required dependencies: @amplitude/analytics-browser@^1.3.0
 * Tracking Plan Version: 124
 * Build: 1.0.0
 * Runtime: browser:typescript-ampli-v2
 *
 * [View Tracking Plan](https://data.amplitude.com/homebot/Homebot%20Tracking%20Plan/events/main/latest)
 *
 * [Full Setup Instructions](https://data.amplitude.com/homebot/Homebot%20Tracking%20Plan/implementation/kraken-web-ts)
 */

import * as amplitude from '@amplitude/analytics-browser';

export type Environment = 'production' | 'development';

export const ApiKey: Record<Environment, string> = {
  production: '',
  development: ''
};

/**
 * Default Amplitude configuration options. Contains tracking plan information.
 */
export const DefaultConfiguration: BrowserOptions = {
  plan: {
    version: '124',
    branch: 'main',
    source: 'kraken-web-ts',
    versionId: '6bab5b78-fded-43ea-877e-a908ad2291fc'
  },
  ...{
    ingestionMetadata: {
      sourceName: 'browser-typescript-ampli',
      sourceVersion: '2.0.0'
    }
  }
};

export interface LoadOptionsBase { disabled?: boolean }

export type LoadOptionsWithEnvironment = LoadOptionsBase & { environment: Environment; client?: { configuration?: BrowserOptions; }; };
export type LoadOptionsWithApiKey = LoadOptionsBase & { client: { apiKey: string; configuration?: BrowserOptions; } };
export type LoadOptionsWithClientInstance = LoadOptionsBase & { client: { instance: BrowserClient; } };

export type LoadOptions = LoadOptionsWithEnvironment | LoadOptionsWithApiKey | LoadOptionsWithClientInstance;

export interface IdentifyProperties {
  /**
   * uuid assigned to client
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | [0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12} |
   */
  client_id?: string;
  /**
   * The number of clients associated with the customer profile
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  clients_count?: number;
  /**
   * uuid of the corperation attached to a customer_profile
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | [0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12} |
   */
  corp_id?: string;
  /**
   * Whether a user has received a CMA on their home or has tuned their value.
   */
  custom_valuations?: boolean;
  /**
   * customer email address
   */
  customer_email_address?: string;
  /**
   * uuid of attached customer in customer_properties
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | [0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12} |
   */
  customer_id?: string;
  /**
   * email address on user JWT
   */
  email_address?: string;
  /**
   * The domain portion (following @) of the email address associated with the users account
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | [^.]+\..+ |
   */
  email_domain?: string;
  /**
   * optional portion of email between + and @
   *
   * | Rule | Value |
   * |---|---|
   * | Max Length | 256 |
   */
  email_tag?: string;
  environment: string;
  /**
   * Utility property to force disable flags and experiments for co-borrowers if a borrower on the same home is not activated. Primarily used for sticky bucketing.
   */
  force_disable?: boolean;
  /**
   * Utility property to force enable flags and experiments for co-borrowers if a borrower on the same home is activated. Primarily used for sticky bucketing.
   */
  force_enable?: boolean;
  /**
   * **Wheather or not the client is considered a buyer client type**
   */
  is_buyer?: boolean;
  /**
   * **Wheather or not the client is considered a guest client type**
   */
  is_guest?: boolean;
  /**
   * **Wheather or not the client is considered a homeowner client type**
   */
  is_homeowner?: boolean;
  /**
   * Wheather or not the user is considered a insurance agent user type
   */
  is_insurance_agent?: boolean;
  /**
   * Whether or not the user is considered a lead. Used if user is landing on the search landing page.
   */
  is_lead?: boolean;
  /**
   * Wheather or not the user is considered a loan officer user type
   */
  is_loan_officer?: boolean;
  /**
   * Wheather or not the user is considered a real estate agent user type
   */
  is_real_estate_agent?: boolean;
  /**
   * timestamp when native app was last opened
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  last_app_open_time?: number;
  /**
   * Wheather or not the user has access to partner intel. This means the user has either paid for access, or its been enabled manually via a feature flag
   */
  partner_intel_enabled?: boolean;
  /**
   * The plan_code that the users subscription is attached to.
   */
  plan_code?: string;
  /**
   * type of account via JWT auth
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | client, customer, homebotter, unauthed |
   */
  user_type: "client" | "customer" | "homebotter" | "unauthed";
  /**
   * Zip code of a user's property
   *
   * | Rule | Value |
   * |---|---|
   * | Regex | \d{5} |
   */
  zipcode?: string;
}

export interface AccordionInteractionProperties {
  /**
   * Used by generic interaction events to specify which action was taken on the component that fired an interaction event
   */
  action: string;
  /**
   * Used by generic events to indicate the content of the element firing the interaction. Should be human readable and in English.
   */
  descriptor?: string;
  /**
   * Application the event is fired from
   */
  event_source?: string;
  /**
   * Unique identifier used by generic events that should be used in Custom Events. This will ensure that future changes to a property do not break our tracking queries.
   */
  guid: string;
  /**
   * Used by generic interaction events to specify which page the component firing the interaction event is on
   */
  pathname?: string;
  /**
   * Used by generic interaction events to determine which section of a page the event is being fired from. This property should always be included so we have enough information to determine which individual component is firing the event on a page. Should be snake_cased.
   */
  ui_context?: string;
}

export interface AirbnbExploredProperties {
  /**
   * What did the client interact with in their Airbnb module
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | monthly_revenue, nightly_rate, number_of_nights, basic_or_fancy, seldom_or_often |
   */
  airbnb_clicked: "monthly_revenue" | "nightly_rate" | "number_of_nights" | "basic_or_fancy" | "seldom_or_often";
  /**
   * Application the event is fired from
   */
  event_source?: string;
}

export interface AutocompleteInteractionProperties {
  /**
   * Used by generic interaction events to specify which action was taken on the component that fired an interaction event
   */
  action: string;
  /**
   * Used by generic events to indicate the content of the element firing the interaction. Should be human readable and in English.
   */
  descriptor?: string;
  /**
   * Application the event is fired from
   */
  event_source?: string;
  /**
   * Unique identifier used by generic events that should be used in Custom Events. This will ensure that future changes to a property do not break our tracking queries.
   */
  guid: string;
  /**
   * Used by generic interaction events to specify which page the component firing the interaction event is on
   */
  pathname?: string;
  /**
   * Used by generic interaction events to determine which section of a page the event is being fired from. This property should always be included so we have enough information to determine which individual component is firing the event on a page. Should be snake_cased.
   */
  ui_context?: string;
}

export interface AvmAlertViewedProperties {
  /**
   * What type of AVM alert is a client exposed to
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | avm_alert_variance, avm_alert_low_confidence, avm_alert_non_disclosure, avm_alert_in_demand |
   */
  avm_alert_type:
    | "avm_alert_variance"
    | "avm_alert_low_confidence"
    | "avm_alert_non_disclosure"
    | "avm_alert_in_demand";
  /**
   * Application the event is fired from
   */
  event_source?: string;
}

export interface AvmFeedbackProperties {
  /**
   * Describes how the client engages to their AVM alert in Homebot
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | avm_thumbs_up, avm_thumbs_down, avm_learn_more, avm_dismissed, avm_tuned |
   */
  avm_alert_response: "avm_thumbs_up" | "avm_thumbs_down" | "avm_learn_more" | "avm_dismissed" | "avm_tuned";
  /**
   * Application the event is fired from
   */
  event_source?: string;
}

export interface AvmInfoViewedProperties {
  /**
   * Application the event is fired from
   */
  event_source?: string;
}

export interface BudgetSavedProperties {
  /**
   * What did the client engage with in the Your Profile tab?
   *
   * | Rule | Value |
   * |---|---|
   * | Min Items | 0 |
   * | Max Items | 5 |
   * | Unique Items | true |
   * | Item Type | string |
   *
   * @minItems 0
   * @maxItems 5
   */
  budget_input?:
    | []
    | [string]
    | [string, string]
    | [string, string, string]
    | [string, string, string, string]
    | [string, string, string, string, string];
  /**
   * Application the event is fired from
   */
  event_source?: string;
}

export interface ButtonInteractionProperties {
  /**
   * Used by generic interaction events to specify which action was taken on the component that fired an interaction event
   */
  action: string;
  /**
   * Used by generic events to indicate the content of the element firing the interaction. Should be human readable and in English.
   */
  descriptor?: string;
  /**
   * Application the event is fired from
   */
  event_source?: string;
  /**
   * Unique identifier used by generic events that should be used in Custom Events. This will ensure that future changes to a property do not break our tracking queries.
   */
  guid: string;
  icon?: string;
  /**
   * Used by generic interaction events to specify which page the component firing the interaction event is on
   */
  pathname?: string;
  /**
   * name of route viewed
   */
  route_name?: string;
  size?: string;
  /**
   * Used by generic interaction events to determine which section of a page the event is being fired from. This property should always be included so we have enough information to determine which individual component is firing the event on a page. Should be snake_cased.
   */
  ui_context?: string;
}

export interface BuyerLeadAddedProperties {
  /**
   * Application the event is fired from
   */
  event_source?: string;
}

export interface BuyerNavigationLinkClickedProperties {
  /**
   * Where did the client nav to from the "find a home" dropdown
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | search_homes, favorite_listings, saved_searches, explore_markets, buying_report, for_you |
   */
  buyer_navigation_link:
    | "search_homes"
    | "favorite_listings"
    | "saved_searches"
    | "explore_markets"
    | "buying_report"
    | "for_you";
  /**
   * Application the event is fired from
   */
  event_source?: string;
}

export interface BuyerOnboardedProperties {
  city?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | jan, feb, mar, apr, may, jun, jul, aug, sep, oct, nov, dec |
   */
  close_timeline?: "jan" | "feb" | "mar" | "apr" | "may" | "jun" | "jul" | "aug" | "sep" | "oct" | "nov" | "dec";
  /**
   * Application the event is fired from
   */
  event_source?: string;
  /**
   * What menu option did the client click on to specifity seach criteria?
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | popular_markets, bigger_house, cheaper_buys, fastest_move |
   */
  market_criteria?: "popular_markets" | "bigger_house" | "cheaper_buys" | "fastest_move";
  /**
   * Did the client enter an address in response to "thinking of selling" step?
   */
  may_sell?: string;
  /**
   * **Did the client enter a price point?**
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  price_point_set?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | buy_different_home, invest_in_real_estate, purchase_first_home, explore_for_now, sell_current |
   */
  priority_set?:
    | "buy_different_home"
    | "invest_in_real_estate"
    | "purchase_first_home"
    | "explore_for_now"
    | "sell_current";
}

export interface BuyerPriceTunedProperties {
  /**
   * Application the event is fired from
   */
  event_source?: string;
  /**
   * **On buyer tune panel's CALCULATE tab, client inputs some/any fields to explore with**
   *
   * | Rule | Value |
   * |---|---|
   * | Min Items | 0 |
   * | Max Items | 5 |
   * | Unique Items | true |
   * | Item Type | string |
   *
   * @minItems 0
   * @maxItems 5
   */
  price_calculated?:
    | []
    | [string]
    | [string, string]
    | [string, string, string]
    | [string, string, string, string]
    | [string, string, string, string, string];
  /**
   * On buyer tune panel's GUESS tab, Client updates price point and clicks "Explore with..."
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | click_explore |
   */
  price_guessed?: "click_explore";
}

export interface CarouselInteractionProperties {
  /**
   * Used by generic events to indicate the content of the element firing the interaction. Should be human readable and in English.
   */
  descriptor?: string;
  /**
   * Application the event is fired from
   */
  event_source?: string;
  /**
   * Unique identifier used by generic events that should be used in Custom Events. This will ensure that future changes to a property do not break our tracking queries.
   */
  guid: string;
  /**
   * Used by generic interaction events to determine which section of a page the event is being fired from. This property should always be included so we have enough information to determine which individual component is firing the event on a page. Should be snake_cased.
   */
  ui_context?: string;
}

export interface CashoutOptionExploredProperties {
  /**
   * Describes the cashout option the client explored
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | emergency_fund, home_improvements, add_space, student_loans, credit_card_debt, solar_panels, european_vacation, new_investments |
   */
  cash_out_type:
    | "emergency_fund"
    | "home_improvements"
    | "add_space"
    | "student_loans"
    | "credit_card_debt"
    | "solar_panels"
    | "european_vacation"
    | "new_investments";
  /**
   * Application the event is fired from
   */
  event_source?: string;
}

export interface CheckboxInteractionProperties {
  /**
   * Used by generic interaction events to specify which action was taken on the component that fired an interaction event
   */
  action: string;
  /**
   * Used by generic events to indicate the content of the element firing the interaction. Should be human readable and in English.
   */
  descriptor?: string;
  /**
   * Application the event is fired from
   */
  event_source?: string;
  /**
   * Unique identifier used by generic events that should be used in Custom Events. This will ensure that future changes to a property do not break our tracking queries.
   */
  guid: string;
  /**
   * Used by generic interaction events to specify which page the component firing the interaction event is on
   */
  pathname?: string;
  /**
   * Used by generic interaction events to determine which section of a page the event is being fired from. This property should always be included so we have enough information to determine which individual component is firing the event on a page. Should be snake_cased.
   */
  ui_context?: string;
}

export interface ClientCustomizedProperties {
  /**
   * Ways clients can customize their Homebot
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | confirmed_loan, updated_loan, added_loan, updated_loan_balance, updated_budget, updated_price_point, updated_buying_timeline |
   */
  customization_type:
    | "confirmed_loan"
    | "updated_loan"
    | "added_loan"
    | "updated_loan_balance"
    | "updated_budget"
    | "updated_price_point"
    | "updated_buying_timeline";
  /**
   * Application the event is fired from
   */
  event_source?: string;
}

export interface ClientRequestedInfoProperties {
  /**
   * Describes the digest owner's customer type
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | loan_officer, real_estate_agent, insurance_agent |
   */
  customer_type: "loan_officer" | "real_estate_agent" | "insurance_agent";
  /**
   * Application the event is fired from
   */
  event_source?: string;
  /**
   * The type of request the client sent our customer
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | cma, tca, showing, prequal, instant_offer, should_i_sell, refi, market_temp |
   */
  request_type: "cma" | "tca" | "showing" | "prequal" | "instant_offer" | "should_i_sell" | "refi" | "market_temp";
  /**
   * Used by generic interaction events to determine which section of a page the event is being fired from. This property should always be included so we have enough information to determine which individual component is firing the event on a page. Should be snake_cased.
   */
  ui_context?: string;
}

export interface DiscoverZipsClickedProperties {
  /**
   * Application the event is fired from
   */
  event_source?: string;
}

export interface DrawerInteractionProperties {
  /**
   * Used by generic interaction events to specify which action was taken on the component that fired an interaction event
   */
  action: string;
  /**
   * Used by generic events to indicate the content of the element firing the interaction. Should be human readable and in English.
   */
  descriptor?: string;
  /**
   * Application the event is fired from
   */
  event_source?: string;
  /**
   * Unique identifier used by generic events that should be used in Custom Events. This will ensure that future changes to a property do not break our tracking queries.
   */
  guid: string;
  /**
   * Used by generic interaction events to specify which page the component firing the interaction event is on
   */
  pathname?: string;
  /**
   * Used by generic interaction events to determine which section of a page the event is being fired from. This property should always be included so we have enough information to determine which individual component is firing the event on a page. Should be snake_cased.
   */
  ui_context?: string;
}

export interface ExploreMarketsClickedProperties {
  /**
   * Application the event is fired from
   */
  event_source?: string;
}

export interface ExploreSavedMarketsClickedProperties {
  /**
   * Application the event is fired from
   */
  event_source?: string;
}

export interface ExtraPrincipalAdjustedProperties {
  /**
   * Application the event is fired from
   */
  event_source?: string;
}

export interface HomeAddedProperties {
  /**
   * Application the event is fired from
   */
  event_source?: string;
}

export interface HomeFavoritedProperties {
  /**
   * Application the event is fired from
   */
  event_source?: string;
}

export interface HomebotDigestSharedProperties {
  /**
   * Application the event is fired from
   */
  event_source?: string;
}

export interface HomebotPanelSharedProperties {
  /**
   * Application the event is fired from
   */
  event_source?: string;
  /**
   * How did the client share HB?
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | copy_invite_link, share_on_facebook, send_email_invite, share_via_mobileshare |
   */
  share_method: "copy_invite_link" | "share_on_facebook" | "send_email_invite" | "share_via_mobileshare";
}

export interface HomeownerLeadAddedProperties {
  /**
   * Application the event is fired from
   */
  event_source?: string;
}

export interface InputInteractionProperties {
  action: string;
  /**
   * Used by generic interaction events that specify user input. This simply tells you the length of the content entered as users may be inputting PII or other sensitive data.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  content_length?: number;
  /**
   * Used by generic events to indicate the content of the element firing the interaction. Should be human readable and in English.
   */
  descriptor?: string;
  /**
   * Application the event is fired from
   */
  event_source?: string;
  /**
   * Unique identifier used by generic events that should be used in Custom Events. This will ensure that future changes to a property do not break our tracking queries.
   */
  guid: string;
  pathname: string;
  /**
   * name of route viewed
   */
  route_name?: string;
  /**
   * Used by generic interaction events to determine which section of a page the event is being fired from. This property should always be included so we have enough information to determine which individual component is firing the event on a page. Should be snake_cased.
   */
  ui_context?: string;
  /**
   * does user input pass validators
   */
  valid?: boolean;
  /**
   * validation error message reported on input
   */
  validation_message?: string;
}

export interface InsightsOverviewAdjustedProperties {
  /**
   * Application the event is fired from
   */
  event_source?: string;
  /**
   * What did the client engage with on the Overview tab?
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | you_might_pay, offer_slider, growth_slider, rental_nights, rental_income, useful_reply, not_useful_reply, possible_gain_or_loss |
   */
  overview_panel_clicked?:
    | "you_might_pay"
    | "offer_slider"
    | "growth_slider"
    | "rental_nights"
    | "rental_income"
    | "useful_reply"
    | "not_useful_reply"
    | "possible_gain_or_loss";
}

export interface InsightsViewedProperties {
  /**
   * Describes the buy grade the user is looking at
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | fair_buy, weak_buy, smart_buy |
   */
  buy_score: "fair_buy" | "weak_buy" | "smart_buy";
  /**
   * Application the event is fired from
   */
  event_source?: string;
}

export interface InsuranceReviewedProperties {
  /**
   * Application the event is fired from
   */
  event_source?: string;
  /**
   * How is a client engaging with the insurance module?
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | confirm_insurance, snooze_insurance, more_info, update_policy_details, adjust_policy_date |
   */
  insurance_policy_checked:
    | "confirm_insurance"
    | "snooze_insurance"
    | "more_info"
    | "update_policy_details"
    | "adjust_policy_date";
}

export interface InsuranceRiskViewedProperties {
  /**
   * Application the event is fired from
   */
  event_source?: string;
  /**
   * The risk of overpaying on a home insurance renenwel
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | low, medium, high |
   */
  risk_level: "low" | "medium" | "high";
}

export interface LinkInteractionProperties {
  /**
   * Used by generic interaction events to specify which action was taken on the component that fired an interaction event
   */
  action: string;
  /**
   * Used by generic events to indicate the content of the element firing the interaction. Should be human readable and in English.
   */
  descriptor?: string;
  /**
   * Application the event is fired from
   */
  event_source?: string;
  /**
   * Unique identifier used by generic events that should be used in Custom Events. This will ensure that future changes to a property do not break our tracking queries.
   */
  guid: string;
  /**
   * Used by generic interaction events to specify which page the component firing the interaction event is on
   */
  pathname?: string;
  /**
   * Used by generic interaction events to determine which section of a page the event is being fired from. This property should always be included so we have enough information to determine which individual component is firing the event on a page. Should be snake_cased.
   */
  ui_context?: string;
}

export interface ListingCopiedProperties {
  /**
   * Application the event is fired from
   */
  event_source?: string;
}

export interface ListingSharedViaWebShareApiProperties {
  /**
   * Application the event is fired from
   */
  event_source?: string;
}

export interface ListingViewedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  building_size?: number;
  city?: string;
  /**
   * Application the event is fired from
   */
  event_source?: string;
  mls_number?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  num_baths?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  num_beds?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  num_floors?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  price?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  price_per_sqft?: number;
  property_type?: string;
  /**
   * source that reported the event, ie, native, web
   */
  source?: any;
  /**
   * | Rule | Value |
   * |---|---|
   * | Min Length | 2 |
   * | Max Length | 2 |
   */
  state_or_province?: string;
  status?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 1776 |
   */
  year_built?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Min Length | 5 |
   * | Max Length | 5 |
   * | Regex | /^\d+$/ |
   */
  zip_code?: string;
}

export interface MapModifiedProperties {
  /**
   * Application the event is fired from
   */
  event_source?: string;
  /**
   * The type of map modification the user did
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | map_panned, zoomed_out, zoomed_in |
   */
  search_map_adjustment: "map_panned" | "zoomed_out" | "zoomed_in";
}

export interface MarketTempClickedProperties {
  /**
   * Application the event is fired from
   */
  event_source?: string;
  /**
   * Describes the market temp range the user's home is in
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | cold, cooling_off, balanced, in_demand, in_high_demand |
   */
  market_temp_range: "cold" | "cooling_off" | "balanced" | "in_demand" | "in_high_demand";
}

export interface MarketsExploredProperties {
  /**
   * Application the event is fired from
   */
  event_source?: string;
  /**
   * What menu option did the client click on to specifity seach criteria?
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | popular_markets, bigger_house, cheaper_buys, fastest_move |
   */
  market_criteria?: "popular_markets" | "bigger_house" | "cheaper_buys" | "fastest_move";
  /**
   * Did a user click into a specific market from the main map to explore its details?
   */
  market_explored?: any;
  /**
   * Did a client save / favorite a market in Market Explorer?
   *
   * | Rule | Value |
   * |---|---|
   * | Min Length | 5 |
   * | Max Length | 5 |
   * | Regex | /^\d+$/ |
   */
  market_favorited?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | buy_different_home, invest_in_real_estate, purchase_first_home, explore_for_now, sell_current |
   */
  priority_set?:
    | "buy_different_home"
    | "invest_in_real_estate"
    | "purchase_first_home"
    | "explore_for_now"
    | "sell_current";
}

export interface MenuInteractionProperties {
  /**
   * Used by generic interaction events to specify which action was taken on the component that fired an interaction event
   */
  action: string;
  /**
   * Used by generic events to indicate the content of the element firing the interaction. Should be human readable and in English.
   */
  descriptor?: string;
  /**
   * Application the event is fired from
   */
  event_source?: string;
  /**
   * Unique identifier used by generic events that should be used in Custom Events. This will ensure that future changes to a property do not break our tracking queries.
   */
  guid: string;
  /**
   * Used by generic interaction events to specify which page the component firing the interaction event is on
   */
  pathname?: string;
  /**
   * Used by generic interaction events to determine which section of a page the event is being fired from. This property should always be included so we have enough information to determine which individual component is firing the event on a page. Should be snake_cased.
   */
  ui_context?: string;
}

export interface MenuItemInteractionProperties {
  /**
   * Used by generic interaction events to specify which action was taken on the component that fired an interaction event
   */
  action: string;
  /**
   * Used by generic events to indicate the content of the element firing the interaction. Should be human readable and in English.
   */
  descriptor?: string;
  /**
   * Application the event is fired from
   */
  event_source?: string;
  /**
   * Unique identifier used by generic events that should be used in Custom Events. This will ensure that future changes to a property do not break our tracking queries.
   */
  guid: string;
  /**
   * Used by generic interaction events to specify which page the component firing the interaction event is on
   */
  pathname?: string;
  /**
   * Used by generic interaction events to determine which section of a page the event is being fired from. This property should always be included so we have enough information to determine which individual component is firing the event on a page. Should be snake_cased.
   */
  ui_context?: string;
}

export interface ModuleViewedProperties {
  /**
   * Application the event is fired from
   */
  event_source?: string;
  /**
   * Describes the module content
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | avm, refi, purchase_power, cashout, airbnb, reverse_mortgage, pmi, net_worth, win, home_insurance, principal_interest, pay_extra_principal, assumables, recently_sold, recent_price_drop, concessions, home_highlights, listing_description, smart_move, home_details, similar_homes, estimated_payment_breakdown |
   */
  module_name:
    | "avm"
    | "refi"
    | "purchase_power"
    | "cashout"
    | "airbnb"
    | "reverse_mortgage"
    | "pmi"
    | "net_worth"
    | "win"
    | "home_insurance"
    | "principal_interest"
    | "pay_extra_principal"
    | "assumables"
    | "recently_sold"
    | "recent_price_drop"
    | "concessions"
    | "home_highlights"
    | "listing_description"
    | "smart_move"
    | "home_details"
    | "similar_homes"
    | "estimated_payment_breakdown";
  /**
   * The descending order in which the module was displayed
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   * | Min Value | 1 |
   * | Max Value | 20 |
   */
  module_position?: number;
}

export interface NetWorthExploredProperties {
  /**
   * Application the event is fired from
   */
  event_source?: string;
}

export interface PageViewedProperties {
  /**
   * Application the event is fired from
   */
  event_source?: string;
  /**
   * Pattern of the URL of the page
   */
  page_location: string;
  /**
   * Actual URL of the page including IDs
   */
  page_path?: string;
  /**
   * Name of the page that describes the content
   */
  page_title?: string;
}

export interface PmiCheckedProperties {
  /**
   * Application the event is fired from
   */
  event_source?: string;
  /**
   * How did a client with estimated PMI respond to the PMI quick check?
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | yes, no, unsure |
   */
  pmi_quick_check: "yes" | "no" | "unsure";
}

export interface PmiIndicatedProperties {
  /**
   * Application the event is fired from
   */
  event_source?: string;
  /**
   * PMI status in Homebot for a client
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | not_yet, soon, maybe_now |
   */
  pmi_status: "not_yet" | "soon" | "maybe_now";
}

export interface PmiInfoRequestedProperties {
  /**
   * Application the event is fired from
   */
  event_source?: string;
  /**
   * When the PMI panel is expanded, does a client to learn about
   *
   * * home improvement
   *
   * * principal payment
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | home_improvement, principal_payment |
   */
  inspected_pmi_info?: "home_improvement" | "principal_payment";
}

export interface PmiRemovedProperties {
  /**
   * Application the event is fired from
   */
  event_source?: string;
}

export interface PricePointChangedProperties {
  /**
   * Application the event is fired from
   */
  event_source?: string;
}

export interface QuestionAskedProperties {
  /**
   * When a client DMs their LO/REA about a Homebot topic
   */
  direct_message_topic: string;
  /**
   * Application the event is fired from
   */
  event_source?: string;
  listing_id?: string;
  platform?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Min Length | 5 |
   * | Max Length | 5 |
   * | Regex | /^\d+$/ |
   */
  zip_code?: string;
}

export interface RadioInteractionProperties {
  /**
   * Used by generic interaction events to specify which action was taken on the component that fired an interaction event
   */
  action: string;
  /**
   * Used by generic events to indicate the content of the element firing the interaction. Should be human readable and in English.
   */
  descriptor?: string;
  /**
   * Application the event is fired from
   */
  event_source?: string;
  /**
   * Unique identifier used by generic events that should be used in Custom Events. This will ensure that future changes to a property do not break our tracking queries.
   */
  guid: string;
  /**
   * Used by generic interaction events to specify which page the component firing the interaction event is on
   */
  pathname?: string;
  /**
   * Used by generic interaction events to determine which section of a page the event is being fired from. This property should always be included so we have enough information to determine which individual component is firing the event on a page. Should be snake_cased.
   */
  ui_context?: string;
  /**
   * Used by generic interaction events to determine what value the user has entered or selected
   */
  value?: any;
}

export interface ScrolledIntoViewInteractionProperties {
  /**
   * Used by generic interaction events to specify which action was taken on the component that fired an interaction event
   */
  action: string;
  /**
   * Used by generic events to indicate the content of the element firing the interaction. Should be human readable and in English.
   */
  descriptor?: string;
  /**
   * Unique identifier used by generic events that should be used in Custom Events. This will ensure that future changes to a property do not break our tracking queries.
   */
  guid: string;
  /**
   * Used by generic interaction events to specify which page the component firing the interaction event is on
   */
  pathname?: string;
  /**
   * Used by generic interaction events to determine which section of a page the event is being fired from. This property should always be included so we have enough information to determine which individual component is firing the event on a page. Should be snake_cased.
   */
  ui_context?: string;
}

export interface SearchCompletedProperties {
  /**
   * Application the event is fired from
   */
  event_source?: string;
}

export interface SearchLeadAddedProperties {
  /**
   * Application the event is fired from
   */
  event_source?: string;
  /**
   * **Did someone complete or get stuck in the Search lead capture flow?**
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | click_continue, click_create_account |
   */
  search_lead_flow: "click_continue" | "click_create_account";
}

export interface SearchModifiedProperties {
  /**
   * Application the event is fired from
   */
  event_source?: string;
  /**
   * Describes parameters the client filtered their search by
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | price_max, price_min, bedroom_min, bathroom_min, home_type, sqft_min, sqft_max, construction_year_min, construction_year_max, acres_min, acres_max, has_seller_concession, is_assumable, monthly_payment_min, monthly_payment_max, has_open_houses |
   */
  search_filter_added:
    | "price_max"
    | "price_min"
    | "bedroom_min"
    | "bathroom_min"
    | "home_type"
    | "sqft_min"
    | "sqft_max"
    | "construction_year_min"
    | "construction_year_max"
    | "acres_min"
    | "acres_max"
    | "has_seller_concession"
    | "is_assumable"
    | "monthly_payment_min"
    | "monthly_payment_max"
    | "has_open_houses";
}

export interface SearchSavedProperties {
  /**
   * Application the event is fired from
   */
  event_source?: string;
}

export interface SearchSortProperties {
  /**
   * Application the event is fired from
   */
  event_source?: string;
  /**
   * What order the client sorted their search results by
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | sort_price_low, sort_price_high, sort_sqft_smallest, sort_sqft_largest, sort_year_built_oldest, sort_year_built_newest, sort_acres_largest, sort_acres_smallest, sort_days_on_market |
   */
  search_sort_updated:
    | "sort_price_low"
    | "sort_price_high"
    | "sort_sqft_smallest"
    | "sort_sqft_largest"
    | "sort_year_built_oldest"
    | "sort_year_built_newest"
    | "sort_acres_largest"
    | "sort_acres_smallest"
    | "sort_days_on_market";
}

export interface SelectInteractionProperties {
  /**
   * Used by generic interaction events to specify which action was taken on the component that fired an interaction event
   */
  action: string;
  /**
   * Used by generic events to indicate the content of the element firing the interaction. Should be human readable and in English.
   */
  descriptor?: string;
  /**
   * Application the event is fired from
   */
  event_source?: string;
  /**
   * Unique identifier used by generic events that should be used in Custom Events. This will ensure that future changes to a property do not break our tracking queries.
   */
  guid: string;
  /**
   * Used by generic interaction events to specify which page the component firing the interaction event is on
   */
  pathname?: string;
  /**
   * Used by generic interaction events to determine which section of a page the event is being fired from. This property should always be included so we have enough information to determine which individual component is firing the event on a page. Should be snake_cased.
   */
  ui_context?: string;
  /**
   * Used by generic interaction events to determine what value the user has entered or selected
   */
  value?: any;
}

export interface SliderInteractionProperties {
  /**
   * Used by generic interaction events to specify which action was taken on the component that fired an interaction event
   */
  action: string;
  /**
   * Used by generic events to indicate the content of the element firing the interaction. Should be human readable and in English.
   */
  descriptor?: string;
  /**
   * Unique identifier used by generic events that should be used in Custom Events. This will ensure that future changes to a property do not break our tracking queries.
   */
  guid: string;
  /**
   * Used by generic interaction events that specify numeric ranges. This property specifies the max of the range.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  max?: number;
  /**
   * Used by generic interaction events that specify numeric ranges. This attribute specifies the min of the range.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  min?: number;
  /**
   * name of route viewed
   */
  route_name?: string;
  /**
   * Used by generic interaction events to determine which section of a page the event is being fired from. This property should always be included so we have enough information to determine which individual component is firing the event on a page. Should be snake_cased.
   */
  ui_context?: string;
  /**
   * Used by generic interaction events to determine what value the user has entered or selected
   */
  value?: any;
}

export interface SwitchInteractionProperties {
  /**
   * Used by generic interaction events to specify which action was taken on the component that fired an interaction event
   */
  action: string;
  /**
   * Used by generic events to indicate the content of the element firing the interaction. Should be human readable and in English.
   */
  descriptor?: string;
  /**
   * Unique identifier used by generic events that should be used in Custom Events. This will ensure that future changes to a property do not break our tracking queries.
   */
  guid: string;
  /**
   * Used by generic interaction events to specify which page the component firing the interaction event is on
   */
  pathname?: string;
  /**
   * Used by generic interaction events to determine which section of a page the event is being fired from. This property should always be included so we have enough information to determine which individual component is firing the event on a page. Should be snake_cased.
   */
  ui_context?: string;
}

export interface TabInteractionProperties {
  /**
   * Used by generic interaction events to specify which action was taken on the component that fired an interaction event
   */
  action: string;
  /**
   * Used by generic events to indicate the content of the element firing the interaction. Should be human readable and in English.
   */
  descriptor?: string;
  /**
   * Application the event is fired from
   */
  event_source?: string;
  /**
   * Unique identifier used by generic events that should be used in Custom Events. This will ensure that future changes to a property do not break our tracking queries.
   */
  guid: string;
  /**
   * Used by generic interaction events to specify which page the component firing the interaction event is on
   */
  pathname?: string;
  /**
   * Used by generic interaction events to determine which section of a page the event is being fired from. This property should always be included so we have enough information to determine which individual component is firing the event on a page. Should be snake_cased.
   */
  ui_context?: string;
}

export interface ToggleBuyerReportUnsubscribedProperties {
  /**
   * Application the event is fired from
   */
  event_source?: string;
  /**
   * **Was the toggle positive (subscribe) or negative (unsubscribe) upon user click?**
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | toggle_subscribe, toggle_unsubscribe |
   */
  toggle_click: "toggle_subscribe" | "toggle_unsubscribe";
}

export interface ToggleHomeReportUnsubscribedProperties {
  /**
   * Application the event is fired from
   */
  event_source?: string;
  /**
   * **Was the toggle positive (subscribe) or negative (unsubscribe) upon user click?**
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | toggle_subscribe, toggle_unsubscribe |
   */
  toggle_click: "toggle_subscribe" | "toggle_unsubscribe";
}

export interface ToggleListingsUnsubscribedProperties {
  /**
   * Application the event is fired from
   */
  event_source?: string;
  /**
   * **Was the toggle positive (subscribe) or negative (unsubscribe) upon user click?**
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | toggle_subscribe, toggle_unsubscribe |
   */
  toggle_click: "toggle_subscribe" | "toggle_unsubscribe";
}

export interface ValueTunedProperties {
  /**
   * Application the event is fired from
   */
  event_source?: string;
  /**
   * Describes how a client adjusts their AVM in Homebot
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | tune_increase, tune_decrease |
   */
  tuned_value_direction: "tune_increase" | "tune_decrease";
}

export interface ViewListingsClickedProperties {
  /**
   * Application the event is fired from
   */
  event_source?: string;
}

export interface WebPanelUnsubscribedProperties {
  /**
   * Application the event is fired from
   */
  event_source?: string;
}

export interface WebPreferencesManagedProperties {
  /**
   * Application the event is fired from
   */
  event_source?: string;
}

export interface WebUnsubscribedProperties {
  /**
   * Application the event is fired from
   */
  event_source?: string;
}

export interface WinClickedProperties {
  /**
   * Application the event is fired from
   */
  event_source?: string;
  /**
   * Client WIN CTA type
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | should_you_sell, new_home, equity_smart_search, share, reserve, refi, home_insurance, pmi |
   */
  win_type:
    | "should_you_sell"
    | "new_home"
    | "equity_smart_search"
    | "share"
    | "reserve"
    | "refi"
    | "home_insurance"
    | "pmi";
}

export interface YearsInHomeAdjustedProperties {
  /**
   * Application the event is fired from
   */
  event_source?: string;
}

export class Identify implements BaseEvent {
  event_type = amplitude.Types.SpecialEventType.IDENTIFY;

  constructor(
    public event_properties: IdentifyProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AccordionInteraction implements BaseEvent {
  event_type = 'Accordion Interaction';

  constructor(
    public event_properties: AccordionInteractionProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AirbnbExplored implements BaseEvent {
  event_type = 'Airbnb Explored';

  constructor(
    public event_properties: AirbnbExploredProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AutocompleteInteraction implements BaseEvent {
  event_type = 'Autocomplete Interaction';

  constructor(
    public event_properties: AutocompleteInteractionProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AvmAlertViewed implements BaseEvent {
  event_type = 'AVM Alert Viewed';

  constructor(
    public event_properties: AvmAlertViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AvmFeedback implements BaseEvent {
  event_type = 'AVM Feedback';

  constructor(
    public event_properties: AvmFeedbackProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AvmInfoViewed implements BaseEvent {
  event_type = 'AVM Info Viewed';

  constructor(
    public event_properties?: AvmInfoViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class BudgetSaved implements BaseEvent {
  event_type = 'Budget Saved';

  constructor(
    public event_properties?: BudgetSavedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ButtonInteraction implements BaseEvent {
  event_type = 'Button Interaction';

  constructor(
    public event_properties: ButtonInteractionProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class BuyerLeadAdded implements BaseEvent {
  event_type = 'Buyer Lead Added';

  constructor(
    public event_properties?: BuyerLeadAddedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class BuyerNavigationLinkClicked implements BaseEvent {
  event_type = 'Buyer Navigation Link Clicked';

  constructor(
    public event_properties: BuyerNavigationLinkClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class BuyerOnboarded implements BaseEvent {
  event_type = 'Buyer Onboarded';

  constructor(
    public event_properties?: BuyerOnboardedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class BuyerPriceTuned implements BaseEvent {
  event_type = 'Buyer Price Tuned';

  constructor(
    public event_properties?: BuyerPriceTunedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CarouselInteraction implements BaseEvent {
  event_type = 'Carousel Interaction';

  constructor(
    public event_properties: CarouselInteractionProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CashoutOptionExplored implements BaseEvent {
  event_type = 'Cashout Option Explored';

  constructor(
    public event_properties: CashoutOptionExploredProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CheckboxInteraction implements BaseEvent {
  event_type = 'Checkbox Interaction';

  constructor(
    public event_properties: CheckboxInteractionProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClientCustomized implements BaseEvent {
  event_type = 'Client Customized';

  constructor(
    public event_properties: ClientCustomizedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClientRequestedInfo implements BaseEvent {
  event_type = 'Client Requested Info';

  constructor(
    public event_properties: ClientRequestedInfoProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DiscoverZipsClicked implements BaseEvent {
  event_type = 'Discover Zips Clicked';

  constructor(
    public event_properties?: DiscoverZipsClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DrawerInteraction implements BaseEvent {
  event_type = 'Drawer Interaction';

  constructor(
    public event_properties: DrawerInteractionProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ExploreMarketsClicked implements BaseEvent {
  event_type = 'Explore Markets Clicked';

  constructor(
    public event_properties?: ExploreMarketsClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ExploreSavedMarketsClicked implements BaseEvent {
  event_type = 'Explore Saved Markets Clicked';

  constructor(
    public event_properties?: ExploreSavedMarketsClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ExtraPrincipalAdjusted implements BaseEvent {
  event_type = 'Extra Principal Adjusted';

  constructor(
    public event_properties?: ExtraPrincipalAdjustedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class HomeAdded implements BaseEvent {
  event_type = 'Home Added';

  constructor(
    public event_properties?: HomeAddedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class HomeFavorited implements BaseEvent {
  event_type = 'Home Favorited';

  constructor(
    public event_properties?: HomeFavoritedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class HomebotDigestShared implements BaseEvent {
  event_type = 'Homebot Digest Shared';

  constructor(
    public event_properties?: HomebotDigestSharedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class HomebotPanelShared implements BaseEvent {
  event_type = 'Homebot Panel Shared';

  constructor(
    public event_properties: HomebotPanelSharedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class HomeownerLeadAdded implements BaseEvent {
  event_type = 'Homeowner Lead Added';

  constructor(
    public event_properties?: HomeownerLeadAddedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class InputInteraction implements BaseEvent {
  event_type = 'Input Interaction';

  constructor(
    public event_properties: InputInteractionProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class InsightsOverviewAdjusted implements BaseEvent {
  event_type = 'Insights Overview Adjusted';

  constructor(
    public event_properties?: InsightsOverviewAdjustedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class InsightsViewed implements BaseEvent {
  event_type = 'Insights Viewed';

  constructor(
    public event_properties: InsightsViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class InsuranceReviewed implements BaseEvent {
  event_type = 'Insurance Reviewed';

  constructor(
    public event_properties: InsuranceReviewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class InsuranceRiskViewed implements BaseEvent {
  event_type = 'Insurance Risk Viewed';

  constructor(
    public event_properties: InsuranceRiskViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class LinkInteraction implements BaseEvent {
  event_type = 'Link Interaction';

  constructor(
    public event_properties: LinkInteractionProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ListingCopied implements BaseEvent {
  event_type = 'Listing Copied';

  constructor(
    public event_properties?: ListingCopiedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ListingSharedViaWebShareApi implements BaseEvent {
  event_type = 'Listing Shared Via Web Share API';

  constructor(
    public event_properties?: ListingSharedViaWebShareApiProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ListingViewed implements BaseEvent {
  event_type = 'Listing Viewed';

  constructor(
    public event_properties?: ListingViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class MapModified implements BaseEvent {
  event_type = 'Map Modified';

  constructor(
    public event_properties: MapModifiedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class MarketTempClicked implements BaseEvent {
  event_type = 'Market Temp Clicked';

  constructor(
    public event_properties: MarketTempClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class MarketsExplored implements BaseEvent {
  event_type = 'Markets Explored';

  constructor(
    public event_properties?: MarketsExploredProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class MenuInteraction implements BaseEvent {
  event_type = 'Menu Interaction';

  constructor(
    public event_properties: MenuInteractionProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class MenuItemInteraction implements BaseEvent {
  event_type = 'Menu Item Interaction';

  constructor(
    public event_properties: MenuItemInteractionProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ModuleViewed implements BaseEvent {
  event_type = 'Module Viewed';

  constructor(
    public event_properties: ModuleViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class NetWorthExplored implements BaseEvent {
  event_type = 'Net Worth Explored';

  constructor(
    public event_properties?: NetWorthExploredProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PageViewed implements BaseEvent {
  event_type = 'Page Viewed';

  constructor(
    public event_properties: PageViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PmiChecked implements BaseEvent {
  event_type = 'PMI Checked';

  constructor(
    public event_properties: PmiCheckedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PmiIndicated implements BaseEvent {
  event_type = 'PMI Indicated';

  constructor(
    public event_properties: PmiIndicatedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PmiInfoRequested implements BaseEvent {
  event_type = 'PMI Info Requested';

  constructor(
    public event_properties?: PmiInfoRequestedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PmiRemoved implements BaseEvent {
  event_type = 'PMI Removed';

  constructor(
    public event_properties?: PmiRemovedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PricePointChanged implements BaseEvent {
  event_type = 'Price Point Changed';

  constructor(
    public event_properties?: PricePointChangedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class QuestionAsked implements BaseEvent {
  event_type = 'Question Asked';

  constructor(
    public event_properties: QuestionAskedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class RadioInteraction implements BaseEvent {
  event_type = 'Radio Interaction';

  constructor(
    public event_properties: RadioInteractionProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ScrolledIntoViewInteraction implements BaseEvent {
  event_type = 'Scrolled Into View Interaction';

  constructor(
    public event_properties: ScrolledIntoViewInteractionProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SearchCompleted implements BaseEvent {
  event_type = 'Search Completed';

  constructor(
    public event_properties?: SearchCompletedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SearchLeadAdded implements BaseEvent {
  event_type = 'Search Lead Added';

  constructor(
    public event_properties: SearchLeadAddedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SearchModified implements BaseEvent {
  event_type = 'Search Modified';

  constructor(
    public event_properties: SearchModifiedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SearchSaved implements BaseEvent {
  event_type = 'Search Saved';

  constructor(
    public event_properties?: SearchSavedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SearchSort implements BaseEvent {
  event_type = 'Search Sort';

  constructor(
    public event_properties: SearchSortProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SelectInteraction implements BaseEvent {
  event_type = 'Select Interaction';

  constructor(
    public event_properties: SelectInteractionProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SliderInteraction implements BaseEvent {
  event_type = 'Slider Interaction';

  constructor(
    public event_properties: SliderInteractionProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SwitchInteraction implements BaseEvent {
  event_type = 'Switch Interaction';

  constructor(
    public event_properties: SwitchInteractionProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TabInteraction implements BaseEvent {
  event_type = 'Tab Interaction';

  constructor(
    public event_properties: TabInteractionProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ToggleBuyerReportUnsubscribed implements BaseEvent {
  event_type = 'Toggle Buyer Report Unsubscribed';

  constructor(
    public event_properties: ToggleBuyerReportUnsubscribedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ToggleHomeReportUnsubscribed implements BaseEvent {
  event_type = 'Toggle Home Report Unsubscribed';

  constructor(
    public event_properties: ToggleHomeReportUnsubscribedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ToggleListingsUnsubscribed implements BaseEvent {
  event_type = 'Toggle Listings Unsubscribed';

  constructor(
    public event_properties: ToggleListingsUnsubscribedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ValueTuned implements BaseEvent {
  event_type = 'Value Tuned';

  constructor(
    public event_properties: ValueTunedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewListingsClicked implements BaseEvent {
  event_type = 'View Listings Clicked';

  constructor(
    public event_properties?: ViewListingsClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class WebPanelUnsubscribed implements BaseEvent {
  event_type = 'Web Panel Unsubscribed';

  constructor(
    public event_properties?: WebPanelUnsubscribedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class WebPreferencesManaged implements BaseEvent {
  event_type = 'Web Preferences Managed';

  constructor(
    public event_properties?: WebPreferencesManagedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class WebUnsubscribed implements BaseEvent {
  event_type = 'Web Unsubscribed';

  constructor(
    public event_properties?: WebUnsubscribedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class WinClicked implements BaseEvent {
  event_type = 'WIN Clicked';

  constructor(
    public event_properties: WinClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class YearsInHomeAdjusted implements BaseEvent {
  event_type = 'Years In Home Adjusted';

  constructor(
    public event_properties?: YearsInHomeAdjustedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export type PromiseResult<T> = { promise: Promise<T | void> };

const getVoidPromiseResult = () => ({ promise: Promise.resolve() });

// prettier-ignore
export class Ampli {
  private disabled: boolean = false;
  private amplitude?: BrowserClient;

  get client(): BrowserClient {
    this.isInitializedAndEnabled();
    return this.amplitude!;
  }

  get isLoaded(): boolean {
    return this.amplitude != null;
  }

  private isInitializedAndEnabled(): boolean {
    if (!this.amplitude) {
      console.error('ERROR: Ampli is not yet initialized. Have you called ampli.load() on app start?');
      return false;
    }
    return !this.disabled;
  }

  /**
   * Initialize the Ampli SDK. Call once when your application starts.
   *
   * @param options Configuration options to initialize the Ampli SDK with.
   */
  load(options: LoadOptions): PromiseResult<void> {
    this.disabled = options.disabled ?? false;

    if (this.amplitude) {
      console.warn('WARNING: Ampli is already intialized. Ampli.load() should be called once at application startup.');
      return getVoidPromiseResult();
    }

    let apiKey: string | null = null;
    if (options.client && 'apiKey' in options.client) {
      apiKey = options.client.apiKey;
    } else if ('environment' in options) {
      apiKey = ApiKey[options.environment];
    }

    if (options.client && 'instance' in options.client) {
      this.amplitude = options.client.instance;
    } else if (apiKey) {
      this.amplitude = amplitude.createInstance();
      const configuration = (options.client && 'configuration' in options.client) ? options.client.configuration : {};
      return this.amplitude.init(apiKey, undefined, { ...DefaultConfiguration, ...configuration });
    } else {
      console.error("ERROR: ampli.load() requires 'environment', 'client.apiKey', or 'client.instance'");
    }

    return getVoidPromiseResult();
  }

  /**
   * Identify a user and set user properties.
   *
   * @param userId The user's id.
   * @param properties The user properties.
   * @param options Optional event options.
   */
  identify(
    userId: string | undefined,
    properties: IdentifyProperties,
    options?: EventOptions,
  ): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    if (userId) {
      options = {...options,  user_id: userId};
    }

    const amplitudeIdentify = new amplitude.Identify();
    const eventProperties = properties;
    if (eventProperties != null) {
      for (const [key, value] of Object.entries(eventProperties)) {
        amplitudeIdentify.set(key, value);
      }
    }
    return this.amplitude!.identify(
      amplitudeIdentify,
      options,
    );
  }

 /**
  * Flush the event.
  */
  flush() : PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.flush();
  }

  /**
   * Track event
   *
   * @param event The event to track.
   * @param options Optional event options.
   */
  track(event: Event, options?: EventOptions): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.track(event, undefined, options);
  }

  /**
   * Accordion Interaction
   *
   * [View in Tracking Plan](https://data.amplitude.com/homebot/Homebot%20Tracking%20Plan/events/main/latest/Accordion%20Interaction)
   *
   * Generic event for tracking when an item listed in an Accordion component is interacted with in the Design System
   *
   * @param properties The event's properties (e.g. action)
   * @param options Amplitude event options.
   */
  accordionInteraction(
    properties: AccordionInteractionProperties,
    options?: EventOptions,
  ) {
    return this.track(new AccordionInteraction(properties), options);
  }

  /**
   * Airbnb Explored
   *
   * [View in Tracking Plan](https://data.amplitude.com/homebot/Homebot%20Tracking%20Plan/events/main/latest/Airbnb%20Explored)
   *
   * How did a client engage with Homebot's Airbnb module?
   *
   * Owner: leia@homebot.ai
   *
   * @param properties The event's properties (e.g. airbnb_clicked)
   * @param options Amplitude event options.
   */
  airbnbExplored(
    properties: AirbnbExploredProperties,
    options?: EventOptions,
  ) {
    return this.track(new AirbnbExplored(properties), options);
  }

  /**
   * Autocomplete Interaction
   *
   * [View in Tracking Plan](https://data.amplitude.com/homebot/Homebot%20Tracking%20Plan/events/main/latest/Autocomplete%20Interaction)
   *
   * Generic event for tracking autocomplete interactions in the Design System
   *
   * @param properties The event's properties (e.g. action)
   * @param options Amplitude event options.
   */
  autocompleteInteraction(
    properties: AutocompleteInteractionProperties,
    options?: EventOptions,
  ) {
    return this.track(new AutocompleteInteraction(properties), options);
  }

  /**
   * AVM Alert Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/homebot/Homebot%20Tracking%20Plan/events/main/latest/AVM%20Alert%20Viewed)
   *
   * Client was exposed to 1 of the 4 AVM alerts
   *
   * Owner: leia@homebot.ai
   *
   * @param properties The event's properties (e.g. avm_alert_type)
   * @param options Amplitude event options.
   */
  avmAlertViewed(
    properties: AvmAlertViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new AvmAlertViewed(properties), options);
  }

  /**
   * AVM Feedback
   *
   * [View in Tracking Plan](https://data.amplitude.com/homebot/Homebot%20Tracking%20Plan/events/main/latest/AVM%20Feedback)
   *
   * Client provided feedback by engaging with an AVM alert
   *
   * Owner: leia@homebot.ai
   *
   * @param properties The event's properties (e.g. avm_alert_response)
   * @param options Amplitude event options.
   */
  avmFeedback(
    properties: AvmFeedbackProperties,
    options?: EventOptions,
  ) {
    return this.track(new AvmFeedback(properties), options);
  }

  /**
   * AVM Info Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/homebot/Homebot%20Tracking%20Plan/events/main/latest/AVM%20Info%20Viewed)
   *
   * Client clicked "Learn about home estimates” button
   *
   * Owner: leia@homebot.ai
   *
   * @param properties The event's properties (e.g. event_source)
   * @param options Amplitude event options.
   */
  avmInfoViewed(
    properties?: AvmInfoViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new AvmInfoViewed(properties), options);
  }

  /**
   * Budget Saved
   *
   * [View in Tracking Plan](https://data.amplitude.com/homebot/Homebot%20Tracking%20Plan/events/main/latest/Budget%20Saved)
   *
   * Client added or edited their budget
   *
   * Owner: leia@homebot.ai
   *
   * @param properties The event's properties (e.g. budget_input)
   * @param options Amplitude event options.
   */
  budgetSaved(
    properties?: BudgetSavedProperties,
    options?: EventOptions,
  ) {
    return this.track(new BudgetSaved(properties), options);
  }

  /**
   * Button Interaction
   *
   * [View in Tracking Plan](https://data.amplitude.com/homebot/Homebot%20Tracking%20Plan/events/main/latest/Button%20Interaction)
   *
   * Generic event for tracking button interactions in the Design System
   *
   * Owner: David Daily
   *
   * @param properties The event's properties (e.g. action)
   * @param options Amplitude event options.
   */
  buttonInteraction(
    properties: ButtonInteractionProperties,
    options?: EventOptions,
  ) {
    return this.track(new ButtonInteraction(properties), options);
  }

  /**
   * Buyer Lead Added
   *
   * [View in Tracking Plan](https://data.amplitude.com/homebot/Homebot%20Tracking%20Plan/events/main/latest/Buyer%20Lead%20Added)
   *
   * Did a buyer PURL user input an email and click "Go" to unlock their Homebot? \[Trigger is Go click\]
   *
   * Owner: leia@homebot.ai
   *
   * @param properties The event's properties (e.g. event_source)
   * @param options Amplitude event options.
   */
  buyerLeadAdded(
    properties?: BuyerLeadAddedProperties,
    options?: EventOptions,
  ) {
    return this.track(new BuyerLeadAdded(properties), options);
  }

  /**
   * Buyer Navigation Link Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/homebot/Homebot%20Tracking%20Plan/events/main/latest/Buyer%20Navigation%20Link%20Clicked)
   *
   * Captures **the navigation link a buyer clicked on** in both the desktop and mobile buyer navigation experiences
   *
   * Owner: leia@homebot.ai
   *
   * @param properties The event's properties (e.g. buyer_navigation_link)
   * @param options Amplitude event options.
   */
  buyerNavigationLinkClicked(
    properties: BuyerNavigationLinkClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new BuyerNavigationLinkClicked(properties), options);
  }

  /**
   * Buyer Onboarded
   *
   * [View in Tracking Plan](https://data.amplitude.com/homebot/Homebot%20Tracking%20Plan/events/main/latest/Buyer%20Onboarded)
   *
   * For a client using Market Explorer for the first time, how do they engage with the onboarding menu?
   *
   * Owner: leia@homebot.ai
   *
   * @param properties The event's properties (e.g. city)
   * @param options Amplitude event options.
   */
  buyerOnboarded(
    properties?: BuyerOnboardedProperties,
    options?: EventOptions,
  ) {
    return this.track(new BuyerOnboarded(properties), options);
  }

  /**
   * Buyer Price Tuned
   *
   * [View in Tracking Plan](https://data.amplitude.com/homebot/Homebot%20Tracking%20Plan/events/main/latest/Buyer%20Price%20Tuned)
   *
   * **How does a buyer client update their price point in the buyer report?**
   *
   * Owner: leia@homebot.ai
   *
   * @param properties The event's properties (e.g. event_source)
   * @param options Amplitude event options.
   */
  buyerPriceTuned(
    properties?: BuyerPriceTunedProperties,
    options?: EventOptions,
  ) {
    return this.track(new BuyerPriceTuned(properties), options);
  }

  /**
   * Carousel Interaction
   *
   * [View in Tracking Plan](https://data.amplitude.com/homebot/Homebot%20Tracking%20Plan/events/main/latest/Carousel%20Interaction)
   *
   * Generic event for tracking carousel interaction
   *
   * @param properties The event's properties (e.g. descriptor)
   * @param options Amplitude event options.
   */
  carouselInteraction(
    properties: CarouselInteractionProperties,
    options?: EventOptions,
  ) {
    return this.track(new CarouselInteraction(properties), options);
  }

  /**
   * Cashout Option Explored
   *
   * [View in Tracking Plan](https://data.amplitude.com/homebot/Homebot%20Tracking%20Plan/events/main/latest/Cashout%20Option%20Explored)
   *
   * This event's property (cash*\_*out_type) describes the cashout option the client explored
   *
   * Owner: leia@homebot.ai
   *
   * @param properties The event's properties (e.g. cash_out_type)
   * @param options Amplitude event options.
   */
  cashoutOptionExplored(
    properties: CashoutOptionExploredProperties,
    options?: EventOptions,
  ) {
    return this.track(new CashoutOptionExplored(properties), options);
  }

  /**
   * Checkbox Interaction
   *
   * [View in Tracking Plan](https://data.amplitude.com/homebot/Homebot%20Tracking%20Plan/events/main/latest/Checkbox%20Interaction)
   *
   * Generic event for tracking checkbox interactions in the Design System
   *
   * Owner: David Daily
   *
   * @param properties The event's properties (e.g. action)
   * @param options Amplitude event options.
   */
  checkboxInteraction(
    properties: CheckboxInteractionProperties,
    options?: EventOptions,
  ) {
    return this.track(new CheckboxInteraction(properties), options);
  }

  /**
   * Client Customized
   *
   * [View in Tracking Plan](https://data.amplitude.com/homebot/Homebot%20Tracking%20Plan/events/main/latest/Client%20Customized)
   *
   * The client updated or confirmed important fields that customized their experience
   *
   * Owner: leia@homebot.ai
   *
   * @param properties The event's properties (e.g. customization_type)
   * @param options Amplitude event options.
   */
  clientCustomized(
    properties: ClientCustomizedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClientCustomized(properties), options);
  }

  /**
   * Client Requested Info
   *
   * [View in Tracking Plan](https://data.amplitude.com/homebot/Homebot%20Tracking%20Plan/events/main/latest/Client%20Requested%20Info)
   *
   * The client asked for important information that the customer needs to respond to
   *
   * Owner: Carey Clark
   *
   * @param properties The event's properties (e.g. customer_type)
   * @param options Amplitude event options.
   */
  clientRequestedInfo(
    properties: ClientRequestedInfoProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClientRequestedInfo(properties), options);
  }

  /**
   * Discover Zips Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/homebot/Homebot%20Tracking%20Plan/events/main/latest/Discover%20Zips%20Clicked)
   *
   * **Did a buyer client click "Discover more zip codes" from the buyer report to nav into Market Explorer?**
   *
   * Owner: leia@homebot.ai
   *
   * @param properties The event's properties (e.g. event_source)
   * @param options Amplitude event options.
   */
  discoverZipsClicked(
    properties?: DiscoverZipsClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DiscoverZipsClicked(properties), options);
  }

  /**
   * Drawer Interaction
   *
   * [View in Tracking Plan](https://data.amplitude.com/homebot/Homebot%20Tracking%20Plan/events/main/latest/Drawer%20Interaction)
   *
   * **Generic event for tracking Drawer interactions in the Design System**
   *
   * @param properties The event's properties (e.g. action)
   * @param options Amplitude event options.
   */
  drawerInteraction(
    properties: DrawerInteractionProperties,
    options?: EventOptions,
  ) {
    return this.track(new DrawerInteraction(properties), options);
  }

  /**
   * Explore Markets Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/homebot/Homebot%20Tracking%20Plan/events/main/latest/Explore%20Markets%20Clicked)
   *
   * **Did a buyer client click "Explore all markets" from the buyer report to nav into Market Explorer?**
   *
   * Owner: leia@homebot.ai
   *
   * @param properties The event's properties (e.g. event_source)
   * @param options Amplitude event options.
   */
  exploreMarketsClicked(
    properties?: ExploreMarketsClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ExploreMarketsClicked(properties), options);
  }

  /**
   * Explore Saved Markets Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/homebot/Homebot%20Tracking%20Plan/events/main/latest/Explore%20Saved%20Markets%20Clicked)
   *
   * **Did a buyer client click "View" by a saved/favorited zip from the buyer report to nav into Market Explorer?**
   *
   * Owner: leia@homebot.ai
   *
   * @param properties The event's properties (e.g. event_source)
   * @param options Amplitude event options.
   */
  exploreSavedMarketsClicked(
    properties?: ExploreSavedMarketsClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ExploreSavedMarketsClicked(properties), options);
  }

  /**
   * Extra Principal Adjusted
   *
   * [View in Tracking Plan](https://data.amplitude.com/homebot/Homebot%20Tracking%20Plan/events/main/latest/Extra%20Principal%20Adjusted)
   *
   * Client adjusted extra monthly principal dropdown
   *
   * Owner: leia@homebot.ai
   *
   * @param properties The event's properties (e.g. event_source)
   * @param options Amplitude event options.
   */
  extraPrincipalAdjusted(
    properties?: ExtraPrincipalAdjustedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ExtraPrincipalAdjusted(properties), options);
  }

  /**
   * Home Added
   *
   * [View in Tracking Plan](https://data.amplitude.com/homebot/Homebot%20Tracking%20Plan/events/main/latest/Home%20Added)
   *
   * Client self added a new home via /home/new
   *
   * Owner: leia@homebot.ai
   *
   * @param properties The event's properties (e.g. event_source)
   * @param options Amplitude event options.
   */
  homeAdded(
    properties?: HomeAddedProperties,
    options?: EventOptions,
  ) {
    return this.track(new HomeAdded(properties), options);
  }

  /**
   * Home Favorited
   *
   * [View in Tracking Plan](https://data.amplitude.com/homebot/Homebot%20Tracking%20Plan/events/main/latest/Home%20Favorited)
   *
   * Client favorited a listing
   *
   * Owner: leia@homebot.ai
   *
   * @param properties The event's properties (e.g. event_source)
   * @param options Amplitude event options.
   */
  homeFavorited(
    properties?: HomeFavoritedProperties,
    options?: EventOptions,
  ) {
    return this.track(new HomeFavorited(properties), options);
  }

  /**
   * Homebot Digest Shared
   *
   * [View in Tracking Plan](https://data.amplitude.com/homebot/Homebot%20Tracking%20Plan/events/main/latest/Homebot%20Digest%20Shared)
   *
   * Did a client click on the share button from the HO report digest page to expand the panel?
   *
   * Owner: leia@homebot.ai
   *
   * @param properties The event's properties (e.g. event_source)
   * @param options Amplitude event options.
   */
  homebotDigestShared(
    properties?: HomebotDigestSharedProperties,
    options?: EventOptions,
  ) {
    return this.track(new HomebotDigestShared(properties), options);
  }

  /**
   * Homebot Panel Shared
   *
   * [View in Tracking Plan](https://data.amplitude.com/homebot/Homebot%20Tracking%20Plan/events/main/latest/Homebot%20Panel%20Shared)
   *
   * Did a client successfully Share Homebot from the share panel?
   *
   * Owner: leia@homebot.ai
   *
   * @param properties The event's properties (e.g. event_source)
   * @param options Amplitude event options.
   */
  homebotPanelShared(
    properties: HomebotPanelSharedProperties,
    options?: EventOptions,
  ) {
    return this.track(new HomebotPanelShared(properties), options);
  }

  /**
   * Homeowner Lead Added
   *
   * [View in Tracking Plan](https://data.amplitude.com/homebot/Homebot%20Tracking%20Plan/events/main/latest/Homeowner%20Lead%20Added)
   *
   * **Did a HO PURL user input an email and click "Go" to unlock their Homebot? \[Trigger is Go Click\]**
   *
   * Owner: leia@homebot.ai
   *
   * @param properties The event's properties (e.g. event_source)
   * @param options Amplitude event options.
   */
  homeownerLeadAdded(
    properties?: HomeownerLeadAddedProperties,
    options?: EventOptions,
  ) {
    return this.track(new HomeownerLeadAdded(properties), options);
  }

  /**
   * Input Interaction
   *
   * [View in Tracking Plan](https://data.amplitude.com/homebot/Homebot%20Tracking%20Plan/events/main/latest/Input%20Interaction)
   *
   * Generic event for tracking input interactions in the Design System
   *
   * Owner: David Daily
   *
   * @param properties The event's properties (e.g. action)
   * @param options Amplitude event options.
   */
  inputInteraction(
    properties: InputInteractionProperties,
    options?: EventOptions,
  ) {
    return this.track(new InputInteraction(properties), options);
  }

  /**
   * Insights Overview Adjusted
   *
   * [View in Tracking Plan](https://data.amplitude.com/homebot/Homebot%20Tracking%20Plan/events/main/latest/Insights%20Overview%20Adjusted)
   *
   * How did a client engage with the Overview information in the finanical insights panel on a home listing?
   *
   * Owner: leia@homebot.ai
   *
   * @param properties The event's properties (e.g. event_source)
   * @param options Amplitude event options.
   */
  insightsOverviewAdjusted(
    properties?: InsightsOverviewAdjustedProperties,
    options?: EventOptions,
  ) {
    return this.track(new InsightsOverviewAdjusted(properties), options);
  }

  /**
   * Insights Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/homebot/Homebot%20Tracking%20Plan/events/main/latest/Insights%20Viewed)
   *
   * Client clicks on button to view personal financial insights on a listing in Homebot Home Search
   *
   * Owner: leia@homebot.ai
   *
   * @param properties The event's properties (e.g. buy_score)
   * @param options Amplitude event options.
   */
  insightsViewed(
    properties: InsightsViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new InsightsViewed(properties), options);
  }

  /**
   * Insurance Reviewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/homebot/Homebot%20Tracking%20Plan/events/main/latest/Insurance%20Reviewed)
   *
   * When clients click the "More information on this" button on their insurance module.
   *
   * Owner: leia@homebot.ai
   *
   * @param properties The event's properties (e.g. event_source)
   * @param options Amplitude event options.
   */
  insuranceReviewed(
    properties: InsuranceReviewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new InsuranceReviewed(properties), options);
  }

  /**
   * Insurance Risk Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/homebot/Homebot%20Tracking%20Plan/events/main/latest/Insurance%20Risk%20Viewed)
   *
   * When a client views the home insurance module, we capture the risk level of overpaying on a homeinsurance renewel.
   *
   * Owner: Bret Doucette
   *
   * @param properties The event's properties (e.g. event_source)
   * @param options Amplitude event options.
   */
  insuranceRiskViewed(
    properties: InsuranceRiskViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new InsuranceRiskViewed(properties), options);
  }

  /**
   * Link Interaction
   *
   * [View in Tracking Plan](https://data.amplitude.com/homebot/Homebot%20Tracking%20Plan/events/main/latest/Link%20Interaction)
   *
   * Generic event for tracking link interactions in the Design System
   *
   * Owner: David Daily
   *
   * @param properties The event's properties (e.g. action)
   * @param options Amplitude event options.
   */
  linkInteraction(
    properties: LinkInteractionProperties,
    options?: EventOptions,
  ) {
    return this.track(new LinkInteraction(properties), options);
  }

  /**
   * Listing Copied
   *
   * [View in Tracking Plan](https://data.amplitude.com/homebot/Homebot%20Tracking%20Plan/events/main/latest/Listing%20Copied)
   *
   * Track when a user clicks the ‘copy link’ icon on listing gallery cards.  
   *
   *  Icon shown to clients depends on using a browser capable of native sharing. This event will track browsers that **do not** support webshare.
   *
   * Owner: leia@homebot.ai
   *
   * @param properties The event's properties (e.g. event_source)
   * @param options Amplitude event options.
   */
  listingCopied(
    properties?: ListingCopiedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ListingCopied(properties), options);
  }

  /**
   * Listing Shared Via Web Share API
   *
   * [View in Tracking Plan](https://data.amplitude.com/homebot/Homebot%20Tracking%20Plan/events/main/latest/Listing%20Shared%20Via%20Web%20Share%20API)
   *
   * Track when a user clicks the ‘share’ icon on listing gallery cards.  Icon shown to clients depends on using a browser capable of native sharing. This event will track browsers that **do** support webshare.
   *
   * Owner: leia@homebot.ai
   *
   * @param properties The event's properties (e.g. event_source)
   * @param options Amplitude event options.
   */
  listingSharedViaWebShareApi(
    properties?: ListingSharedViaWebShareApiProperties,
    options?: EventOptions,
  ) {
    return this.track(new ListingSharedViaWebShareApi(properties), options);
  }

  /**
   * Listing Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/homebot/Homebot%20Tracking%20Plan/events/main/latest/Listing%20Viewed)
   *
   * Client clicked to view a listing in HB Home Search
   *
   * Owner: leia@homebot.ai
   *
   * @param properties The event's properties (e.g. building_size)
   * @param options Amplitude event options.
   */
  listingViewed(
    properties?: ListingViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ListingViewed(properties), options);
  }

  /**
   * Map Modified
   *
   * [View in Tracking Plan](https://data.amplitude.com/homebot/Homebot%20Tracking%20Plan/events/main/latest/Map%20Modified)
   *
   * Client using Home Search adjusted the map to view listings
   *
   * Owner: leia@homebot.ai
   *
   * @param properties The event's properties (e.g. event_source)
   * @param options Amplitude event options.
   */
  mapModified(
    properties: MapModifiedProperties,
    options?: EventOptions,
  ) {
    return this.track(new MapModified(properties), options);
  }

  /**
   * Market Temp Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/homebot/Homebot%20Tracking%20Plan/events/main/latest/Market%20Temp%20Clicked)
   *
   * Client clicked CTA to access the Your Market panel
   *
   * Owner: Martha Bawn
   *
   * @param properties The event's properties (e.g. event_source)
   * @param options Amplitude event options.
   */
  marketTempClicked(
    properties: MarketTempClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new MarketTempClicked(properties), options);
  }

  /**
   * Markets Explored
   *
   * [View in Tracking Plan](https://data.amplitude.com/homebot/Homebot%20Tracking%20Plan/events/main/latest/Markets%20Explored)
   *
   * **How does a client engage with Market Explorer (HB buyer experience)?**
   *
   * Owner: leia@homebot.ai
   *
   * @param properties The event's properties (e.g. event_source)
   * @param options Amplitude event options.
   */
  marketsExplored(
    properties?: MarketsExploredProperties,
    options?: EventOptions,
  ) {
    return this.track(new MarketsExplored(properties), options);
  }

  /**
   * Menu Interaction
   *
   * [View in Tracking Plan](https://data.amplitude.com/homebot/Homebot%20Tracking%20Plan/events/main/latest/Menu%20Interaction)
   *
   * Generic event for tracking Menu interactions in the Design System
   *
   * @param properties The event's properties (e.g. action)
   * @param options Amplitude event options.
   */
  menuInteraction(
    properties: MenuInteractionProperties,
    options?: EventOptions,
  ) {
    return this.track(new MenuInteraction(properties), options);
  }

  /**
   * Menu Item Interaction
   *
   * [View in Tracking Plan](https://data.amplitude.com/homebot/Homebot%20Tracking%20Plan/events/main/latest/Menu%20Item%20Interaction)
   *
   * Generic event for tracking when an item listed in the Menu component is interacted with in the Design System
   *
   * @param properties The event's properties (e.g. action)
   * @param options Amplitude event options.
   */
  menuItemInteraction(
    properties: MenuItemInteractionProperties,
    options?: EventOptions,
  ) {
    return this.track(new MenuItemInteraction(properties), options);
  }

  /**
   * Module Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/homebot/Homebot%20Tracking%20Plan/events/main/latest/Module%20Viewed)
   *
   * Client viewed a specific module
   *
   * Owner: Carey Clark
   *
   * @param properties The event's properties (e.g. event_source)
   * @param options Amplitude event options.
   */
  moduleViewed(
    properties: ModuleViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ModuleViewed(properties), options);
  }

  /**
   * Net Worth Explored
   *
   * [View in Tracking Plan](https://data.amplitude.com/homebot/Homebot%20Tracking%20Plan/events/main/latest/Net%20Worth%20Explored)
   *
   * User clicked 'how is this calculated' below their net worth amount
   *
   * Owner: leia@homebot.ai
   *
   * @param properties The event's properties (e.g. event_source)
   * @param options Amplitude event options.
   */
  netWorthExplored(
    properties?: NetWorthExploredProperties,
    options?: EventOptions,
  ) {
    return this.track(new NetWorthExplored(properties), options);
  }

  /**
   * Page Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/homebot/Homebot%20Tracking%20Plan/events/main/latest/Page%20Viewed)
   *
   * Client viewed a specific page
   *
   * Owner: Martha Bawn
   *
   * @param properties The event's properties (e.g. event_source)
   * @param options Amplitude event options.
   */
  pageViewed(
    properties: PageViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PageViewed(properties), options);
  }

  /**
   * PMI Checked
   *
   * [View in Tracking Plan](https://data.amplitude.com/homebot/Homebot%20Tracking%20Plan/events/main/latest/PMI%20Checked)
   *
   * For clients with estimated PMI that engage with the PMI quick check: Yes, No, or Unsure
   *
   * Owner: leia@homebot.ai
   *
   * @param properties The event's properties (e.g. event_source)
   * @param options Amplitude event options.
   */
  pmiChecked(
    properties: PmiCheckedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PmiChecked(properties), options);
  }

  /**
   * PMI Indicated
   *
   * [View in Tracking Plan](https://data.amplitude.com/homebot/Homebot%20Tracking%20Plan/events/main/latest/PMI%20Indicated)
   *
   * Do clients see a red (not yet), orange (soon), or green (maybe now) PMI indication in their Homebot?
   *
   * Owner: leia@homebot.ai
   *
   * @param properties The event's properties (e.g. event_source)
   * @param options Amplitude event options.
   */
  pmiIndicated(
    properties: PmiIndicatedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PmiIndicated(properties), options);
  }

  /**
   * PMI Info Requested
   *
   * [View in Tracking Plan](https://data.amplitude.com/homebot/Homebot%20Tracking%20Plan/events/main/latest/PMI%20Info%20Requested)
   *
   * In our PMI module, trigger is when the Tell me more button is clicked to expand the info panel.
   *
   * Owner: leia@homebot.ai
   *
   * @param properties The event's properties (e.g. event_source)
   * @param options Amplitude event options.
   */
  pmiInfoRequested(
    properties?: PmiInfoRequestedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PmiInfoRequested(properties), options);
  }

  /**
   * PMI Removed
   *
   * [View in Tracking Plan](https://data.amplitude.com/homebot/Homebot%20Tracking%20Plan/events/main/latest/PMI%20Removed)
   *
   * In our PMI module, trigger is when the "Ask About PMI Removal" button is clicked to DM the customer providing Homebot to their client.
   *
   * Owner: leia@homebot.ai
   *
   * @param properties The event's properties (e.g. event_source)
   * @param options Amplitude event options.
   */
  pmiRemoved(
    properties?: PmiRemovedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PmiRemoved(properties), options);
  }

  /**
   * Price Point Changed
   *
   * [View in Tracking Plan](https://data.amplitude.com/homebot/Homebot%20Tracking%20Plan/events/main/latest/Price%20Point%20Changed)
   *
   * Did a client click on the "Change" button by their price point in the buy report to adjust it?
   *
   * Owner: leia@homebot.ai
   *
   * @param properties The event's properties (e.g. event_source)
   * @param options Amplitude event options.
   */
  pricePointChanged(
    properties?: PricePointChangedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PricePointChanged(properties), options);
  }

  /**
   * Question Asked
   *
   * [View in Tracking Plan](https://data.amplitude.com/homebot/Homebot%20Tracking%20Plan/events/main/latest/Question%20Asked)
   *
   * Client sends a direct message
   *
   * Owner: leia@homebot.ai
   *
   * @param properties The event's properties (e.g. direct_message_topic)
   * @param options Amplitude event options.
   */
  questionAsked(
    properties: QuestionAskedProperties,
    options?: EventOptions,
  ) {
    return this.track(new QuestionAsked(properties), options);
  }

  /**
   * Radio Interaction
   *
   * [View in Tracking Plan](https://data.amplitude.com/homebot/Homebot%20Tracking%20Plan/events/main/latest/Radio%20Interaction)
   *
   * Generic event for tracking radio button interactions in the Design System
   *
   * @param properties The event's properties (e.g. action)
   * @param options Amplitude event options.
   */
  radioInteraction(
    properties: RadioInteractionProperties,
    options?: EventOptions,
  ) {
    return this.track(new RadioInteraction(properties), options);
  }

  /**
   * Scrolled Into View Interaction
   *
   * [View in Tracking Plan](https://data.amplitude.com/homebot/Homebot%20Tracking%20Plan/events/main/latest/Scrolled%20Into%20View%20Interaction)
   *
   * Owner: Turner Anderson
   *
   * @param properties The event's properties (e.g. action)
   * @param options Amplitude event options.
   */
  scrolledIntoViewInteraction(
    properties: ScrolledIntoViewInteractionProperties,
    options?: EventOptions,
  ) {
    return this.track(new ScrolledIntoViewInteraction(properties), options);
  }

  /**
   * Search Completed
   *
   * [View in Tracking Plan](https://data.amplitude.com/homebot/Homebot%20Tracking%20Plan/events/main/latest/Search%20Completed)
   *
   * Client input a city, county, or zip to search for home listings
   *
   * Owner: leia@homebot.ai
   *
   * @param properties The event's properties (e.g. event_source)
   * @param options Amplitude event options.
   */
  searchCompleted(
    properties?: SearchCompletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SearchCompleted(properties), options);
  }

  /**
   * Search Lead Added
   *
   * [View in Tracking Plan](https://data.amplitude.com/homebot/Homebot%20Tracking%20Plan/events/main/latest/Search%20Lead%20Added)
   *
   * **Did a new Search user input their credentials and click "Create an account" to become a lead?**
   *
   * Owner: leia@homebot.ai
   *
   * @param properties The event's properties (e.g. event_source)
   * @param options Amplitude event options.
   */
  searchLeadAdded(
    properties: SearchLeadAddedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SearchLeadAdded(properties), options);
  }

  /**
   * Search Modified
   *
   * [View in Tracking Plan](https://data.amplitude.com/homebot/Homebot%20Tracking%20Plan/events/main/latest/Search%20Modified)
   *
   * Client modified home search results using search filters
   *
   * Owner: Carey Clark
   *
   * @param properties The event's properties (e.g. event_source)
   * @param options Amplitude event options.
   */
  searchModified(
    properties: SearchModifiedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SearchModified(properties), options);
  }

  /**
   * Search Saved
   *
   * [View in Tracking Plan](https://data.amplitude.com/homebot/Homebot%20Tracking%20Plan/events/main/latest/Search%20Saved)
   *
   * Client saved their search
   *
   * Owner: leia@homebot.ai
   *
   * @param properties The event's properties (e.g. event_source)
   * @param options Amplitude event options.
   */
  searchSaved(
    properties?: SearchSavedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SearchSaved(properties), options);
  }

  /**
   * Search Sort
   *
   * [View in Tracking Plan](https://data.amplitude.com/homebot/Homebot%20Tracking%20Plan/events/main/latest/Search%20Sort)
   *
   * Client sorted their listings search results
   *
   * Owner: leia@homebot.ai
   *
   * @param properties The event's properties (e.g. event_source)
   * @param options Amplitude event options.
   */
  searchSort(
    properties: SearchSortProperties,
    options?: EventOptions,
  ) {
    return this.track(new SearchSort(properties), options);
  }

  /**
   * Select Interaction
   *
   * [View in Tracking Plan](https://data.amplitude.com/homebot/Homebot%20Tracking%20Plan/events/main/latest/Select%20Interaction)
   *
   * Generic event for tracking select interactions in the Design System
   *
   * Owner: David Daily
   *
   * @param properties The event's properties (e.g. action)
   * @param options Amplitude event options.
   */
  selectInteraction(
    properties: SelectInteractionProperties,
    options?: EventOptions,
  ) {
    return this.track(new SelectInteraction(properties), options);
  }

  /**
   * Slider Interaction
   *
   * [View in Tracking Plan](https://data.amplitude.com/homebot/Homebot%20Tracking%20Plan/events/main/latest/Slider%20Interaction)
   *
   * Generic event for tracking slider interactions in the Design System
   *
   * Owner: David Daily
   *
   * @param properties The event's properties (e.g. action)
   * @param options Amplitude event options.
   */
  sliderInteraction(
    properties: SliderInteractionProperties,
    options?: EventOptions,
  ) {
    return this.track(new SliderInteraction(properties), options);
  }

  /**
   * Switch Interaction
   *
   * [View in Tracking Plan](https://data.amplitude.com/homebot/Homebot%20Tracking%20Plan/events/main/latest/Switch%20Interaction)
   *
   * Generic event for tracking Switch interactions in the Design System
   *
   * @param properties The event's properties (e.g. action)
   * @param options Amplitude event options.
   */
  switchInteraction(
    properties: SwitchInteractionProperties,
    options?: EventOptions,
  ) {
    return this.track(new SwitchInteraction(properties), options);
  }

  /**
   * Tab Interaction
   *
   * [View in Tracking Plan](https://data.amplitude.com/homebot/Homebot%20Tracking%20Plan/events/main/latest/Tab%20Interaction)
   *
   * Generic event for tracking tab interactions in the Design System
   *
   * @param properties The event's properties (e.g. action)
   * @param options Amplitude event options.
   */
  tabInteraction(
    properties: TabInteractionProperties,
    options?: EventOptions,
  ) {
    return this.track(new TabInteraction(properties), options);
  }

  /**
   * Toggle Buyer Report Unsubscribed
   *
   * [View in Tracking Plan](https://data.amplitude.com/homebot/Homebot%20Tracking%20Plan/events/main/latest/Toggle%20Buyer%20Report%20Unsubscribed)
   *
   * **Client clicked on the "your buyer reports" toggle from the web manage preference panel**
   *
   * Owner: leia@homebot.ai
   *
   * @param properties The event's properties (e.g. event_source)
   * @param options Amplitude event options.
   */
  toggleBuyerReportUnsubscribed(
    properties: ToggleBuyerReportUnsubscribedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ToggleBuyerReportUnsubscribed(properties), options);
  }

  /**
   * Toggle Home Report Unsubscribed
   *
   * [View in Tracking Plan](https://data.amplitude.com/homebot/Homebot%20Tracking%20Plan/events/main/latest/Toggle%20Home%20Report%20Unsubscribed)
   *
   * **Client clicked on the "home value updates" toggle from the web manage preference panel**
   *
   * Owner: leia@homebot.ai
   *
   * @param properties The event's properties (e.g. event_source)
   * @param options Amplitude event options.
   */
  toggleHomeReportUnsubscribed(
    properties: ToggleHomeReportUnsubscribedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ToggleHomeReportUnsubscribed(properties), options);
  }

  /**
   * Toggle Listings Unsubscribed
   *
   * [View in Tracking Plan](https://data.amplitude.com/homebot/Homebot%20Tracking%20Plan/events/main/latest/Toggle%20Listings%20Unsubscribed)
   *
   * **Client clicked on the "listings update" toggle from the web manage preference panel**
   *
   * Owner: leia@homebot.ai
   *
   * @param properties The event's properties (e.g. event_source)
   * @param options Amplitude event options.
   */
  toggleListingsUnsubscribed(
    properties: ToggleListingsUnsubscribedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ToggleListingsUnsubscribed(properties), options);
  }

  /**
   * Value Tuned
   *
   * [View in Tracking Plan](https://data.amplitude.com/homebot/Homebot%20Tracking%20Plan/events/main/latest/Value%20Tuned)
   *
   * Client tuned their value higher or lower
   *
   * Owner: leia@homebot.ai
   *
   * @param properties The event's properties (e.g. event_source)
   * @param options Amplitude event options.
   */
  valueTuned(
    properties: ValueTunedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ValueTuned(properties), options);
  }

  /**
   * View Listings Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/homebot/Homebot%20Tracking%20Plan/events/main/latest/View%20Listings%20Clicked)
   *
   * **Did a buyer client click "View home listings" from the buyer report to nav into Home Search?**
   *
   * Owner: leia@homebot.ai
   *
   * @param properties The event's properties (e.g. event_source)
   * @param options Amplitude event options.
   */
  viewListingsClicked(
    properties?: ViewListingsClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewListingsClicked(properties), options);
  }

  /**
   * Web Panel Unsubscribed
   *
   * [View in Tracking Plan](https://data.amplitude.com/homebot/Homebot%20Tracking%20Plan/events/main/latest/Web%20Panel%20Unsubscribed)
   *
   * **Client clicked on the "Unsubscribe" button from the web manage preference panel - this a global unsubscribe event**
   *
   * Owner: leia@homebot.ai
   *
   * @param properties The event's properties (e.g. event_source)
   * @param options Amplitude event options.
   */
  webPanelUnsubscribed(
    properties?: WebPanelUnsubscribedProperties,
    options?: EventOptions,
  ) {
    return this.track(new WebPanelUnsubscribed(properties), options);
  }

  /**
   * Web Preferences Managed
   *
   * [View in Tracking Plan](https://data.amplitude.com/homebot/Homebot%20Tracking%20Plan/events/main/latest/Web%20Preferences%20Managed)
   *
   * **Client clicked on the "Manage preferences" button from their web report mainpage (HO or Buyers)**
   *
   * Owner: leia@homebot.ai
   *
   * @param properties The event's properties (e.g. event_source)
   * @param options Amplitude event options.
   */
  webPreferencesManaged(
    properties?: WebPreferencesManagedProperties,
    options?: EventOptions,
  ) {
    return this.track(new WebPreferencesManaged(properties), options);
  }

  /**
   * Web Unsubscribed
   *
   * [View in Tracking Plan](https://data.amplitude.com/homebot/Homebot%20Tracking%20Plan/events/main/latest/Web%20Unsubscribed)
   *
   * Client clicked on the "Unsubscribe" button from the web
   *
   * Owner: leia@homebot.ai
   *
   * @param properties The event's properties (e.g. event_source)
   * @param options Amplitude event options.
   */
  webUnsubscribed(
    properties?: WebUnsubscribedProperties,
    options?: EventOptions,
  ) {
    return this.track(new WebUnsubscribed(properties), options);
  }

  /**
   * WIN Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/homebot/Homebot%20Tracking%20Plan/events/main/latest/WIN%20Clicked)
   *
   * Client clicked on one of the WINs
   *
   * Owner: leia@homebot.ai
   *
   * @param properties The event's properties (e.g. event_source)
   * @param options Amplitude event options.
   */
  winClicked(
    properties: WinClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new WinClicked(properties), options);
  }

  /**
   * Years In Home Adjusted
   *
   * [View in Tracking Plan](https://data.amplitude.com/homebot/Homebot%20Tracking%20Plan/events/main/latest/Years%20In%20Home%20Adjusted)
   *
   * Client adjusted the years in home slider above refi options
   *
   * Owner: leia@homebot.ai
   *
   * @param properties The event's properties (e.g. event_source)
   * @param options Amplitude event options.
   */
  yearsInHomeAdjusted(
    properties?: YearsInHomeAdjustedProperties,
    options?: EventOptions,
  ) {
    return this.track(new YearsInHomeAdjusted(properties), options);
  }
}

export const ampli = new Ampli();

// BASE TYPES
type BrowserOptions = amplitude.Types.BrowserOptions;

export type BrowserClient = amplitude.Types.BrowserClient;
export type BaseEvent = amplitude.Types.BaseEvent;
export type IdentifyEvent = amplitude.Types.IdentifyEvent;
export type GroupEvent = amplitude.Types.GroupIdentifyEvent;
export type Event = amplitude.Types.Event;
export type EventOptions = amplitude.Types.EventOptions;
export type Result = amplitude.Types.Result;
