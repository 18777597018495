import React, { useState } from 'react'
import { Avatar } from '../..'
import classnames from 'classnames'
import TextArea from 'react-textarea-autosize'
import { DirectMessageButton } from './DirectMessageButton'
import { PrequalSwitch } from '../PrequalSwitch/PrequalSwitch'
import { TopicKey } from '../../../constants/customerMessages'
import { QuestionPrequalConfirmationModal } from './QuestionPrequalConfirmationModal'

import styles from './QuestionSingle.module.scss'
import { useSelector } from 'react-redux'
import { selectCustomerIsInsuranceAgent, selectIsSoloRea } from '../../../store/selectors/customerProfile'

interface QuestionSingleProps {
  avatarUri?: string
  className?: string
  inputValue: string
  isFocused: boolean
  isLoading: boolean
  name?: string
  onBlur: (e) => void
  onChange: (e) => void
  onFocus: (e) => void
  onSubmit: (e) => void
  onTextAreaClick: (e) => void
  placeholder?: string
  questionTopicKey: TopicKey
  showPrequalSwitchUnderSingleQuestion: boolean
  topLevelQuestionsPrequalSwitchChecked: boolean
}

const QuestionSingle = ({
  className,
  onChange,
  onSubmit,
  inputValue,
  name,
  placeholder,
  avatarUri,
  isLoading,
  isFocused,
  questionTopicKey,
  showPrequalSwitchUnderSingleQuestion,
  topLevelQuestionsPrequalSwitchChecked,
  onBlur,
  onFocus,
  onTextAreaClick
}: QuestionSingleProps) => {
  const isSoloRea = useSelector(selectIsSoloRea)
  const isInsuranceAgent = useSelector(selectCustomerIsInsuranceAgent)
  const [prequalSwitchChecked, setPrequalSwitchChecked] = useState(showPrequalSwitchUnderSingleQuestion ? true : false)
  const [showPrequalConfirmationModal, setShowPrequalConfirmationModal] = useState(false)

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const hasAlreadySubmittedPrequalRequest = sessionStorage.getItem('prequalRequestSent')

    if (
      ((prequalSwitchChecked && showPrequalSwitchUnderSingleQuestion) ||
        (topLevelQuestionsPrequalSwitchChecked && !showPrequalSwitchUnderSingleQuestion)) &&
      !isSoloRea &&
      !isInsuranceAgent &&
      !hasAlreadySubmittedPrequalRequest
    ) {
      setShowPrequalConfirmationModal(true)
    } else {
      onSubmit(e)
    }
  }

  return (
    <div>
      <div
        data-qa='questionSingle'
        className={classnames(className, styles.question, avatarUri && styles.withAvatar, isFocused && styles.focused)}
      >
        <TextArea
          className={styles.textarea}
          value={inputValue}
          name={name}
          placeholder={placeholder}
          aria-label={`dm ${inputValue}`}
          onChange={onChange}
          onClick={onTextAreaClick}
          onFocus={onFocus}
          onBlur={onBlur}
        />
        {avatarUri && <Avatar className={styles.avatar} src={avatarUri} />}
        <DirectMessageButton onClick={handleSubmit} loading={isLoading} disabled={!inputValue && !placeholder} />
      </div>
      {showPrequalSwitchUnderSingleQuestion && (
        <PrequalSwitch
          checked={prequalSwitchChecked}
          prequalTopicKey={questionTopicKey}
          setChecked={setPrequalSwitchChecked}
        />
      )}
      {showPrequalConfirmationModal && (
        <QuestionPrequalConfirmationModal
          onSubmit={onSubmit}
          inputValue={inputValue}
          prequalTopicKey={questionTopicKey}
          onClose={() => setShowPrequalConfirmationModal(false)}
        />
      )}
    </div>
  )
}

export default QuestionSingle
