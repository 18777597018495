import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import * as Sentry from '@sentry/browser'
import {
  createClientTeamMembershipRequest,
  createClientTeamMembershipWithCustomerRequest,
  deleteClientTeamMembershipRequest,
  getHbnCustomerProfileByEmailRequest,
  replaceClientTeamMembershipRequest,
  replaceClientTeamMembershipWithCustomerRequest,
  verifyClientTeamMembershipRequest
} from '../../../api/mikasa/homebotNetwork/requests'
import { ClientTeamMembership } from '../../../types/clientTeamMembership'

interface CreateClientTeamMembershipsParams {
  clientId: string
  isVerified?: boolean
  preferredAgentFirstName: string
  preferredAgentLastName: string
  preferredAgentEmail: string
}
interface ReplaceClientTeamMembershipsParams {
  clientId: string
  clientTeamMembershipId: string
  isVerified?: boolean
  preferredAgentFirstName: string
  preferredAgentLastName: string
  preferredAgentEmail: string
}

interface ClientTeamMembershipsState {
  data: ClientTeamMembership[]
  loading: boolean
  error: string | null
}

interface VerifyClientTeamMembershipsParams {
  clientId: string
  clientTeamMembershipId: string
}
interface CreateTeamMembershipWithCustomerParams {
  clientId: string
  customerProfileId: string
  isVerified?: boolean
}
interface ReplaceTeamMembershipWithCustomerParams {
  clientId: string
  customerProfileId: string
  clientTeamMembershipId: string
  isVerified?: boolean
}

interface DeleteClientTeamMembershipParams {
  clientId: string
  membershipId: string
}

interface CustomerProfile {
  id: string
  firstName: string
  lastName: string
  email: string
}

const initialState: ClientTeamMembershipsState = {
  data: [],
  loading: false,
  error: null
}

export const createTeamMembership = createAsyncThunk(
  'clientTeamMemberships/create',
  async (params: CreateClientTeamMembershipsParams, { rejectWithValue }) => {
    try {
      const response = await createClientTeamMembershipRequest(params.clientId, {
        firstName: params.preferredAgentFirstName,
        lastName: params.preferredAgentLastName,
        email: params.preferredAgentEmail,
        isVerified: params.isVerified || false
      })
      return response.data
    } catch (error) {
      Sentry.captureException(error)
      return rejectWithValue(error.response?.data || 'Failed to create team membership')
    }
  }
)

export const createTeamMembershipWithCustomer = createAsyncThunk(
  'clientTeamMemberships/createWithCustomer',
  async (params: CreateTeamMembershipWithCustomerParams, { rejectWithValue }) => {
    try {
      const response = await createClientTeamMembershipWithCustomerRequest(params.clientId, {
        customerProfileId: params.customerProfileId,
        isVerified: params.isVerified || false
      })
      return response.data
    } catch (error) {
      Sentry.captureException(error)
      return rejectWithValue(error.response?.data || 'Failed to create team membership with customer')
    }
  }
)

export const replaceTeamMembership = createAsyncThunk(
  'clientTeamMemberships/replace',
  async (params: ReplaceClientTeamMembershipsParams, { rejectWithValue }) => {
    try {
      const response = await replaceClientTeamMembershipRequest(params.clientId, params.clientTeamMembershipId, {
        firstName: params.preferredAgentFirstName,
        lastName: params.preferredAgentLastName,
        email: params.preferredAgentEmail,
        isVerified: params.isVerified || false
      })
      return response.data
    } catch (error) {
      Sentry.captureException(error)
      return rejectWithValue(error.response?.data || 'Failed to create team membership')
    }
  }
)

export const replaceTeamMembershipWithCustomer = createAsyncThunk(
  'clientTeamMemberships/replaceWithCustomer',
  async (params: ReplaceTeamMembershipWithCustomerParams, { rejectWithValue }) => {
    try {
      const response = await replaceClientTeamMembershipWithCustomerRequest(
        params.clientId,
        params.clientTeamMembershipId,
        {
          customerProfileId: params.customerProfileId,
          isVerified: params.isVerified || false
        }
      )
      return response.data
    } catch (error) {
      Sentry.captureException(error)
      return rejectWithValue(error.response?.data || 'Failed to replace team membership with customer')
    }
  }
)

export const verifyTeamMembership = createAsyncThunk(
  'clientTeamMemberships/verify',
  async (params: VerifyClientTeamMembershipsParams, { rejectWithValue }) => {
    try {
      const response = await verifyClientTeamMembershipRequest(params.clientId, params.clientTeamMembershipId)
      return response.data
    } catch (error) {
      Sentry.captureException(error)
      return rejectWithValue(error.response?.data || 'Failed to verify team membership')
    }
  }
)

export const deleteTeamMembership = createAsyncThunk<
  string, // Change return type to string (membershipId)
  DeleteClientTeamMembershipParams,
  {
    rejectValue: string
  }
>('clientTeamMemberships/delete', async (params, { rejectWithValue }) => {
  try {
    await deleteClientTeamMembershipRequest(params.clientId, params.membershipId)
    return params.membershipId // Return the ID for filtering
  } catch (error: any) {
    Sentry.captureException(error)
    return rejectWithValue(error.response?.data || 'Failed to delete team membership')
  }
})

const clientTeamMembershipsSlice = createSlice({
  name: 'clientTeamMemberships',
  initialState,
  reducers: {
    setClientTeamMemberships: (state, action) => {
      state.data = action.payload
      state.loading = false
      state.error = null
    }
  },
  extraReducers: builder => {
    builder
      .addCase(createTeamMembership.pending, state => {
        state.loading = true
        state.error = null
      })
      .addCase(createTeamMembership.fulfilled, (state, action) => {
        state.loading = false
        state.data.push(action.payload)
      })
      .addCase(createTeamMembership.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload as string
      })
      .addCase(verifyTeamMembership.pending, state => {
        state.loading = true
        state.error = null
      })
      .addCase(verifyTeamMembership.fulfilled, (state, action) => {
        state.loading = false
        const index = state.data.findIndex(membership => membership.id === action.payload.id)
        if (index !== -1) {
          state.data[index] = action.payload
        } else {
          state.data = [action.payload]
        }
      })
      .addCase(verifyTeamMembership.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload as string
      })
      .addCase(createTeamMembershipWithCustomer.pending, state => {
        state.loading = true
        state.error = null
      })
      .addCase(createTeamMembershipWithCustomer.fulfilled, (state, action) => {
        state.loading = false
        state.data.push(action.payload)
      })
      .addCase(createTeamMembershipWithCustomer.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload as string
      })
      .addCase(deleteTeamMembership.pending, state => {
        state.loading = true
        state.error = null
      })
      .addCase(deleteTeamMembership.fulfilled, (state, action) => {
        state.loading = false
        state.data = state.data.filter(membership => membership.id !== action.payload)
      })
      .addCase(deleteTeamMembership.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload as string
      })
      .addCase(replaceTeamMembership.pending, state => {
        state.loading = true
        state.error = null
      })
      .addCase(replaceTeamMembership.fulfilled, (state, action) => {
        state.loading = false
        const index = state.data.findIndex(membership => membership.id === action.payload.id)
        if (index !== -1) {
          state.data[index] = action.payload
        } else {
          state.data = [action.payload]
        }
      })
      .addCase(replaceTeamMembership.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload as string
      })
      .addCase(replaceTeamMembershipWithCustomer.pending, state => {
        state.loading = true
        state.error = null
      })
      .addCase(replaceTeamMembershipWithCustomer.fulfilled, (state, action) => {
        state.loading = false
        const index = state.data.findIndex(membership => membership.id === action.payload.id)
        if (index !== -1) {
          state.data[index] = action.payload
        } else {
          state.data = [action.payload]
        }
      })
      .addCase(replaceTeamMembershipWithCustomer.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload as string
      })
  }
})

export const { setClientTeamMemberships } = clientTeamMembershipsSlice.actions

export default clientTeamMembershipsSlice.reducer
