import { UseMutationOptions } from 'react-query'
import { UpdateClientMutation, UpdateClientMutationVariables } from '../../api/gqlaxy/gql/generated/graphql'
import { useToast } from '@homebotapp/hb-react-component-catalog'
import { graphqlGLClient } from '../../api/gqlaxy/gql/queries/graphQLClient'
import { useCallback } from 'react'
import { useUpdateClientMutation } from '../../api/gqlaxy/gql/generated/graphql-hooks'

interface ErrorPrompt {
  error: string
  message: string
}

interface Option<D, E, V> extends UseMutationOptions<D, E, V> {
  disableErrorPrompts?: boolean
  errorPrompts?: ErrorPrompt[]
}

export const useUpdateClient = (option: Option<UpdateClientMutation, Error, UpdateClientMutationVariables> = {}) => {
  const toast = useToast()

  const client = graphqlGLClient({
    // endpoint: 'http://127.0.0.1:5001/mobile-staging-376418/us-central1/gqlactus' // Local endpoint
  })

  const { disableErrorPrompts, errorPrompts, ...options } = option

  const handleError = useCallback(
    (...args) => {
      const [e] = args

      if (disableErrorPrompts) return

      const prompt = errorPrompts?.find(({ error }) => error === e.message)
      const message = prompt?.message ?? e.message

      toast({
        description: message,
        status: 'error',
        position: 'top-right'
      })
    },
    [disableErrorPrompts, errorPrompts, toast]
  )

  return useUpdateClientMutation(client, {
    onError: handleError,
    ...options
  })
}
