import { useEffect, useRef, useState } from 'react'
import {
  Box,
  Drawer,
  Flex,
  Grid,
  GridItem,
  DrawerBody,
  DrawerContent,
  DrawerOverlay
} from '@homebotapp/hb-react-component-catalog'
import { useRecoilState } from 'recoil'
import { ListingCardNew } from '../../../ListingCardNew/ListingCardNew'
import { defineMessages, useIntl } from 'react-intl'
import Text from '../../../../shared/Text'
import { SearchLeadCard } from '../SearchLeadCard/SearchLeadCard'
import { HOME_SEARCH_LANDING_PAGE } from '../../../../../constants/tracking/trackingEventLocations/homeSearchTrackingEventLocation'
import { SearchResultsEmptyIcon } from '../SearchModalResults/SearchResultsEmpty/SearchResultsEmptyIcon'
import { currentSelectedLocation } from '../../../GoogleAutoComplete/state/currentSelectedLocation'
import { DelayedBotHeadSpinWithMessage } from '../../../../shared/Loading/Delay/DelayedBotHeadSpinWithMessage'

import styles from './LeadListingsSearchResults.module.scss'

export const MSG = defineMessages({
  searchCount: {
    id: 'HomeSearch.landing.page.home.count',
    defaultMessage: '{count}+ homes'
  },
  unlock: {
    id: 'HomeSearch.landing.page.home.unlock',
    defaultMessage: 'Unlock all {count}+ results'
  },
  customerIntro: {
    id: 'HomeSearch.landing.page.home.customer.intro',
    defaultMessage: 'Brought to you by {customerFullName}'
  },
  noListingsFound: {
    id: 'HomeSearch.landing.page.home.noListingsFound',
    defaultMessage: 'No listings found. Try another area.'
  },
  loading: {
    id: 'HomeSearch.landing.page.home.loading',
    defaultMessage: 'Searching...'
  }
})

export const LeadListingsSearchResults = ({ leadListings, locationSearchInputRef, leadListingsIsFetching }) => {
  const intl = useIntl()
  const [showLeadsModal, setShowLeadsModal] = useState(false)
  const [selectedLocation] = useRecoilState(currentSelectedLocation)
  const displayedResults = leadListings?.slice(0, 4) || []
  const emailInputRef = useRef<HTMLInputElement>()

  useEffect(() => {
    if (showLeadsModal) {
      emailInputRef?.current?.focus()
    } else {
      locationSearchInputRef?.current?.focus()
    }
  }, [showLeadsModal])

  return (
    <Box mt={2}>
      {!leadListingsIsFetching && leadListings.length > 0 && (
        <>
          <Text
            size='xxs'
            weight='semibold'
            variantCount='500'
            variant='neutralWarm'
            transform='uppercase'
            className={styles.subHeader}
          >
            {intl.formatMessage(MSG.searchCount, { count: leadListings.length })}
          </Text>
          <Grid templateColumns={{ base: '1fr', md: '1fr 1fr' }} gap={4} mb={4}>
            {displayedResults.map(listing => (
              <GridItem key={listing.id} height='100%' display='flex' flexDirection='column'>
                <ListingCardNew
                  listing={listing}
                  setShowLeadsModal={setShowLeadsModal}
                  tracking={{
                    guid: 'drFX3BVHn3rjnJLr1Cw24m',
                    ui_context: HOME_SEARCH_LANDING_PAGE,
                    descriptor: 'PURL Home Search Landing Page Lead Listing Clicked'
                  }}
                />
              </GridItem>
            ))}
          </Grid>
        </>
      )}
      {leadListingsIsFetching && (
        <DelayedBotHeadSpinWithMessage loading={true}>
          <Box display='flex' flex={1} alignItems='center' justifyContent='center' marginY={4}>
            <Text size='xs' weight='semibold'>
              {intl.formatMessage(MSG.loading)}
            </Text>
          </Box>
        </DelayedBotHeadSpinWithMessage>
      )}
      {leadListings.length > 0 && <SearchLeadCard listings={leadListings} emailInputRef={emailInputRef} />}
      <Flex
        gap={4}
        align='center'
        justify='center'
        direction='column'
        transition='all .5s ease-in-out'
        p={!leadListings?.length && selectedLocation && !leadListingsIsFetching ? 12 : 0}
        h={!leadListings?.length && selectedLocation && !leadListingsIsFetching ? '100%' : '0'}
        opacity={!leadListings?.length && selectedLocation && !leadListingsIsFetching ? '1' : '0'}
      >
        <SearchResultsEmptyIcon />
        <Text size='xs' weight='semibold'>
          {intl.formatMessage(MSG.noListingsFound)}
        </Text>
      </Flex>
      <Drawer
        trapFocus={false}
        isOpen={showLeadsModal}
        placement='bottom'
        tracking={{
          guid: 'bnDEASfaamdHBZE89d2nqc',
          ui_context: HOME_SEARCH_LANDING_PAGE,
          descriptor: 'Lead form drawer'
        }}
        onClose={() => setShowLeadsModal(false)}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerBody p={0}>
            <SearchLeadCard listings={leadListings} emailInputRef={emailInputRef} isDrawer />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  )
}
