import {
  Box,
  Text,
  Modal,
  Button,
  Heading,
  useToast,
  ModalBody,
  ButtonGroup,
  ModalFooter,
  ModalHeader,
  ModalContent,
  ModalOverlay,
  ModalCloseButton
} from '@homebotapp/hb-react-component-catalog'
import { REA } from '../../../constants/agent_types'
import { RootState } from '../../../types/rootState'
import { useIntl, defineMessages } from 'react-intl'
import { useAppSelector } from '../../../store/hooks'
import { useDispatch, useSelector } from 'react-redux'
import { useTrackingContext } from '../../../providers/tracking'
import { sendPrequalRequestForClient } from '../../../actions/affordability'
import { selectSponsorshipFirstName } from '../../../store/selectors/sponsorship'
import { PREQUAL_TOPIC_KEYS_UI_CONTEXT, TopicKey } from '../../../constants/customerMessages'

export const MSG = defineMessages({
  title: {
    id: 'QuestionPrequalConfirmationModal.title',
    defaultMessage: 'Include a pre-qual request?'
  },
  body1: {
    id: 'QuestionPrequalConfirmationModal.body1',
    defaultMessage: 'We will send {lo} this message along with a request for a pre-qualification.'
  },
  body2: {
    id: 'QuestionPrequalConfirmationModal.body2',
    defaultMessage:
      'Pre-qualifying gives you a clear budget with no obligation to proceed, so you can have a fully informed conversation.'
  },
  cancel: {
    id: 'QuestionPrequalConfirmationModal.cancel',
    defaultMessage: 'Cancel'
  },
  send: {
    id: 'QuestionPrequalConfirmationModal.send',
    defaultMessage: 'Send'
  },
  sendWithoutPrequal: {
    id: 'QuestionPrequalConfirmationModal.sendWithoutPrequal',
    defaultMessage: 'Send without pre-qual'
  },
  prequalRequestSuccess: {
    id: 'QuestionPrequalConfirmationModal.prequalRequestSuccess',
    defaultMessage: 'Pre-qualification request sent!'
  }
})

export interface QuestionPrequalConfirmationModalProps {
  inputValue: string
  onClose: () => void
  onSubmit: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  prequalTopicKey: TopicKey
}

export const QuestionPrequalConfirmationModal = ({
  onClose,
  onSubmit,
  inputValue,
  prequalTopicKey
}: QuestionPrequalConfirmationModalProps) => {
  const intl = useIntl()
  const toast = useToast()
  const dispatch = useDispatch()
  const { trackingClient } = useTrackingContext()
  const sponsorName = useSelector(selectSponsorshipFirstName)
  const isRea = useAppSelector((state: RootState) => state.customerProfile.data.type === REA)
  const customerName = useAppSelector((state: RootState) => state.customerProfile.data.firstName)

  const handleSend = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    onSubmit(e)
    dispatch(sendPrequalRequestForClient(trackingClient, PREQUAL_TOPIC_KEYS_UI_CONTEXT[prequalTopicKey]))

    toast({
      status: 'success',
      title: intl.formatMessage(MSG.prequalRequestSuccess)
    })

    sessionStorage.setItem('prequalRequestSent', 'true')

    onClose()
  }

  const handleSendWithoutPrequal = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    onSubmit(e)
    onClose()
  }

  return (
    <Modal isOpen={true} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Heading mb={0} textAlign='left'>
            {intl.formatMessage(MSG.title)}
          </Heading>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text>
            {intl.formatMessage(MSG.body1, {
              lo: isRea ? sponsorName : customerName
            })}
          </Text>
          <Box bg='neutral.100' mb={3} p={2} fontStyle='italic'>
            {inputValue}
          </Box>
          <Text>{intl.formatMessage(MSG.body2)}</Text>
        </ModalBody>
        <ModalFooter>
          <ButtonGroup spacing={3}>
            <Button
              size='sm'
              tracking={{
                descriptor: 'Cancel',
                guid: '7VBc3gQbEHJQA6g2aYRM6q',
                ui_context: 'QuestionPrequalConfirmationModal'
              }}
              onClick={onClose}
            >
              {intl.formatMessage(MSG.cancel)}
            </Button>
            <Button
              size='sm'
              variant='solid'
              onClick={handleSendWithoutPrequal}
              tracking={{
                guid: '6Q5oLeaxcBabDe5rSUgxWn',
                descriptor: 'Send Without Prequal',
                ui_context: 'QuestionPrequalConfirmationModal'
              }}
            >
              {intl.formatMessage(MSG.sendWithoutPrequal)}
            </Button>
            <Button
              size='sm'
              variant='solid'
              colorScheme='primary'
              onClick={handleSend}
              tracking={{
                descriptor: 'Send',
                guid: 'ptRwMrVkhwWqc6gZXeCLFn',
                ui_context: 'QuestionPrequalConfirmationModal'
              }}
            >
              {intl.formatMessage(MSG.send)}
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
