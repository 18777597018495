import { format } from 'date-fns'
import { useIntl, defineMessages } from 'react-intl'
import { Box, Text } from '@homebotapp/hb-react-component-catalog'

export const MSG = defineMessages({
  marketDate: {
    id: 'Listing.ListingNewlyListedTag.sold',
    defaultMessage: 'New: {onMarketDate}'
  }
})

export interface ListingNewlyListedTagProps {
  onMarketDate?: string | null
}

//function to check if the onMarketDate is within the last 30 days
export const calculateIfWithinLast30Days = (onMarketDate: string) => {
  const marketDateObject = new Date(onMarketDate)
  const thirtyDaysAgo = new Date()
  thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30)
  return marketDateObject >= thirtyDaysAgo
}

export const ListingNewlyListedTag = ({ onMarketDate }: ListingNewlyListedTagProps) => {
  const intl = useIntl()

  if (!onMarketDate) return null

  // Check if onMarketDate is within the last 30 days
  const isWithinLast30Days = calculateIfWithinLast30Days(onMarketDate)

  if (!isWithinLast30Days) return null

  return (
    <Box
      px={2}
      py={1}
      gap={2}
      borderWidth={1}
      borderRadius={9}
      alignItems='left'
      justifyItems='left'
      borderColor='neutral.400'
      backgroundColor='neutral.900'
    >
      <Text m={0} fontWeight={600} size='2xs' color='neutral.200'>
        {intl.formatMessage(MSG.marketDate, { onMarketDate: format(new Date(onMarketDate), 'MM/dd/yyyy') })}
      </Text>
    </Box>
  )
}
