export const FETCH_CURRENT_USER_REQUEST = 'FETCH_CURRENT_USER_REQUEST'
export const FETCH_CURRENT_USER_SUCCESS = 'FETCH_CURRENT_USER_SUCCESS'
export const FETCH_CURRENT_USER_FAILURE = 'FETCH_CURRENT_USER_FAILURE'

export const SET_LOCALE_DATA = 'SET_LOCALE_DATA'

export const FETCH_CLIENT_REQUEST = 'FETCH_CLIENT_REQUEST'
export const FETCH_CLIENT_SUCCESS = 'FETCH_CLIENT_SUCCESS'
export const FETCH_CLIENT_FAILURE = 'FETCH_CLIENT_FAILURE'

export const FETCH_CLIENT_HOMES_REQUEST = 'FETCH_CLIENT_HOMES_REQUEST'
export const FETCH_CLIENT_HOMES_SUCCESS = 'FETCH_CLIENT_HOMES_SUCCESS'
export const FETCH_CLIENT_HOMES_FAILURE = 'FETCH_CLIENT_HOMES_FAILURE'

export const TOGGLE_AFFORDABILITY_MODAL = 'TOGGLE_AFFORDABILITY_MODAL'
export const SET_AFFORDABILITY_VALUES = 'SET_AFFORDABILITY_VALUES'
export const SET_AFFORDABILITY_METHOD = 'SET_AFFORDABILITY_METHOD'
export const SET_PRICE_POINT = 'SET_PRICE_POINT'
export const DISPLAY_PREQUAL_PROMPT = 'DISPLAY_PREQUAL_PROMPT'

export const SEND_PREQUAL_REQUEST = 'SEND_PREQUAL_REQUEST'
export const SEND_PREQUAL_SUCCESS = 'SEND_PREQUAL_SUCCESS'
export const SEND_PREQUAL_FAILURE = 'SEND_PREQUAL_FAILURE'

export const PREPARE_AIRDNA_REQUEST = 'PREPARE_AIRDNA_REQUEST'
export const FETCH_AIRDNA_REQUEST = 'FETCH_AIRDNA_REQUEST'
export const FETCH_AIRDNA_SUCCESS = 'FETCH_AIRDNA_SUCCESS'
export const FETCH_AIRDNA_FAILURE = 'FETCH_AIRDNA_FAILURE'
export const SET_AIRDNA_SETTINGS = 'SET_AIRDNA_SETTINGS'

export const FETCH_BUYER_INFO_REQUEST = 'FETCH_BUYER_INFO_REQUEST'
export const FETCH_BUYER_INFO_SUCCESS = 'FETCH_BUYER_INFO_SUCCESS'
export const FETCH_BUYER_INFO_FAILURE = 'FETCH_BUYER_INFO_FAILURE'

export const FETCH_HOME_CACHE = 'FETCH_HOME_CACHE'
export const FETCH_HOME_REQUEST = 'FETCH_HOME_REQUEST'
export const FETCH_HOME_SUCCESS = 'FETCH_HOME_SUCCESS'
export const FETCH_HOME_FAILURE = 'FETCH_HOME_FAILURE'

export const FETCH_HOME_SUCCESS_FOR_BUYER = 'FETCH_HOME_SUCCESS_FOR_BUYER'

export const DIRECT_MESSAGE_REQUEST = 'DIRECT_MESSAGE_REQUEST'
export const DIRECT_MESSAGE_SUCCESS = 'DIRECT_MESSAGE_SUCCESS'
export const DIRECT_MESSAGE_FAILURE = 'DIRECT_MESSAGE_FAILURE'

export const UPDATE_HOME_REQUEST = 'UPDATE_HOME_REQUEST'
export const UPDATE_HOME_SUCCESS = 'UPDATE_HOME_SUCCESS'
export const UPDATE_HOME_ERROR = 'UPDATE_HOME_ERROR'

export const CREATE_HOME_REQUEST = 'CREATE_HOME_REQUEST'
export const CREATE_HOME_SUCCESS = 'CREATE_HOME_SUCCESS'
export const CREATE_HOME_FAILURE = 'CREATE_HOME_FAILURE'

export const CREATE_HOME_FETCH_COMPILED_FAILURE = 'CREATE_HOME_FETCH_COMIPLED_FAILURE'

export const ADD_HOME_ANSWER_REQUEST = 'ADD_HOME_ANSWER_REQUEST'
export const ADD_HOME_ANSWER_SUCCESS = 'ADD_HOME_ANSWER_SUCCESS'
export const ADD_HOME_ANSWER_ERROR = 'ADD_HOME_ANSWER_ERROR'

export const FETCH_VALUATION_HISTORY_REQUEST = 'FETCH_VALUATION_HISTORY_REQUEST'
export const FETCH_VALUATION_HISTORY_SUCCESS = 'FETCH_VALUATION_HISTORY_SUCCESS'
export const FETCH_VALUATION_HISTORY_FAILURE = 'FETCH_VALUATION_HISTORY_FAILURE'

export const UPDATE_BUYER_INFO_REQUEST = 'UPDATE_BUYER_INFO_REQUEST'
export const UPDATE_BUYER_INFO_SUCCESS = 'UPDATE_BUYER_INFO_SUCCESS'
export const UPDATE_BUYER_INFO_FAILURE = 'UPDATE_BUYER_INFO_FAILURE'

export const UPDATE_BUYERS_MARKETS = 'UPDATE_BUYERS_MARKETS'
export const UPDATE_MARKET_INTEREST_SUCCESS = 'UPDATE_MARKET_INTEREST_SUCCESS'
export const UPDATE_MARKET_INTEREST_FAILURE = 'UPDATE_MARKET_INTEREST_FAILURE'

export const FETCH_ALL_MARKETS_REQUEST = 'FETCH_ALL_MARKETS_REQUEST'
export const FETCH_ALL_MARKETS_SUCCESS = 'FETCH_ALL_MARKETS_SUCCESS'
export const FETCH_ALL_MARKETS_FAILURE = 'FETCH_ALL_MARKETS_FAILURE'

export const FETCH_MARKET_RECORDS = 'FETCH_MARKET_RECORDS'
export const FETCH_MARKET_RECORDS_SUCCESS = 'FETCH_MARKET_RECORDS_SUCCESS'
export const FETCH_MARKET_RECORDS_FAILURE = 'FETCH_MARKET_RECORDS_FAILURE'
export const FETCH_MARKET_RECORDS_DETAIL_SUCCESS = 'FETCH_MARKET_RECORDS_DETAIL_SUCCESS'
export const SET_MARKET_RECORDS_METADATA_SCOPE = 'SET_MARKET_RECORDS_METADATA_SCOPE'

export const FETCH_MORTGAGE_RATES_REQUEST = 'FETCH_MORTGAGE_RATES_REQUEST'
export const FETCH_MORTGAGE_RATES_SUCCESS = 'FETCH_MORTGAGE_RATES_SUCCESS'
export const FETCH_MORTGAGE_RATES_FAILURE = 'FETCH_MORTGAGE_RATES_FAILURE'

export const FETCH_SPONSOR_REQUEST = 'FETCH_SPONSOR_REQUEST'
export const FETCH_SPONSOR_SUCCESS = 'FETCH_SPONSOR_SUCCESS'
export const FETCH_SPONSOR_FAILURE = 'FETCH_SPONSOR_FAILURE'

export const HIDE_NOTIFICATION = 'HIDE_NOTIFICATION'
export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION'

export const SORT_ZIP_EXPLORE = 'SORT_ZIP_EXPLORE'
export const SET_ZIPCODE = 'SET_ZIPCODE'
export const SET_ZIP_VISIBLE = 'SET_ZIP_VISIBLE'
export const SET_MAP_CENTER = 'SET_MAP_CENTER'
export const SET_FILTER_QUERY = 'SET_FILTER_QUERY'

export const FETCH_MAPBOX_ZIP_DATA = 'FETCH_MAPBOX_ZIP_DATA'
export const FETCH_MAPBOX_ZIP_DATA_SUCCESS = 'FETCH_MAPBOX_ZIP_DATA_SUCCESS'
export const FETCH_MAPBOX_ZIP_DATA_FAILURE = 'FETCH_MAPBOX_ZIP_DATA_FAILURE'

export const BOMBBOMB_INITIALIZE = 'BOMBBOMB_INITIALIZE'
export const BOMBBOMB_TOGGLE_OPEN = 'BOMBBOMB_TOGGLE_OPEN'
export const BOMBBOMB_MOUNT = 'BOMBBOMB_MOUNT'
export const BOMBBOMB_ERROR = 'BOMBBOMB_ERROR'

export const SET_LOAN_EDITING = 'SET_LOAN_EDITING'

export const CREATE_HOME_LOAN_REQUEST = 'CREATE_HOME_LOAN_REQUEST'
export const CREATE_HOME_LOAN_SUCCESS = 'CREATE_HOME_LOAN_SUCCESS'
export const CREATE_HOME_LOAN_FAILURE = 'CREATE_HOME_LOAN_FAILURE'

export const SET_LOAN_BALANCE_EDITING = 'SET_LOAN_BALANCE_EDITING'
export const CREATE_LOAN_BALANCE_REQUEST = 'CREATE_LOAN_BALANCE_REQUEST'
export const CREATE_LOAN_BALANCE_SUCCESS = 'CREATE_LOAN_BALANCE_SUCCESS'
export const CREATE_LOAN_BALANCE_FAILURE = 'CREATE_LOAN_BALANCE_FAILURE'

export const DELETE_LOAN_REQUEST = 'DELETE_LOAN_REQUEST'
export const DELETE_LOAN_SUCCESS = 'DELETE_LOAN_SUCCESS'
export const DELETE_LOAN_FAILURE = 'DELETE_LOAN_FAILURE'

export const UPDATE_LOAN_REQUEST = 'UPDATE_LOAN_REQUEST'
export const UPDATE_LOAN_SUCCESS = 'UPDATE_LOAN_SUCCESS'
export const UPDATE_LOAN_FAILURE = 'UPDATE_LOAN_FAILURE'

export const SET_HO_LOAN_FOCUS = 'SET_HO_LOAN_FOCUS'
export const SET_HO_CHECKED_NUMBERS = 'SET_HO_CHECKED_NUMBERS'
export const SET_WARNING_MODAL_VISIBLE = 'SET_WARNING_MODAL_VISIBLE'

export const SEND_INVITE_REQUEST = 'SEND_INVITE_REQUEST'
export const SEND_INVITE_SUCCESS = 'SEND_INVITE_SUCCESS'
export const SEND_INVITE_FAILURE = 'SEND_INVITE_FAILURE'

export const TOGGLE_DISCLAIMER = 'TOGGLE_DISCLAIMER'
export const SHOW_DISCLAIMER = 'SHOW_DISCLAIMER'

export const REQUEST_DIGEST_REQUEST = 'REQUEST_DIGEST_REQUEST'
export const REQUEST_DIGEST_SUCCESS = 'REQUEST_DIGEST_SUCCESS'
export const REQUEST_DIGEST_FAILURE = 'REQUEST_DIGEST_FAILURE'

export const HOME_EMAIL_REQUEST = 'HOME_EMAIL_REQUEST'
export const HOME_EMAIL_SUCCESS = 'HOME_EMAIL_SUCCESS'
export const HOME_EMAIL_FAILURE = 'HOME_EMAIL_FAILURE'

export const FETCH_PREVIEW_REQUEST = 'FETCH_PREVIEW_REQUEST'
export const FETCH_PREVIEW_SUCCESS = 'FETCH_PREVIEW_SUCCESS'
export const FETCH_PREVIEW_FAILURE = 'FETCH_PREVIEW_FAILURE'

export const ADD_HOMEOWNER_LEAD_REQUEST = 'ADD_HOMEOWNER_LEAD_REQUEST'
export const ADD_HOMEOWNER_LEAD_SUCCESS = 'ADD_HOMEOWNER_LEAD_SUCCESS'
export const ADD_HOMEOWNER_LEAD_FAILURE = 'ADD_HOMEOWNER_LEAD_FAILURE'

export const ADD_BUYER_LEAD_REQUEST = 'ADD_BUYER_LEAD_REQUEST'
export const ADD_BUYER_LEAD_SUCCESS = 'ADD_BUYER_LEAD_SUCCESS'
export const ADD_BUYER_LEAD_FAILURE = 'ADD_BUYER_LEAD_FAILURE'

export const SET_CUSTOMER_PROFILE = 'SET_CUSTOMER_PROFILE'

export const FETCH_CUSTOMER_PROFILE_SUCCESS = 'FETCH_CUSTOMER_PROFILE_SUCCESS'
export const FETCH_CUSTOMER_PROFILE_REQUEST = 'FETCH_CUSTOMER_PROFILE_REQUEST'
export const FETCH_CUSTOMER_PROFILE_FAILURE = 'FETCH_CUSTOMER_PROFILE_FAILURE'

export const FETCH_CUSTOMER_PROFILE_SETTINGS_REQUEST = 'FETCH_CUSTOMER_PROFILE_SETTINGS_REQUEST'
export const FETCH_CUSTOMER_PROFILE_SETTINGS_SUCCESS = 'FETCH_CUSTOMER_PROFILE_SETTINGS_SUCCESS'
export const FETCH_CUSTOMER_PROFILE_SETTINGS_FAILURE = 'FETCH_CUSTOMER_PROFILE_SETTINGS_FAILURE'

export const TOGGLE_AUTH_MODAL = 'TOGGLE_AUTH_MODAL'

export const SAVE_CLIENT_ANSWER_REQUEST = 'SAVE_CLIENT_ANSWER_REQUEST'
export const SAVE_CLIENT_ANSWER_SUCCESS = 'SAVE_CLIENT_ANSWER_SUCCESS'
export const SAVE_CLIENT_ANSWER_FAILURE = 'SAVE_CLIENT_ANSWER_FAILURE'

export const SEND_REFI_REQUEST = 'SEND_REFI_REQUEST'
export const SEND_REFI_SUCCESS = 'SEND_REFI_SUCCESS'
export const SEND_REFI_FAILURE = 'SEND_REFI_FAILURE'
export const RESET_REFI_REQUEST = 'RESET_REFI_REQUEST'

export const SHOW_HOME_INSURANCE_DETAIL = 'SHOW_HOME_INSURANCE_DETAIL'
export const HIDE_HOME_INSURANCE_DETAIL = 'HIDE_HOME_INSURANCE_DETAIL'
export const FETCH_HOME_INSURANCE_SUCCESS = 'FETCH_HOME_INSURANCE_SUCCESS'
export const FETCH_HOME_INSURANCE_REQUEST = 'FETCH_HOME_INSURANCE_REQUEST'
export const FETCH_HOME_INSURANCE_FAILURE = 'FETCH_HOME_INSURANCE_FAILURE'
export const SET_HOME_INSURANCE_OVERRIDE = 'SET_HOME_INSURANCE_OVERRIDE'

export const SET_REFI_MONTHS_IN_HOME = 'SET_REFI_MONTHS_IN_HOME'
export const SET_REFI_DETAILS_INDEX = 'SET_REFI_DETAILS_INDEX'

export const FETCH_LATEST_MORTGAGE_RATES_SUCCESS = 'FETCH_LATEST_MORTGAGE_RATES_SUCCESS'
export const FETCH_LATEST_MORTGAGE_RATES_REQUEST = 'FETCH_LATEST_MORTGAGE_RATES_REQUEST'
export const FETCH_LATEST_MORTGAGE_RATES_FAILURE = 'FETCH_LATEST_MORTGAGE_RATES_FAILURE'

export const SEND_INSTANT_OFFER_REQUEST = 'SEND_INSTANT_OFFER_REQUEST'
export const SEND_INSTANT_OFFER_SUCCESS = 'SEND_INSTANT_OFFER_SUCCESS'
export const SEND_INSTANT_OFFER_FAILURE = 'SEND_INSTANT_OFFER_FAILURE'

export const SET_CLIENT_FETCH_ID = 'SET_CLIENT_FETCH_ID'
export const SET_HOME_FETCH_ID = 'SET_HOME_FETCH_ID'
export const SET_HOME_FROM_CACHE = 'SET_HOME_FROM_CACHE'
export const SET_CLIENT_IS_GUEST = 'SET_CLIENT_IS_GUEST'
export const SET_CLIENT_IS_LOADING = 'SET_CLIENT_IS_LOADING'
export const SET_CLIENT_FAVORITE_LISTINGS_ALERT_CADENCE_DAYS = 'SET_CLIENT_FAVORITE_LISTINGS_ALERT_CADENCE_DAYS'

export const ADD_REDRAW_HEIGHT_FADE_KEY = 'ADD_REDRAW_HEIGHT_FADE_KEY'
export const REMOVE_REDRAW_HEIGHT_FADE_KEY = 'REMOVE_REDRAW_HEIGHT_FADE_KEY'

export const UPDATE_LOAN_BALANCES_REQUEST = 'UPDATE_LOAN_BALANCES_REQUEST'
export const UPDATE_LOAN_BALANCES_SUCCESS = 'UPDATE_LOAN_BALANCES_SUCCESS'
export const UPDATE_LOAN_BALANCES_FAILURE = 'UPDATE_LOAN_BALANCES_FAILURE'
export const UPDATE_LOAN_BALANCES_SAVED_RESET = 'UPDATE_LOAN_BALANCES_SAVED_RESET'

export const VALUATION_INITIALIZE_STATE = 'VALUATION_INITIALIZE_STATE'
export const ADD_TYV_VALUATION_REQUEST = 'ADD_TYV_VALUATION_REQUEST'
export const ADD_TYV_VALUATION_SUCCESS = 'ADD_TYV_VALUATION_SUCCESS'
export const ADD_TYV_VALUATION_FAILURE = 'ADD_TYV_VALUATION_FAILURE'
export const VALUATION_REFRESH_HOME_DATA_SUCCESS = 'VALUATION_REFRESH_HOME_DATA_SUCCESS'
export const VALUATION_REFRESH_HOME_DATA_FAILURE = 'VALUATION_REFRESH_HOME_DATA_FAILURE'
export const SET_VALUATION_HOME_FACTS_PROGRESS = 'SET_VALUATION_HOME_FACTS_PROGRESS'
export const SET_VALUATION_NEEDS_REFRESH = 'SET_VALUATION_NEEDS_REFRESH'

export const CREATE_TCA_REQUEST = 'CREATE_TCA_REQUEST'
export const CREATE_TCA_SUCCESS = 'CREATE_TCA_SUCCESS'
export const CREATE_TCA_FAILURE = 'CREATE_TCA_FAILURE'
export const CREATE_TCA_RESET = 'CREATE_TCA_RESET'

export const TOGGLE_FAVORITE_LISTING = 'TOGGLE_FAVORITE_LISTING'
export const APPEND_LISTINGS = 'APPEND_LISTINGS'
export const SET_SIMILAR_LISTINGS = 'SET_SIMILAR_LISTINGS'
export const SET_SEARCH_LISTINGS_TOTAL = 'SET_SEARCH_LISTINGS_TOTAL'
export const SET_SELECTED_LISTING_ID = 'SET_SELECTED_LISTING_ID'
export const SET_PREVIOUSLY_VIEWED_LISTING_ID = 'SET_PREVIOUSLY_VIEWED_LISTING_ID'
export const TOGGLE_LISTING_SEARCH_MODAL = 'TOGGLE_LISTING_SEARCH_MODAL'

export const SET_FILTER = 'SET_FILTER'

export const SET_REFERRER_SOURCE = 'SET_REFERRER_SOURCE'

export const SET_BUYER_ONBOARDING_STATE = 'SET_BUYER_ONBOARDING_STATE'
export const FETCH_BUYER_ONBOARDING_AVERAGE_MEDIAN_PRICE_ERROR = 'FETCH_BUYER_ONBOARDING_AVERAGE_MEDIAN_PRICE_ERROR'
export const SET_BUYER_ONBOARDING_ADDRESS = 'SET_BUYER_ONBOARDING_ADDRESS'
export const SET_BUYER_ONBOARDING_GOAL = 'SET_BUYER_ONBOARDING_GOAL'
export const SET_BUYER_ONBOARDING_AVERAGE_MEDIAN_PRICE = 'SET_BUYER_ONBOARDING_AVERAGE_MEDIAN_PRICE'

export const FETCH_CUSTOMER_POWERUPS_REQUEST = 'FETCH_CUSTOMER_POWERUPS_REQUEST'
export const FETCH_CUSTOMER_POWERUPS_SUCCESS = 'FETCH_CUSTOMER_POWERUPS_SUCCESS'
export const FETCH_CUSTOMER_POWERUPS_FAILURE = 'FETCH_CUSTOMER_POWERUPS_FAILURE'

export const SAVE_CLIENT_DATA_REQUEST = 'SAVE_CLIENT_DATA_REQUEST'
export const SAVE_CLIENT_DATA_SUCCESS = 'SAVE_CLIENT_DATA_SUCCESS'
export const SAVE_CLIENT_DATA_FAILURE = 'SAVE_CLIENT_DATA_FAILURE'
export const RESET_CLIENT_STATUS = 'RESET_CLIENT_STATUS'

export const FETCH_SMS_LONG_CODE_REQUEST = 'FETCH_SMS_LONG_CODE_REQUEST'
export const FETCH_SMS_LONG_CODE_SUCCESS = 'FETCH_SMS_LONG_CODE_SUCCESS'
export const FETCH_SMS_LONG_CODE_FAILURE = 'FETCH_SMS_LONG_CODE_FAILURE'

export const FLAG_FOR_UNSUBSCRIBE = 'FLAG_FOR_UNSUBSCRIBE'

export const FETCH_CUSTOM_MAILER_REQUEST = 'FETCH_CUSTOM_MAILER_REQUEST'
export const FETCH_CUSTOM_MAILER_SUCCESS = 'FETCH_CUSTOM_MAILER_SUCCESS'
export const FETCH_CUSTOM_MAILER_FAILURE = 'FETCH_CUSTOM_MAILER_FAILURE'

export const UNSET_HOME_INSURANCE = 'UNSET_HOME_INSURANCE'

export const FETCH_RECOMMENDATIONS = 'FETCH_RECOMMENDATIONS'
export const FETCH_RECOMMENDATIONS_SUCCESS = 'FETCH_RECOMMENDATIONS_SUCCESS'
export const FETCH_RECOMMENDATIONS_FAILURE = 'FETCH_RECOMMENDATIONS_FAILURE'

export const FETCH_CLIENT_VIEWED_DIGEST = 'FETCH_CLIENT_VIEWED_DIGEST'
export const FETCH_CLIENT_VIEWED_DIGEST_SUCCESS = 'FETCH_CLIENT_VIEWED_DIGEST_SUCCESS'
export const FETCH_CLIENT_VIEWED_DIGEST_FAILURE = 'FETCH_CLIENT_VIEWED_DIGEST_FAILURE'

export const CLIENT_TUNED_MORTGAGE_COACH_VALUE = 'CLIENT_TUNED_MORTGAGE_COACH_VALUE'

export const FETCH_VIDEOS_REQUEST = 'FETCH_VIDEOS_REQUEST'
export const FETCH_VIDEOS_SUCCESS = 'FETCH_VIDEOS_SUCCESS'
export const FETCH_VIDEOS_FAILURE = 'FETCH_VIDEOS_FAILURE'

export const FETCH_BRANDING_STATS_REQUEST = 'FETCH_BRANDING_STATS_REQUEST'
export const FETCH_BRANDING_STATS_SUCCESS = 'FETCH_BRANDING_STATS_SUCCESS'
export const FETCH_BRANDING_STATS_FAILURE = 'FETCH_BRANDING_STATS_FAILURE'

export const FETCH_SHOULD_YOU_SELL_REQUEST = 'FETCH_SHOULD_YOU_SELL_REQUEST'
export const FETCH_SHOULD_YOU_SELL_SUCCESS = 'FETCH_SHOULD_YOU_SELL_SUCCESS'
export const FETCH_SHOULD_YOU_SELL_FAILURE = 'FETCH_SHOULD_YOU_SELL_FAILURE'

export const CREATE_DIGEST_REQUEST = 'CREATE_DIGEST_REQUEST'
export const CREATE_DIGEST_SUCCESS = 'CREATE_DIGEST_SUCCESS'
export const CREATE_DIGEST_FAILURE = 'CREATE_DIGEST_FAILURE'

export const FETCH_HOMEBOT_LISTINGS_REQUEST = 'FETCH_HOMEBOT_LISTINGS_REQUEST'
export const FETCH_HOMEBOT_LISTINGS_SUCCESS = 'FETCH_HOMEBOT_LISTINGS_SUCCESS'
export const FETCH_HOMEBOT_LISTINGS_FAILURE = 'FETCH_HOMEBOT_LISTINGS_FAILURE'

export const FETCH_LISTING_REQUEST = 'FETCH_LISTING_REQUEST'
export const FETCH_LISTING_SUCCESS = 'FETCH_LISTING_SUCCESS'
export const FETCH_LISTING_FAILURE = 'FETCH_LISTING_FAILURE'

export const SET_SCHEDULE_TOUR_STEP = 'SET_SCHEDULE_TOUR_STEP'
export const SET_SCHEDULE_TOUR_SHOW_MODAL = 'SET_SCHEDULE_TOUR_SHOW_MODAL'
export const SET_SCHEDULE_TOUR_IN_PERSON = 'SET_SCHEDULE_TOUR_IN_PERSON'
export const SET_SCHEDULE_TOUR_DATE = 'SET_SCHEDULE_TOUR_DATE'
export const SET_SCHEDULE_TOUR_PHONE = 'SET_SCHEDULE_TOUR_PHONE'
export const SET_SCHEDULE_TOUR_QUESTIONS_TEXT = 'SET_SCHEDULE_TOUR_QUESTIONS_TEXT'
export const CREATE_SCHEDULE_TOUR_REQUEST = 'CREATE_SCHEDULE_TOUR_REQUEST'

export const ADD_TOAST = 'ADD_TOAST'
export const REMOVE_TOAST = 'REMOVE_TOAST'
