import axios from 'axios'
import jsonApiParser from 'jsonapi-parse'
import changeCaseObject from 'change-case-object'
import { getAuthHeaders } from '../../auth'
import { mockedClient } from './mockedApiClient'

export const DEFAULT_OPTIONS = {
  contentType: 'application/vnd.api+json',
  transformRequest: [
    function (data) {
      return JSON.stringify(data)
    }
  ],
  transformResponse: [
    function (data) {
      // Convert JsonAPI's default kebab-case to camelCase for easier destructuring in JavaScript
      let parsedData = null

      try {
        parsedData = changeCaseObject.camelCase(jsonApiParser.parse(data))
      } catch (e) {
        console.error(e)
      }
      return parsedData || data
    }
  ]
}

function apiClient(baseURL, options) {
  const inMockedDevMode = Boolean(process.env.REACT_APP_MOCKED_DEV_MODE)
  const settings = Object.assign({}, DEFAULT_OPTIONS, options)

  const client = axios.create({
    baseURL,
    timeout: 30000,
    headers: {
      Accept: settings.contentType,
      'Content-Type': settings.contentType
    },
    transformRequest: settings.transformRequest,
    transformResponse: settings.transformResponse
  })

  // Add request interceptor to set auth headers
  client.interceptors.request.use(config => {
    const authHeaders = getAuthHeaders()

    if (!authHeaders.Authorization) {
      console.warn('No authorization header present for request to:', config.url)
    }

    config.headers = {
      ...config.headers,
      ...authHeaders
    }
    return config
  })

  return inMockedDevMode ? mockedClient() : client
}

export default apiClient
