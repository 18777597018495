import { createSelector } from 'reselect'
import get from 'lodash/get'
import partialRight from 'lodash/partialRight'
import { IA, LO, REA } from '../../constants/agent_types'

// Helpers
import GlobalHelpers from '../../charts/lib/Helpers'

import { MAX_FIRST_NAME_LENGTH, MAX_FULL_NAME_LENGTH } from '../../constants/listings'
import { fullName } from './utils'

export const selectCustomerProfileLoading = state => state.customerProfile.loading

export const selectCustomerProfile = state => get(state, 'customerProfile.data')

const select = getter => createSelector(selectCustomerProfile, getter)

const getFromCustomerProfile = key => partialRight(get, key)

export const getCustomerId = getFromCustomerProfile('id')
export const getCustomerHasSponsor = getFromCustomerProfile('hasSponsor')
export const getCustomerEmail = getFromCustomerProfile('email')
export const getCustomerFirstName = getFromCustomerProfile('firstName')
export const getCustomerLastName = getFromCustomerProfile('lastName')
export const getCustomerPhotoUri = getFromCustomerProfile('photoUri')
export const getCustomerNmls = getFromCustomerProfile('nmls')
export const getCustomerLinkedinUrl = getFromCustomerProfile('linkedinUri')
export const getCustomerStateLicense = getFromCustomerProfile('stateLicense')
export const getCustomerType = getFromCustomerProfile('type')
export const getCustomerDisclaimer = getFromCustomerProfile('disclaimer')
export const getCustomerFeatures = getFromCustomerProfile('features')
export const getCustomerListingsUri = getFromCustomerProfile('listingsUri')
export const getCustomerCorporationName = getFromCustomerProfile('corporationName')
export const getCustomerBuyersEnabled = getFromCustomerProfile('buyersEnabled')
export const getCustomerBuyerShareUri = getFromCustomerProfile('buyerShareUri')
export const getCustomerLegacyRef = getFromCustomerProfile('legacyRef')
export const getCustomerTitle = getFromCustomerProfile('title')
export const getCustomerWebsite = getFromCustomerProfile('websiteUri')
export const getDisableGetLeadsFeature = getFromCustomerProfile('disableGetLeadsFeature')
export const getReverseMortgageVideoExternalId = getFromCustomerProfile('reverseMortgageVideoExternalId')
export const getReverseMortgageVideoType = getFromCustomerProfile('reverseMortgageVideoType')
export const getHomebotListingsFeature = getFromCustomerProfile('homebotListingsFeature')
export const getHBForChromeFeature = getFromCustomerProfile('hbForChromeFeature')
export const getNativeAccessFeature = getFromCustomerProfile('nativeAccess')
export const getHomebotNetworkFeature = getFromCustomerProfile('homebotNetworkEnabled')
export const getNativeShareUrl = getFromCustomerProfile('shareNativeUri')
export const getCustomerPublickKey = getFromCustomerProfile('publicKey')
export const getBrandingStatement = getFromCustomerProfile('brandingStatement')
export const getCustomerProfileState = getFromCustomerProfile('state')
export const getLoanApplicationUri = getFromCustomerProfile('loanApplicationUri')
export const getScheduleACallUri = getFromCustomerProfile('appointmentLinkUri')
export const getLoanApplicationCtaEnabled = getFromCustomerProfile('loanApplicationCtaEnabled')

export const getShareCode = getFromCustomerProfile('shareCode')
export const selectShareCode = select(getShareCode)
export const selectCustomerId = select(getCustomerId)
export const selectCustomerHasSponsor = select(getCustomerHasSponsor)
export const selectCustomerEmail = select(getCustomerEmail)
export const selectCustomerFirstName = select(getCustomerFirstName)
export const selectCustomerLastName = select(getCustomerLastName)
export const selectCustomerPhotoUri = select(getCustomerPhotoUri)
export const selectCustomerNMLS = select(getCustomerNmls)
export const selectLinkedinUrl = select(getCustomerLinkedinUrl)
export const selectStateLicense = select(getCustomerStateLicense)
export const selectCustomerType = select(getCustomerType)
export const selectCustomerDisclaimer = select(getCustomerDisclaimer)
export const selectCustomerCorporationName = select(getCustomerCorporationName)
export const selectListingsUri = select(getCustomerListingsUri)
export const selectFeatures = select(getCustomerFeatures)
export const selectCustomerBuyersEnabled = select(getCustomerBuyersEnabled)
export const selectCustomerBuyerShareUri = select(getCustomerBuyerShareUri)
export const selectCustomerLegacyRef = select(getCustomerLegacyRef)
export const selectCustomerPublicKey = select(getCustomerPublickKey)
export const selectCustomerTitle = select(getCustomerTitle)
export const selectCustomerBrandingStatement = select(getBrandingStatement)
export const selectCustomerProfileState = select(getCustomerProfileState)
export const selectReverseMortgageVideoExternalId = select(getReverseMortgageVideoExternalId)
export const selectReverseMortgageVideoType = select(getReverseMortgageVideoType)
export const selectHomebotListingsFeatureEnabled = select(getHomebotListingsFeature)
export const selectNativeAccessFeature = select(getNativeAccessFeature)
export const selectHomebotNetworkFeature = select(getHomebotNetworkFeature)
export const selectNativeShareUrl = select(getNativeShareUrl)
export const selectLoanApplicationUri = select(getLoanApplicationUri)
export const selectScheduleACallUri = select(getScheduleACallUri)
export const selectLoanApplicationCtaEnabled = select(getLoanApplicationCtaEnabled)

export const selectHBForChromeFeature = select(getHBForChromeFeature)

export const selectCustomerWebsite = select(getCustomerWebsite)
export const selectDisableGetLeadsFeature = select(getDisableGetLeadsFeature)

export const getCustomerPhone = profile => get(profile, 'mobilePhone') || get(profile, 'workPhone')

export const selectFullName = createSelector(selectCustomerFirstName, selectCustomerLastName, fullName)

export const selectStickyBrandingFirstName = createSelector(selectCustomerFirstName, firstName =>
  firstName?.length <= MAX_FIRST_NAME_LENGTH ? firstName : undefined
)

export const selectStickyBrandingFullName = createSelector(
  selectFullName,
  selectStickyBrandingFirstName,
  (rawFullName, firstName) => (rawFullName?.length > MAX_FULL_NAME_LENGTH ? firstName : rawFullName)
)

export const selectFeatureSet = createSelector(selectFeatures, features => {
  if (!features) {
    return undefined
  }

  const featureSet = new Set()
  for (let i = 0; i < features.length; i += 1) {
    featureSet.add(features[i])
  }
  return featureSet
})

export const selectCustomerPhone = state => {
  const cp = selectCustomerProfile(state) || {}

  return getCustomerPhone(cp)
}

export const selectCustomerFormattedPhone = createSelector(selectCustomerPhone, phone => {
  if (phone) {
    return GlobalHelpers.formatPhone(phone, true)
  }
})

const createFeatureFlagSelector = flag => createSelector(selectFeatureSet, features => features && features.has(flag))

export const selectHasMortgageCoach = createFeatureFlagSelector('mortgageCoachCustomer')

export const selectHasReverseMortgageFeatureFlag = createFeatureFlagSelector('reverseMortgage')

export const selectHasHomebotNetworkFeatureFlag = createFeatureFlagSelector('homebotNetwork')

export const selectName = createSelector(
  selectCustomerFirstName,
  selectCustomerLastName,
  (first, last) => first && last && `${first} ${last}`
)

export const selectListingsUriForZipcode = zipcode =>
  createSelector(selectListingsUri, uri => uri && uri.replace('{zipcode}', zipcode))

export const selectOfficeProfile = state => {
  const cp = selectCustomerProfile(state) || {}
  return cp.officeProfile
}

export const selectCustomModules = state => {
  const cp = selectCustomerProfile(state) || {}
  return cp.customModules
}

export const selectFromOfficeProfile = key => createSelector(selectOfficeProfile, op => get(op, key))

export const selectOfficeDisclaimer = selectFromOfficeProfile('disclaimer')
export const selectOfficeAddress = selectFromOfficeProfile('address')
export const selectOfficeName = selectFromOfficeProfile('name')
export const selectOfficeNmls = selectFromOfficeProfile('nmls')
export const selectOfficePhone = selectFromOfficeProfile('phone')

export const selectFromOfficeAddress = key => createSelector(selectOfficeAddress, address => get(address, key))

export const selectOfficeZip = selectFromOfficeAddress('zipCode')

export const selectOfficeFormattedPhone = createSelector(selectOfficePhone, phone => {
  if (phone) {
    return GlobalHelpers.formatPhone(phone, true)
  }
})

export const selectCorporateProfile = state => {
  const op = selectOfficeProfile(state) || {}
  return op.corporateProfile
}

export const selectCorporateProfileId = createSelector(selectCorporateProfile, profile => (profile ? profile.id : null))

const selectFromCorporateProfile = key => createSelector(selectCorporateProfile, partialRight(get, key))

export const selectCompanyFeatures = selectFromCorporateProfile('features')
export const selectCompanyName = selectFromCorporateProfile('name')
export const selectCompanyTheme = selectFromCorporateProfile('brandingColorTheme')
export const selectCompanyLogo = selectFromCorporateProfile('avatarUri')
export const selectCompanyBrandDarkLogoUri = selectFromCorporateProfile('brandDarkLogoUri')
export const selectCompanyBrandLightLogoUri = selectFromCorporateProfile('brandLightLogoUri')
export const selectHasCompanyBrandingLogoPresent = createSelector(
  selectCompanyBrandDarkLogoUri,
  selectCompanyBrandLightLogoUri,
  (darkLogo, lightLogo) => darkLogo || lightLogo
)
export const selectCompanyThemePrimaryColor = selectFromCorporateProfile('brandingPrimaryColor')
export const selectCompanyThemeSecondaryColor = selectFromCorporateProfile('brandingSecondaryColor')

export const selectCompanyFeatureSet = createSelector(selectCompanyFeatures, features => {
  if (!features) {
    return undefined
  }
  const featureSet = new Set()
  for (let i = 0; i < features.length; i += 1) {
    featureSet.add(features[i])
  }
  return featureSet
})

const createCompanyFeatureFlagSelector = flag =>
  createSelector(selectCompanyFeatureSet, features => features && features.has(flag))

export const selectCompanyHasDisabledWINMortgagePaymentOptions = createCompanyFeatureFlagSelector(
  'winMortgagePaymentOptionsDisabled'
)

export const selectCustomerIsAgent = createSelector(selectCustomerType, type => type === IA || type === REA)

export const selectCustomerIsInsuranceAgent = createSelector(selectCustomerType, type => type === IA)

export const selectCustomerIsLoanOfficer = createSelector(selectCustomerType, type => type === LO)

export const selectCustomerIsRealEstateAgent = createSelector(selectCustomerType, type => type === REA)

export const selectRealEstateAgent = createSelector(
  selectCustomerIsRealEstateAgent,
  selectCustomerProfile,
  (isREA, cp) => (isREA ? cp : null)
)

export const selectHasSmsOutreachFeature = createFeatureFlagSelector('smsOutreach')

export const selectReverseMortgageVideoEnabled = createSelector(
  selectReverseMortgageVideoType,
  selectReverseMortgageVideoExternalId,
  (videoType, videoId) => videoType && videoId
)

export const selectIsSoloRea = createSelector(
  selectCustomerIsRealEstateAgent,
  selectCustomerHasSponsor,
  (isREA, hasSponsor) => isREA && !hasSponsor
)

export const selectIsSoloInsuranceAgent = createSelector(
  selectCustomerIsInsuranceAgent,
  selectCustomerHasSponsor,
  (isInsuranceAgent, hasSponsor) => isInsuranceAgent && !hasSponsor
)

export const selectShowOfficeAddress = createSelector(
  selectCorporateProfile,
  corporateProfile => corporateProfile?.showAddress
)
